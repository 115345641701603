import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { CustomAutoComplete, FormBlock, Label, SelectDropDown, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { withRouter } from 'react-router-dom';
import { startPageTimer, trackPageDuration } from "../utils/function";

class DepthChartPage extends Component {
  constructor(props){
    super(props)
    this.state={
      depthChartData:[],
      startTime: null,
    }
  }

  componentDidMount(){
    const { dispatch } = this.props;
    startPageTimer('Depth Chart Page');
    this.setState({ startTime: Date.now() });
    this.props?.getDepthChartsUsers();
  }

  
  componentDidUpdate(previousProps){
    if(this.props?.DepthChartUsers !== this.state.depthChartData){
      this.setState({depthChartData:this.props?.DepthChartUsers})
    }
  }
      
  componentWillUnmount(){
    this.setState({depthChartData:[]});
    this.props?.clearDepthChartValues();
    trackPageDuration('Depth Chart Page',this.state.startTime)
  }

  swap = (drag, drop,rank,rowId,position_id) => {
    const cloneData = [...this.state.depthChartData]

    const updatedCategoryData = cloneData?.filter(item=>item?.category === this.props?.CurrentDepthChart?.["category"])

    const dragData = updatedCategoryData[drag?.index]?.positions
    const dropData = updatedCategoryData[drop?.index]?.positions

    const dragIndex = dragData?.findIndex(val => val?.rank === drag?.rank)
    const dropIndex = dropData?.findIndex(val => val?.rank === drop?.rank)

    let duplicateData = {
      alias:null,
      depth_chart_users_id:null,
      id:null,
      name:null,
      position_id:position_id,
      rank:rank,
      row_id:rowId,
      user_id:null,
    }

    if(dropIndex === -1){
      dropData.push(duplicateData)
    }

    if(dropIndex === -1){
      const dropIndex = dropData?.findIndex(val => val?.rank === drop?.rank)

      const dragValue = dragData[dragIndex]["name"] //1
      dragData[dragIndex]["name"] = dropData[dropIndex]["name"]
      dropData[dropIndex]["name"] = dragValue

      const dragUser = dragData[dragIndex]["user_id"] 
      dragData[dragIndex]["user_id"] = dropData[dropIndex]["user_id"]
      dropData[dropIndex]["user_id"] = dragUser

      if(dragData[dragIndex]["rank"] === dropData[dropIndex]["rank"]){
      dropData[dropIndex]["rank"] = rank
      const dragRank = dragData[dragIndex]["rank"] 
      dragData[dragIndex]["rank"] = dropData[dropIndex]["rank"]
      dropData[dropIndex]["rank"] = dragRank
      }

    }else{
      const dragValue = dragData[dragIndex]["name"] //1
      dragData[dragIndex]["name"] = dropData[dropIndex]["name"]
      dropData[dropIndex]["name"] = dragValue

      const dragUser = dragData[dragIndex]["user_id"] 
      dragData[dragIndex]["user_id"] = dropData[dropIndex]["user_id"]
      dropData[dropIndex]["user_id"] = dragUser

      if(dragData[dragIndex]["rank"] === dropData[dropIndex]["rank"]){
        dropData[dropIndex]["rank"] = rank
        const dragRank = dragData[dragIndex]["rank"] 
        dragData[dragIndex]["rank"] = dropData[dropIndex]["rank"]
        dropData[dropIndex]["rank"] = dragRank
      }
    }

    if(dropIndex === -1){
      const dropIndex = dropData?.findIndex(val => val?.rank === drop?.rank)

      this.props.updateDragUser(dropData[dropIndex])
      this.props.updateDropUser(dragData[dragIndex])
    }else{
      this.props.updateDragUser(dropData[dropIndex])
      this.props.updateDropUser(dragData[dragIndex])
    }
    
    this.setState({
      ...this.state, depthChartData: cloneData
    })
    
    
  }

  handleDragStart = (e, item,index) => {
   if(item?.id !== null){
    let fromBox =JSON.stringify({...item, index});
    e.dataTransfer.setData("dragContent", fromBox);
    e.currentTarget.classList.add("being-dragged");
   }
  };

  handleDragOver = (e, item, property) => {
    e.preventDefault(); // Necessary. Allows us to drop.
    return false;
  };

  handleDragEnd = (e) =>{
    e.currentTarget.classList.remove("being-dragged");
  }

  handleDrop = (e, item, rank , rowId,index) => {
    e.preventDefault();
    let fromBox = JSON.parse(e?.dataTransfer?.getData("dragContent"))
    let dropValue = item?.positions.filter((postionItem,idx) => postionItem.rank === rank)

    let duplicateData = {
      alias:null,
      depth_chart_users_id:null,
      id:null,
      name:null,
      position_id:null,
      rank:null,
      row_id:null,
      user_id:null,
    }
    if(dropValue?.length === 0 ){
      dropValue?.push(duplicateData)
    }
    let toBox = {...dropValue?.length>0?dropValue[0]:item.positions[0],index}
    if(toBox?.rank === null || toBox?.name=== null){
      toBox.rank=rank;
    }
    if(fromBox?.name){
      this.swap(fromBox, toBox,rank,rowId,item?.id);
    }  
    return false;
  };


   handleFieldDragStart = (e, value,itemIndex) => {
    var style = window.getComputedStyle(e.target, null);
    let id = itemIndex;
    let data =JSON.stringify(value);
 
    e.currentTarget.classList.add('field-hide');
    e.dataTransfer.setData(
      "text/plain",
      parseInt(style.getPropertyValue("left"), 10) -
        e.clientX +
        "," +
        (parseInt(style.getPropertyValue("top"), 10) - e.clientY) +
        "," +
        id
    );
    e.dataTransfer.setData("currentDragData",data)
   
  };

   handleFieldDrop = (event) => {
    var offset = event.dataTransfer.getData("text/plain").split(",");
    var currentDropData = JSON.parse(event.dataTransfer.getData('currentDragData'))
    let box = document.querySelector('.field-view-container');

      var dm = document.getElementById(`box-${offset[2]}`);
      let top = event.clientY + parseInt(offset[1], 10);
      let left =  event.clientX + parseInt(offset[0], 10);
      // let y = event.clientY + parseInt(offset[1], 10);
      // let x =  event.clientX + parseInt(offset[0], 10);
    
      // let check_array=[]
      // this.state?.fieldViewData?.map((item,index) => {
      //   let final_left=item.field_position?.left 
      //   let final_top=item.field_position?.top 
      //   if(((x<=final_left && (x+100)<=(final_left+100) && (x+100)>=final_left) ||
      //    (x>=final_left && x<=(final_left+100) && (x+100)>=(final_left+100))) &&
      //     ((y<=final_top && (y+100)<=(final_top+100) && (y+100)>=final_top) || 
      //     (y>=final_top && y<=(final_top+100) && (y+100)>=(final_top+100)) )){
      //     if(Number(index) !== Number(offset[2])){
      //       check_array.push(true)
      //     }
      //   }
      //   else{
      //     check_array.push(false)
      //     } 
      // })
      // if(!check_array.includes(true)){
        if(top>=0 && left >=0 && left <= box.clientWidth - 0 - 100 && top <= box.clientHeight - 0 - 100){
          dm.style.left = left + "px";
          dm.style.top = top + "px";
          let payload = {
            depth_chart_id:this.props?.CurrentDepthChart?.Id,
            row_id: currentDropData?.row_id,
            rank: currentDropData?.rank,
            user_id: currentDropData?.user_id,
            field_position : { top: top, left: left}
          }
          this.props.updateFieldView(payload)
        }
      // }      
      event.preventDefault();
      return false;
  };

   handleFieldDragOver = (event) => {
    event.dataTransfer.dropEffect = "move";
    event.preventDefault();
    return false;
  };

  handleFieldViewPlayer = (value) =>{
    this.props?.setFieldViewPlayer({data:value})
  }

  handleFieldViewAlias = (item) => {
    this.props?.setFieldViewAlias({"alias id input":{
      id: item?.row_id,
      row_id:item?.row_id,
      alias_id: item.alias_id,
    },"alias name":item?.alias})
  }

  render() {
    let {
      AliasName,
      CurrentAlias,
      CurrentDepthChart,
      currentSelectedPositionData,
      CurrentDepthChartPosition,
      DepthChartRowToDelete,
      DepthChartToDelete,
      DropdownManagePackage,
      EditDepthChart,
      FormEditDepthChartErrors,
      NavBarDropDownIndex,
      NewRow,
      Positions,
      Users,
      DepthChartToDuplicate,
      DepthChartSortingTable,
      depthChartDropDownData,
      selectedDepthChartDropDownData,
      tableView,
      DepthChartFieldView,
      ToggleDepthFieldView,
      SeasonYearData,
      isDefaultLoaded
    } = this.props;
    const { FilterList, Find, Weeks } = window;
    const params = new URLSearchParams(this.props?.location?.search);
    const myParam = Object.fromEntries(params.entries());

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Depth Chart",
              trigger: "onload",
              timeout: "250",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Depth Chart Field View",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            // {
            //   action: "Get Depth Chart Users",
            //   trigger: "onload",
            //   timeout: "250",
            //   pollingPeriod: "",
            //   inputVariables: {isDefault:true},
            // },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 0 : 0,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1",
                  marginLeft: "0px",
                  marginRight: "0px",
                  position:'relative',
                  height:'100%'
                }}
              >
                <DivBlock
                  style={{
                    backgroundColor: "#f8f8fb",
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    paddingTop: "0px",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock style={{ backgroundColor: "#f8f8fb" }}>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock>
                        <DivBlock className="game_title view-game">
                          <Heading className={"h2"} style={{fontSize:'30px',fontWeight:'700'}} type={"h2"}>
                            Depth Charts
                          </Heading>
                          <Span className={"h2 h2-span"} style={{fontWeight:600}}>
                          Depth Charts /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            {CurrentDepthChart
                            ? CurrentDepthChart["title"]
                            : ""}
                          </Span>
                        </DivBlock>
                        {isDefaultLoaded ? null :
                        <>
                        <Columns
                        style={{
                          backgroundColor: "#ffffff",
                        }}> 
                        <DivBlock className="view-game-container">
                          <DivBlock>
                          <DivBlock>
                            <Heading className={"h3"} style={{fontSize:'16px',fontWeight:'700',marginBottom:0}} type={"h3"}>
                              {CurrentDepthChart
                                ? CurrentDepthChart["title"]
                                : ""}
                            </Heading>
                          </DivBlock>
                          <Span style={{fontSize:'14px'}}>
                            {CurrentDepthChart
                            ? CurrentDepthChart["category"]
                            : ""}
                          </Span>
                          </DivBlock>
                          <DivBlock className={"hide-btn"}>
                            <DivBlock
                              className="manage-package-container"
                              actions={[
                                {
                                  action: "Toggle Dropdown Manage Package",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "manage package dropdown": true,
                                  },
                                },
                                {
                                  action: "Toggle Dropdown Manage Package",
                                  trigger: "onmouseleave",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "manage package dropdown": false,
                                  },
                                },
                              ]}
                            >
                              <Button className={"btn btn-primary"}
                               disabled={SeasonYearData?.[0]?.year === Number(myParam?.year) ? false : true}
                               style={  SeasonYearData?.[0]?.year === Number(myParam?.year)? 
                                 {backgroundColor: "#48c46e",
                                 borderColor: "#48c46e"}:
                                 {backgroundColor: "#a0d2a0",
                                 borderColor: "#a0d2a0",}}>
                                Manage Package
                              </Button>

                              <If style={{position:'relative'}}
                                condition={DropdownManagePackage}
                                show={false}
                              >
                                <Then>
                                  <DivBlock
                                    className={"dropdown-menu"}
                                    style={{}}
                                  >
                                    <DivBlock
                                      className={"dropdown-item"}
                                      actions={[
                                        {
                                          action: "Set Edit Depth Chart",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "input edit depth chart":
                                              CurrentDepthChart,
                                          },
                                        },
                                      ]}
                                    >
                                      <Span>Edit</Span>
                                    </DivBlock>

                                    <DivBlock
                                      className={"dropdown-item"}
                                      actions={[
                                        {
                                          action: "Set Export Depth Chart",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "input export depth chart":
                                              CurrentDepthChart,
                                          },
                                        },
                                      ]}
                                    >
                                      <Span>Export</Span>
                                    </DivBlock>

                                    <DivBlock
                                      className={"dropdown-item"}
                                      actions={[
                                        {
                                          action: "Set Duplicate Depth Chart",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "duplicate depth chart" : true,
                                            "duplicate depth chart id":
                                              CurrentDepthChart?.Id,
                                              "duplicate depth chart name": CurrentDepthChart?
                                              CurrentDepthChart?.title+'_Duplicate':null,
                                          },
                                        },
                                      ]}
                                    >
                                      <Span>Duplicate</Span>
                                    </DivBlock>

                                    <DivBlock
                                      className={"dropdown-item"}
                                      actions={[
                                        {
                                          action: "Share Depth Chart",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            share:
                                              CurrentDepthChart &&
                                                CurrentDepthChart["shared"]
                                                ? false
                                                : true,
                                            "Share Message":
                                              CurrentDepthChart &&
                                                CurrentDepthChart["shared"]
                                                ? "Are you sure you wish to unshare?"
                                                : "Are you sure you wish to share?",
                                          },
                                        },
                                      ]}
                                    >
                                      <Span>
                                        {CurrentDepthChart &&
                                          CurrentDepthChart["shared"]
                                          ? "Unshare"
                                          : "Share"}
                                      </Span>
                                    </DivBlock>

                                    <DivBlock
                                      className={"dropdown-item"}
                                      actions={[
                                        {
                                          action: "Set Delete Depth Chart",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "Depth Chart Id":
                                              CurrentDepthChart
                                                ? CurrentDepthChart["Id"]
                                                : "",
                                            // "depth chart id to delete":
                                            //   CurrentDepthChart
                                            //     ? CurrentDepthChart["Id"]
                                            //     : "",
                                          },
                                        },
                                      ]}
                                    >
                                      <Span>Delete Package</Span>
                                    </DivBlock>

                                    <LinkBlock
                                      className={"dropdown-item"}
                                      duration={1000}
                                      href={"/injured_players"}
                                      target={"_self"}
                                      type={"spa"}
                                    >
                                      <DivBlock>
                                        <Span
                                          style={{ textDecoration: "none" }}
                                        >
                                          Injured Players
                                        </Span>
                                      </DivBlock>
                                    </LinkBlock>
                                  </DivBlock>
                                </Then>
                              </If>
                            </DivBlock>
                            
                            {/* <button onClick={()=>window.print()}>Print</button> */}
                            <Button
                              className={"btn btn-primary btn-print"}
                              actions={[
                                {
                                  action: "Print Reports",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Print
                            </Button>
                            <DivBlock className="depth-chart-dropdown">
                            <SelectDropDown
                              style={{
                                alignItems:'center',
                                borderRadius:'5px',
                                fontSize: '14px',
                                width: '200px',
                              }}
                              className={"form-control single-depthchart"}
                              name={"selectedDepthChartDropDownData"}
                              dataSetLabel={"title"}
                              dataSetValue={"Id"}
                              dataSet={depthChartDropDownData?.length > 0 ? depthChartDropDownData : "No data available"}
                              enableReinitialize={true}
                              initialValue={ (selectedDepthChartDropDownData === 0 || selectedDepthChartDropDownData === undefined) ? CurrentDepthChart?.Id : selectedDepthChartDropDownData }
                              type={"number"}
                              concateLabel={{key:'week',name:'Week'}}
                              actions={[
                                {
                                  action: "Set Depth Chart Week Filter",
                                  trigger: "onchange",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { },
                                },
                              ]}
                            />
                            </DivBlock>
                            <DivBlock>
                            <LinkBlock
                              df-snippet-instance-id={301}
                              duration={1000}
                              href={"/depth_chart_lists"}
                              target={"_self"}
                              type={"spa"}
                            >
                              {'Back to Depth Charts >>'}
                            </LinkBlock>
                            </DivBlock>
                          </DivBlock>
                          </DivBlock>
                        </Columns>

                        <DivBlock>
                          <Columns
                            style={{ fontFamily:"'Poppins', sans-serif", }}
                          >
                            <Column lg={8} md={8} sm={8} xs={12}>
                              <DivBlock className="view-game-text">
                                {tableView === "table_view" ?
                              <Paragraph className={"para"}>
                                Click a cell to add a player to the depth chart. 
                                Click and hold a player to drag them to another position. 
                                Click any table header to sort by column.
                              </Paragraph> : <Paragraph className={"para"}>
                              Click and hold a player to drag them to any position on the field below. 
                              Double click any block position to edit the position. 
                              Double click any block player to edit the player.
                              </Paragraph>}
                              </DivBlock>
                              </Column>
                              <Column lg={4} md={4} sm={4} xs={12}>
                              <SelectDropDown
                              className={"form-control field-view-dropdown"}
                              initialValue={tableView}
                              name={"tableView"}
                              options={[
                                { label: "Table View", value: "table_view" },
                                { label: "Field View", value: "field_view" },
                              ]}
                              actions={[
                                {
                                  action: "Select Depth Chart View",
                                  trigger: "onchange",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { initialState: {} },
                                },
                              ]}
                            ></SelectDropDown>
                            </Column>
                          </Columns>
                        </DivBlock>
                        {tableView === "table_view" ?
                        <DivBlock className="table-responsive-sm">      
                        <table
                          style={{
                            margin: "auto",
                            width: "100%",
                            borderCollapse: "collapse",
                            textAlign: "center",
                            overflow: "visible",
                          }}
                          className={"tableBorder"}
                        >
                          <thead style={{ display: "table-header-group" }}>
                            <tr className={"thead-bg"} style={{}}>
                              <th
                                className={"RedTableCell th-border"}
                                style={{}}
                              >
                                <Span className={"Sorting-span th-span"}
                                  actions={[
                                  {
                                    action: "TOGGLE_SORTING_DEPTHCHART",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      inputTogglePlay: {
                                        ToggleSorting:
                                          DepthChartSortingTable !== true,
                                        sortingField: 'abbreviation',
                                      },
                                    },
                                  },
                                ]}>
                                  Group
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"Sorting-span th-span"}
                                 actions={[
                                  {
                                    action: "TOGGLE_SORTING_DEPTHCHART",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      inputTogglePlay: {
                                        ToggleSorting:
                                          DepthChartSortingTable !== true,
                                        sortingField: 'alias',
                                      },
                                    },
                                  },
                                ]}>
                                  Position
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}
                                // actions={[
                                //   {
                                //     action: "TOGGLE_SORTING_DEPTHCHART_PLAYER",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {
                                //       inputTogglePlay: {
                                //         ToggleSorting:
                                //           DepthChartSortingTable !== true,
                                //         sortingField: '1',
                                //       },
                                //     },
                                //   },
                                // ]}
                                >
                                  Starter
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}
                                // actions={[
                                //   {
                                //     action: "TOGGLE_SORTING_DEPTHCHART_PLAYER",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {
                                //       inputTogglePlay: {
                                //         ToggleSorting:
                                //           DepthChartSortingTable !== true,
                                //         sortingField: '2',
                                //       },
                                //     },
                                //   },
                                // ]}
                                >
                                  Second
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}
                                // actions={[
                                //   {
                                //     action: "TOGGLE_SORTING_DEPTHCHART_PLAYER",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {
                                //       inputTogglePlay: {
                                //         ToggleSorting:
                                //           DepthChartSortingTable !== true,
                                //         sortingField: '3',
                                //       },
                                //     },
                                //   },
                                // ]}
                                >
                                  Third
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}
                                // actions={[
                                //   {
                                //     action: "TOGGLE_SORTING_DEPTHCHART_PLAYER",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {
                                //       inputTogglePlay: {
                                //         ToggleSorting:
                                //           DepthChartSortingTable !== true,
                                //         sortingField: '4',
                                //       },
                                //     },
                                //   },
                                // ]}
                                >
                                  Fourth
                                </Span>
                              </th>

                              <th className={"RedTableCell th-border"}>
                                <Span className={"th-span"}
                                // actions={[
                                //   {
                                //     action: "TOGGLE_SORTING_DEPTHCHART_PLAYER",
                                //     trigger: "onclick",
                                //     timeout: "",
                                //     pollingPeriod: "",
                                //     inputVariables: {
                                //       inputTogglePlay: {
                                //         ToggleSorting:
                                //           DepthChartSortingTable !== true,
                                //         sortingField: '5',
                                //       },
                                //     },
                                //   },
                                // ]}
                                >
                                  Fifth
                                </Span>
                              </th>

                              <th
                                className={
                                  "RedTableCell th-border th-span th-hide"
                                }
                                style={{}}
                              >
                                <Span style={{ textAlign: "center" }}>
                                  Action
                                </Span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {FilterList({
                              searchData: this.state.depthChartData,
                              property: "category",
                              query: CurrentDepthChart
                                ? CurrentDepthChart["category"]
                                : "",
                            }) &&
                              isArray(
                                FilterList({
                                  searchData: this.state.depthChartData,
                                  property: "category",
                                  query: CurrentDepthChart
                                    ? CurrentDepthChart["category"]
                                    : "",
                                })
                              ) &&
                              FilterList({
                                searchData: this.state.depthChartData,
                                property: "category",
                                query: CurrentDepthChart
                                  ? CurrentDepthChart["category"]
                                  : "",
                              }).map((row, index) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder"
                                            : "redTableCell"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <LinkBlock
                                          className={"a a-link"}
                                          duration={1000}
                                          href={`/depth_chart_position_users?depthchartid=${CurrentDepthChart.Id}&positionid=${row.id}&rowid=${row.row_id}`}
                                          style={{}}
                                          target={"_self"}
                                          type={"spa"}
                                        >
                                          <DivBlock
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Span
                                              style={{
                                                alignItems: "center",
                                                display: "block",
                                                justifyContent: "center",
                                                paddingTop: "0px",
                                                fontWeight:"600",
                                              }}
                                            >
                                              {row["abbreviation"]}
                                            </Span>
                                          </DivBlock>
                                        </LinkBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder"
                                            : "redTableCell"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            minHeight: "20px",
                                            position: "static",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action: "Set Current Alias",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "alias id input": {
                                                  id: row.id,
                                                  row_id:
                                                    row.row_id > 0
                                                      ? row.row_id
                                                      : null,
                                                  alias_id: row.alias_id
                                                    ? row.alias_id
                                                    : null,
                                                },
                                                "alias name": row["alias"],
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 5,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 5,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {row["alias"]}
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder draggable block"
                                            : "redTableCell draggable block"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                          position:'relative',
                                        }}
                                        draggable="true"
                                        onDragStart={(e) => this.handleDragStart(
                                          e,Find({
                                            searchData: row["positions"],
                                            property: "rank",
                                            query: 1,
                                          }),index
                                        )}
                                        onDrop={(e) => this.handleDrop(
                                          e,row,1,row?.row_id,index
                                        )}
                                        onDragOver={(e) => this.handleDragOver(
                                          e,row,1,index
                                      )}
                                      onDragEnd={(e)=>this.handleDragEnd(e)}>
                                        <DivBlock
                                          className="joyride-depth-chart-click-cell"
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "absolute",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "row": row,
                                                "position_all_data": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 1,
                                                }),
                                                "current user id": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 1,
                                                }).name,
                                                "current user name":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].name
                                                    : 0,
                                                "user id inputt":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].Id
                                                    : 0,
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 1,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 1,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 1,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder draggable block"
                                            : "redTableCell draggable block"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                          position:'relative',
                                        }}
                                        draggable="true"
                                        onDragStart={(e) => this.handleDragStart(
                                          e,Find({
                                            searchData: row["positions"],
                                            property: "rank",
                                            query: 2,
                                          }),index
                                        )}
                                        onDrop={(e) => this.handleDrop(
                                          e,row,2,row?.row_id,index
                                        )}
                                        onDragOver={(e) => this.handleDragOver(
                                          e,row,2,index
                                      )}
                                      onDragEnd={(e)=>this.handleDragEnd(e)}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "absolute",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "row": row,
                                                "position_all_data": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 2,
                                                }),
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 2,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 2,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                                "current user name":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].name
                                                    : 0,
                                                "user id inputt":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].Id
                                                    : 0,
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 2,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder draggable block"
                                            : "redTableCell draggable block"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                          position:'relative',
                                        }}
                                        draggable="true"
                                        onDragStart={(e) => this.handleDragStart(
                                          e,Find({
                                            searchData: row["positions"],
                                            property: "rank",
                                            query: 3,
                                          }),index
                                        )}
                                        onDrop={(e) => this.handleDrop(
                                          e,row,3,row?.row_id,index
                                        )}
                                        onDragOver={(e) => this.handleDragOver(
                                          e,row,3,index
                                      )}
                                      onDragEnd={(e)=>this.handleDragEnd(e)}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "absolute",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "row": row,
                                                "position_all_data": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 3,
                                                }),
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 3,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 3,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                                "current user name":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].name
                                                    : 0,
                                                "user id inputt":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].Id
                                                    : 0,
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 3,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder draggable block"
                                            : "redTableCell draggable block"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                          position:'relative',
                                          // cursor:'grab',
                                        }}
                                        draggable="true"
                                        onDragStart={(e) => this.handleDragStart(
                                          e,Find({
                                            searchData: row["positions"],
                                            property: "rank",
                                            query: 4,
                                          }),index
                                        )}
                                        onDrop={(e) => this.handleDrop(
                                          e,row,4,row?.row_id,index
                                        )}
                                        onDragOver={(e) => this.handleDragOver(
                                          e,row,4,index
                                      )}
                                      onDragEnd={(e)=>this.handleDragEnd(e)}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "absolute",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "row": row,
                                                "position_all_data": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 4,
                                                }),
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 4,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 4,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                                "current user name":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].name
                                                    : 0,
                                                "user id inputt":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].Id
                                                    : 0,
                                              },
                                            },
                                          ]}
                                        >
                                          <Span className={"table-span"}>
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 4,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder draggable block"
                                            : "redTableCell draggable block"
                                        }
                                        style={{
                                          paddingBottom: "10px",
                                          paddingTop: "10px",
                                          position:'relative',
                                        }}
                                        draggable="true"
                                        onDragStart={(e) => this.handleDragStart(
                                          e,Find({
                                            searchData: row["positions"],
                                            property: "rank",
                                            query: 5,
                                          }),index
                                        )}
                                        onDrop={(e) => this.handleDrop(
                                          e,row,5,row?.row_id,index
                                        )}
                                        onDragOver={(e) => this.handleDragOver(
                                          e,row,5,index
                                      )}
                                      onDragEnd={(e)=>this.handleDragEnd(e)}>
                                        <DivBlock
                                          style={{
                                            alignItems: "center",
                                            bottom: "0px",
                                            display: "flex",
                                            height: "100%",
                                            justifyContent: "center",
                                            left: "0px",
                                            position: "absolute",
                                            right: "0px",
                                            top: "0px",
                                            width: "100%",
                                          }}
                                          actions={[
                                            {
                                              action:
                                                "Set Current Depth Chart Position",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                "row": row,
                                                "position_all_data": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 5,
                                                }),
                                                "current depth chart position input":
                                                {
                                                  user_id: Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 5,
                                                  }).depth_chart_users_id,
                                                  "position id": row["id"],
                                                  rank: 5,
                                                  "row id":
                                                    row["row_id"] > 0
                                                      ? row["row_id"]
                                                      : null,
                                                },
                                                "current user name":
                                                  FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  }).length > 0
                                                    ? FilterList({
                                                      searchData: Users,
                                                      property: "injured",
                                                      query: false,
                                                    })[0].name
                                                    : 0,
                                                "user id inputt": Find({
                                                  searchData: row["positions"],
                                                  property: "rank",
                                                  query: 5,
                                                }).depth_chart_users_id
                                                  ? Find({
                                                    searchData:
                                                      row["positions"],
                                                    property: "rank",
                                                    query: 5,
                                                  }).depth_chart_users_id
                                                  : FilterList({
                                                    searchData: Users,
                                                    property: "injured",
                                                    query: false,
                                                  })[0]?.Id,
                                              },
                                            },
                                          ]}
                                        >
                                          <Span
                                            className={"table-span"}
                                            style={{}}
                                          >
                                            {
                                              Find({
                                                searchData: row["positions"],
                                                property: "rank",
                                                query: 5,
                                              }).name
                                            }
                                          </Span>
                                        </DivBlock>
                                      </td>

                                      <td
                                        className={
                                          row.row_id > 0
                                            ? "redTableCell noborder"
                                            : "redTableCell"
                                        }
                                      >
                                        <If
                                          condition={
                                            row?.row_deletable === 0
                                              ? false
                                              : true
                                          }
                                          style={{ display: "block" }}
                                        >
                                          <Then>
                                            <DivBlock
                                              className={"th-span space"}
                                              style={{}}
                                            >
                                              <Span
                                                style={{ color: "#000000" }}
                                                actions={[
                                                  {
                                                    action:
                                                      "Set Delete Depth Chart Row",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      "Delete Depth Chart Row Id":
                                                        row?.row_id,
                                                    },
                                                  },
                                                ]}
                                              >
                                                Delete
                                              </Span>
                                            </DivBlock>
                                          </Then>
                                        </If>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                        </DivBlock>:null}
                        {tableView === "field_view" ?
                        <div className="field-view-wrap">
                        <div className="field-view-container">
                        <div onDrop={(e) => this.handleFieldDrop(e)}
                          onDragOver={(e) => this.handleFieldDragOver(e)}
                          onDragEnd={(e)=>{e.currentTarget.classList.remove('field-hide')}}
                           className="draggable-view" />
                        <div
                          style={{
                            position:'relative',
                            // backgroundColor:"red"
                          }}
                        >
                        {DepthChartFieldView && 
                        isArray(DepthChartFieldView) && 
                        DepthChartFieldView?.map((item,index) => {                          
                          return (
                            <div
                              id={`box-${index}`}
                              className="box-container"
                              style={{ position: "absolute", left: item?.field_position?.left, top:item?.field_position?.top}}
                              draggable="true"
                              onDragStart={(e) => this.handleFieldDragStart(e, item,index)}
                              onDrop={(e) => this.handleFieldDrop(e)}
                              onDragEnd={(e)=>{e.currentTarget.classList.remove('field-hide')}}
                              onDragOver={(e) => this.handleFieldDragOver(e)}>
                              <img src={item?.profile_image ? process.env.REACT_APP_PROFILE +  item?.profile_image?.medium : ""} 
                              draggable="false"
                              alt="logo" style={{
                                height: '50px',
                                width: '50px',
                                borderRadius:'50%'
                                }}
                                onDoubleClick={()=>this.handleFieldViewPlayer(item)}/>
                              <span style={{fontSize:'11px'}} onDoubleClick={()=>this.handleFieldViewAlias(item)}>{item.alias}</span>
                              <span style={{fontSize:'11px'}} onDoubleClick={()=>this.handleFieldViewPlayer(item)}>{item.name}</span>
                            </div>
                          );
                        })}
                        </div>
                        </div>
                        </div>: null }
                        </>}
                      </DivBlock> 
                      <DivBlock className={"hide-add-btn"}>
                        <Button
                          className={"btn btn-primary"}
                          disabled={SeasonYearData?.[0]?.year === Number(myParam?.year) ? false : true}
                          style={  SeasonYearData?.[0]?.year === Number(myParam?.year)? 
                            {backgroundColor: "#48c46e",
                            borderColor: "#48c46e"}:
                            {backgroundColor: "#a0d2a0",
                            borderColor: "#a0d2a0",}}
                          actions={[
                            {
                              action: "Toggle New Row",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: { "input new row": true },
                            },
                          ]}
                        >
                          Add Row
                        </Button>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>

          {/* Field View Player Update Modal */}
          <If condition={ToggleDepthFieldView ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}>
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Set Player
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Field View Player",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            }
                          ]}>
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}>
                          <DivBlock className={"form-group"}>
                            <Label style={{marginBottom: "1.5px"}} className={"label"}>Submit Player</Label>
                            {isArray(FilterList({ searchData: Users, property: "injured", query: false })) &&
                              <CustomAutoComplete name="position_autocomplete" size="small" id="auto_complete"
                                data={FilterList({ searchData: Users, property: "injured", query: false })?.map(val => { return { ...val, title: `${val?.name} (${val?.position ? val?.position : "None"})` } })}
                                selectedValue={{ ...CurrentDepthChartPosition, currentSelectedPositionData }}
                              />
                            }
                          </DivBlock>

                          <Button
                            className={"btn btn-depthchart"}
                            style={
                              SeasonYearData?.[0]?.year === Number(myParam?.year)? 
                              {backgroundColor: "#48c46e",
                              borderColor: "#48c46e"}:
                              {backgroundColor: "#a0d2a0",
                              borderColor: "#a0d2a0",}
                            }
                            disabled={SeasonYearData?.[0]?.year === Number(myParam?.year) ? false : true}
                            actions={[
                              {
                                action: "Update Field View Player",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  ...currentSelectedPositionData
                                },
                              },
                            ]}
                          >
                            Submit Position Assignment
                          </Button>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          
          <If condition={DepthChartToDuplicate ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Duplicate Depth Chart
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Duplicate Depth Chart",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "duplicate depth chart id":null,
                                "duplicate depth chart name": null,
                                "duplicate depth chart" : false,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <DivBlock>
                            <Label className={"label"}>
                              Are you sure you want to duplicate this package?
                            </Label>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-reset DeleteBtn"}
                              style={{}}
                              actions={[
                                {
                                  action: "Set Duplicate Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { 
                                    "duplicate depth chart id":null,
                                    "duplicate depth chart name": null,
                                    "duplicate depth chart" : false,
                                  },
                                },
                              ]}
                            >
                              Cancel
                            </Button>

                            <Button
                              className={"btn btn-primary"}
                              style={{}}
                              actions={[
                                {
                                  action: "Duplicate Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    'duplicate depth chart':false
                                  },
                                },
                              ]}
                            >
                              Yes
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>


           {/* Table View Player Update Modal */}
          <If condition={CurrentDepthChartPosition ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Set Player
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Current Depth Chart Position",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "current depth chart position input": null,
                              },
                            },
                            // {
                            //   action: "Toggle DepthChartUser DropDown",
                            //   trigger: "onclick",
                            //   timeout: "",
                            //   pollingPeriod: "",
                            //   inputVariables: {
                            //     "input depth chart user": false,
                            //   },
                            // },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create User",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label style={{marginBottom: "1.5px"}} className={"label"}>Submit Player</Label>
                            {isArray(FilterList({ searchData: Users, property: "injured", query: false })) &&
                              <CustomAutoComplete name="position_autocomplete" size="small" id="auto_complete" className={"joyride-depth-chart-name-search"}
                                data={FilterList({ searchData: Users, property: "injured", query: false })?.map(val => { return { ...val, title: `${val?.name} (${val?.position ? val?.position : "None"})` } })}
                                selectedValue={{ ...CurrentDepthChartPosition, currentSelectedPositionData }}
                              />
                            }
                          </DivBlock>

                          <Button
                            className={"btn btn-depthchart joyride-depth_chart-submit-player"}
                            style={
                              SeasonYearData?.[0]?.year === Number(myParam?.year)? 
                              {backgroundColor: "#48c46e",
                              borderColor: "#48c46e"}:
                              {backgroundColor: "#a0d2a0",
                              borderColor: "#a0d2a0",}
                            }
                            disabled={SeasonYearData?.[0]?.year === Number(myParam?.year) ? false : true}
                            actions={[
                              {
                                action: "Set Depth Chart User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  ...currentSelectedPositionData
                                },
                              },
                            ]}
                          >
                            Submit Position Assignment
                          </Button>
                        </FormBlock>

                        <FormBlock className={"DeleteFrom"} style={{}}>
                          <Button
                            className={"btn btn-cancel"}
                            style={
                              SeasonYearData?.[0]?.year === Number(myParam?.year)? 
                              {backgroundColor: "#cacaca",
                              borderColor: "#cacaca"}:
                              {backgroundColor: "#a0d2a0",
                              borderColor: "#a0d2a0",}
                            }
                            disabled={SeasonYearData?.[0]?.year === Number(myParam?.year) ? false : true}
                            actions={[
                              {
                                action: "Delete Depth Chart User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input depth chart user id":
                                    CurrentDepthChartPosition
                                      ? CurrentDepthChartPosition["user_id"]
                                      : 0,
                                },
                              },
                            ]}
                          >
                            Remove Player Assignment
                          </Button>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If
            condition={EditDepthChart || EditDepthChart ? true : false}
            show={false}
            style={{ maxWidth: "auto", minWidth: "auto", width: "auto" }}
          >
            <Then>
              <DivBlock
                className={"modal"}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Edit Depth Chart
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Edit Depth Chart",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "input edit depth chart": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Title</Label>

                            <TextInput
                              className={"form-control"}
                              initialValue={
                                EditDepthChart ? EditDepthChart.title : ""
                              }
                              name={"FormEditDepthChart.title"}
                              placeholder={""}
                            ></TextInput>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.title
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["title"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Category</Label>

                            <SelectDropDown
                              className={"form-control"}
                              initialValue={
                                EditDepthChart
                                  ? EditDepthChart.category
                                  : "Offense"
                              }
                              name={"FormEditDepthChart.category"}
                              options={[
                                { label: "Offense", value: "Offense" },
                                { label: "Defense", value: "Defense" },
                                {
                                  label: "Special Teams",
                                  value: "Special Teams",
                                },
                              ]}
                            ></SelectDropDown>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.category
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["category"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Week</Label>

                            <SelectDropDown
                              className={"form-control"}
                              dataSet={Weeks()}
                              dataSetLabel={"name"}
                              dataSetValue={"id"}
                              initialValue={
                                EditDepthChart ? EditDepthChart.week : 0
                              }
                              name={"FormEditDepthChart.week"}
                              options={[]}
                              type={"number"}
                            ></SelectDropDown>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.week
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["week"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>
                              Tag (Use comma with no space for multiple tags)
                            </Label>

                            <TextInput
                              className={"form-control"}
                              initialValue={
                                EditDepthChart ? EditDepthChart.tags : ""
                              }
                              name={"FormEditDepthChart.Tags"}
                              placeholder={""}
                            ></TextInput>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Edit Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Save
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If condition={NewRow} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          New Row
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Toggle New Row",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "input edit depth chart": null,
                                "input new row": false,
                                "input position": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create Depth Chart",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Position</Label>

                            <SelectDropDown
                              className={"form-control"}
                              dataSet={FilterList({
                                searchData: Positions,
                                property: "category",
                                query: CurrentDepthChart
                                  ? CurrentDepthChart["category"]
                                  : "",
                              })}
                              dataSetLabel={"name"}
                              dataSetValue={"id"}
                              enableReinitialize={true}
                              initialValue={
                                FilterList({
                                  searchData: Positions,
                                  property: "category",
                                  query: CurrentDepthChart
                                    ? CurrentDepthChart["category"]
                                    : "",
                                }).length > 0
                                  ? FilterList({
                                    searchData: Positions,
                                    property: "category",
                                    query: CurrentDepthChart
                                      ? CurrentDepthChart["category"]
                                      : "",
                                  })[0].id
                                  : 0
                              }
                              name={"FormNewDepthChartRow.position id"}
                              options={[]}
                              type={"number"}
                            ></SelectDropDown>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.title
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["title"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Create New Row",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { row_deletable: 1 },
                                },
                              ]}
                            >
                              Save
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If
            condition={CurrentAlias && CurrentAlias.alias_id > 0 ? true : false}
            show={false}
          >
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Update Position
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Current Alias",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "alias id input": null,
                                "input edit depth chart": null,
                                "input new row": false,
                                "input position": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create Depth Chart",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Alias</Label>

                            <TextInput
                              initialValue={AliasName}
                              name={"FormAlias"}
                              placeholder={""}
                              style={{
                                background: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                color: "#495057",
                                display: "block",
                                fontSize: "1rem",
                                fontWeight: 400,
                                lineHeight: 1.5,
                                paddingBottom: "0.375rem",
                                paddingLeft: "0.75rem",
                                paddingRight: "0.75rem",
                                paddingTop: "0.375rem",
                                width: "100%",
                              }}
                            ></TextInput>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.title
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["title"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Update Depth Chart Alias",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    value:tableView==="field_view" ? true : false
                                  },
                                },
                              ]}
                            >
                              Save
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If
            condition={
              CurrentAlias &&
                CurrentAlias.alias_id === null &&
                CurrentAlias.positions !== null
                ? true
                : false
            }
            show={false}
          >
            <Then>
              <DivBlock
                className={"modal"}
                actions={[
                  {
                    action: "Set New Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input new position": false },
                  },
                  {
                    action: "Edit Position",
                    trigger: "onclick",
                    timeout: "",
                    pollingPeriod: "",
                    inputVariables: { "input position": null },
                  },
                ]}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          New Position
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Current Alias",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "alias id input": null,
                                "input edit depth chart": null,
                                "input new row": false,
                                "input position": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create Depth Chart",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Position</Label>

                            <TextInput
                              name={"FormAlias"}
                              placeholder={""}
                              style={{
                                background: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                color: "#495057",
                                display: "block",
                                fontSize: "1rem",
                                fontWeight: 400,
                                lineHeight: 1.5,
                                paddingBottom: "0.375rem",
                                paddingLeft: "0.75rem",
                                paddingRight: "0.75rem",
                                paddingTop: "0.375rem",
                                width: "100%",
                              }}
                            ></TextInput>

                            <If
                              condition={
                                FormEditDepthChartErrors &&
                                  FormEditDepthChartErrors.title
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormEditDepthChartErrors
                                    ? FormEditDepthChartErrors["title"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Create Depth Chart Alias",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {},
                                },
                              ]}
                            >
                              Save
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If condition={DepthChartToDelete ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Delete Depth Chart
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Delete Depth Chart",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "Depth Chart Id": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <DivBlock>
                            <Label className={"label"}>
                              Are you sure you want to delete this package?
                            </Label>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-reset DeleteBtn"}
                              style={{}}
                              actions={[
                                {
                                  action: "Set Delete Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: { "Depth Chart Id": null },
                                },
                              ]}
                            >
                              Cancel
                            </Button>

                            <Button
                              className={"btn btn-primary"}
                              style={{}}
                              actions={[
                                {
                                  action: "Delete Depth Chart",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "depth chart id to delete":
                                      CurrentDepthChart
                                        ? CurrentDepthChart["Id"]
                                        : "",
                                        "Depth Chart Id": null,
                                  },
                                },
                              ]}
                            >
                              Yes
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>

          <If condition={DepthChartRowToDelete ? true : false} show={false}>
            <Then>
              <DivBlock
                className={"modal"}
              >
                <DivBlock className={"modal-dialog"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-content"}>
                      <DivBlock className={"modal-header"}>
                        <Heading
                          className="modal-heading"
                          type={"h5"}
                        >
                          Delete Depth Chart Row
                        </Heading>

                        <Span
                          className={"close"}
                          actions={[
                            {
                              action: "Set Delete Depth Chart Row",
                              trigger: "onclick",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {
                                "Delete Depth Chart Row Id": null,
                              },
                            },
                          ]}
                        >
                          x
                        </Span>
                      </DivBlock>

                      <DivBlock className={"modal-body"}>
                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}  
                        >
                          <DivBlock>
                            <Label className={"label"}>
                              Are you sure you want to delete this depth chart
                              row?
                            </Label>
                          </DivBlock>

                          <DivBlock
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className={"btn btn-reset DeleteBtn"}
                              actions={[
                                {
                                  action: "Set Delete Depth Chart Row",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "Delete Depth Chart Row Id": null,
                                  },
                                },
                              ]}
                            >
                              Cancel
                            </Button>

                            <Button
                              className={"btn btn-primary"}
                              actions={[
                                {
                                  action: "Delete Depth Chart Row",
                                  trigger: "onclick",
                                  timeout: "",
                                  pollingPeriod: "",
                                  inputVariables: {
                                    "Depth Chart row id": DepthChartRowToDelete,
                                  },
                                },
                              ]}
                            >
                              Yes
                            </Button>
                          </DivBlock>
                        </FormBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </Then>
          </If>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDepthChartsUsers: (inputVariables) => {
      dispatch({ type: "GET_DEPTH_CHART_USERS", inputVariables:{isDefault:true} });
      return Promise.resolve();
    },
    updateDragUser: (inputVariables) => {
      dispatch({ type: "UPDATE_DRAG_USER", inputVariables });
      return Promise.resolve();
    },
    updateDropUser: (inputVariables) => {
      dispatch({ type: "UPDATE_DROP_USER", inputVariables });
      return Promise.resolve();
    },
    updateFieldView: (inputVariables) => {
      dispatch({ type: "UPDATE_DEPTH_CHART_FIELD_VIEW", inputVariables });
      return Promise.resolve();
    },
    setFieldViewPlayer: (inputVariables) => {
      dispatch({ type: "SET_FIELD_VIEW_PLAYER", inputVariables });
      return Promise.resolve();
    },
    setFieldViewAlias: (inputVariables) => {
      dispatch({ type: "SET_CURRENT_ALIAS", inputVariables });
      return Promise.resolve();
    },
    clearDepthChartValues : () =>{
      dispatch({type:"CLEAR_DEPTH_CHART_VALUES"})
      return Promise.resolve();
    },
    getDepthChartData: () => {
      dispatch({ type: "GET_BASE_DEPTH_CHARTS" });
      return Promise.resolve();
    },
    getTagsData: () => {
      dispatch({ type: "GET_TAGS" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepthChartPage));
