import * as actions from "./actions.js";
import _, { isArray } from "lodash";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

let d = new Date();
const initialState = {
  // _app_initialized: false,
  IsExistGameIds: [],
  initialCheckbox: {},
  isLoaded: false,
  isDefaultLoaded: true,
  currentSelectedPositionData: {},
  DepthChartPackageId: "",
  DepthChartPackageEnableButton: {},
  ExistChartIds: [],
  getPlayGradesIds: [],
  messages: new Map(),
  tasks: new Map(),
  AccordionIndex: false,
  AddPlayer: 0,
  AliasName: "",
  AverageGradesMsg: "",
  AverageIntangible: { game_id: null, user_id: null, averages: null },
  CheckEmail: "Email already exists",
  CheckSchool: "School already exists",
  CheckTitle: "Maximum 10 Custom Fields are allowed",
  Coach: "Coach",
  CoachToDelete: 0,
  CountPlay: "No more columns are allowed",
  CreatePostionCoachSuccess: "",
  CsvMapping: {
    email: null,
    graduation_year: null,
    position_id: null,
    number: null,
    fullname: null,
    height: null,
    weight: null,
    secondary:null,
  },
  CsvPlayMapping: {
    qtr: null,
    series: null,
    down: null,
    distance:null,
    index: null,
    name: null,
    depth_chart_id: null,
    game_id: null,
    type: null,
    data: null,
    title: null,
  },
  CurrentAlias: {
    id: null,
    abbreviation: null,
    category: null,
    positions: null,
    row_id: null,
    alias: null,
    alias_id: null,
  },
  CurrentDepthChart: {
    Id: null,
    created_at: null,
    category: null,
    week: null,
    school_id: null,
    base: null,
    title: null,
    shared: null,
    tags: null,
  },
  CurrentDepthChartId: 0,
  CurrentDropdownPackage: 0,
  CurrentFieldId: 0,
  CurrentPackage: 0,
  CurrentPlayDepthChartId: 0,
  CurrentPlayId: 0,
  CurrentPlayPackage: "",
  CurrentPlay: "",
  CurrentName: "",
  CurrentTitle: "",
  CurrentTitleId: 0,
  CurrentUser: {
    // id: null,
    // active: null,
    // user_type: null,
    // name: null,
    // school_id: null,
    // password: null,
    // email: null,
    // created_at: null,
    // email_confirmed: null,
    // weight: null,
    // height: null,
    // grade: null,
    // graduation_year: null,
    // position_id: null,
    // number: null,
    // school_name: null,
    // notes: null,
    // scale_type: null,
    // category: null,
    // height_two: null,
  },
  CurrentUserName: "",
  CustomFieldsCsvMappingCache: { index: null, name: null },
  defense: "Defense",
  DeletePositionCoachSuccess: "",
  Demo: 0,
  DepthChartCategory: "",
  DepthChartListWeekFilter: 0,
  DepthChartListsCategoryFilter: "",
  DepthChartListsTagFilter: "",
  DepthChartPositionUsers: {
    row_id: null,
    name: null,
    position_id: null,
    positions: null,
  },
  DepthChartRowToDelete: 0,
  DepthChartTitle: "",
  DepthChartToDelete: 0,
  DepthChartToDeleteDepthChartId: 0,
  DepthChartToDeletePositionId: 0,
  DepthChartUserDropDown: false,
  DropDownBasePackagesFilter: false,
  DropdownIntangibleFilter: false,
  DropdownManageGame: false,
  DropdownManagePackage: false,
  DropdownRosterFilter: false,
  DropdownSelectPackage: false,
  DropdownUserSettings: false,
  DropdownWeekPackagesFilter: false,
  DuplicateEmail: "",
  DuplicatePositionCoach: "",
  DuplicateSchool: "",
  DepthChartPlayIdVariable: 0,
  DepthChartPlayIdVariableFlag: false,
  EditDown: 0,
  EditField: "",
  EditIndex: 0,
  EditName: "",
  EditNotes: "",
  EditPackage: 0,
  EditProductionPoint: "",
  EditQtr: 0,
  EditSeries: 0,
  EditType: "",
  EditUserExercise: { name: null, id: null, date: null, integer_value: null },
  Emptystring: "",
  ExerciseData: "",
  ExerciseToDelete: 0,
  EditScaleTypeError: "",
  FalseCheckbox: false,
  FieldData: "",
  FieldTitle: "",
  FileToDelete: 0,
  PlayReportsMultipleData: {},
  collapse:{},
  currentPosition:0,
  FirstDepthChart: {
    title: null,
    category: null,
    week: null,
    school_id: null,
    base: null,
    shared: null,
    id: null,
    created_at: null,
  },
  FormAlias: "",
  FormCreateDepthChart: {
    title: null,
    week: null,
    category: null,
    Tags: null,
    shared: null,
  },
  FormCreateDepthChartErrors: { category: null, week: null, title: null },
  FormCreateDepthChartRegister: {
    title: null,
    week: null,
    category: null,
    Tags: null,
    shared: null,
    school_id: null,
    base: null,
  },
  FormCreateDepthChartRegisterErrors: {
    category: null,
    week: null,
    title: null,
  },
  FormCustomField: "",
  FormEditDepthChart: {
    title: null,
    week: null,
    category: null,
    Tags: null,
    shared: null,
  },
  FormEditDepthChartErrors: { category: null, week: null, title: null },
  FormEditPositionCoach: {
    email: null,
    category: null,
    name: null,
    position_id: null,
    active: null,
    positions: null,
    position_data: null
  },
  FormEditPositionCoachError: {
    email: null,
    category: null,
    name: null,
    position_id: null,
    active: null,
    position_data: null
  },
  FormEditUser: {
    name: null,
    weight: null,
    number: null,
    grade: null,
    "position id": null,
    height: null,
    notes: null,
    active: null,
    "graduation year": null,
    secondary_positions: null,
    category: null,
    email: null,
    "height two": null,
  },
  FormEditUserErrors: {
    name: null,
    weight: null,
    number: null,
    grade: null,
    "position id": null,
    height: null,
    notes: null,
    active: null,
    "graduation year": null,
    secondary_positions: null,
    category: null,
    email: null,
    "height two": null,
  },
  FormNewData: "",
  FormNewPlayUser: 0,
  FormNewUser: {
    name: null,
    weight: null,
    number: null,
    grade: null,
    "position id": null,
    height: null,
    notes: null,
    active: null,
    "graduation year": null,
    secondary_positions: null,
    category: null,
    email: null,
    "height two": null,
  },
  FormNewUserErrors: {
    name: null,
    weight: null,
    number: null,
    grade: null,
    "position id": null,
    height: null,
    notes: null,
    active: null,
    "graduation year": null,
    secondary_positions: null,
    category: null,
    email: null,
    "height two": null,
  },
  FormPlayDown: 0,
  FormPlayDistance: 0,
  FormPlayIndex: 0,
  FormPlayName: "",
  FormPlayPackageId: 0,
  FormPlayQtr: 0,
  FormPlaySeries: 0,
  FormPlayType: "",
  FormPlayUserNotes: "",
  FormProductionPoint: "",
  FormQuizResponse: {
    "question responses": null,
    comment: null,
    "quiz id": null,
  },
  ConfigurationColumn: {},
  FormSearchGradesDate: "",
  FormSearchGradesPosition: 0,
  FormSearchGradesType: "Game",
  FormSignup: {
    name: null,
    school_id: null,
    user_type: null,
    email: null,
    password: null,
    confirm_password: null,
    terms_and_conditions: false,
    school: null,
  },
  FormSignupConfirmPassword: "",
  FormSignupErrors: {
    name: null,
    school_id: null,
    user_type: null,
    email: null,
    password: null,
    confirm_password: null,
    terms_and_conditions: null,
    school: null,
  },
  GameDateResult: "",
  GameToDelete: 0,
  GameTypeMsg: "Game",
  Games: {
    id: null,
    created_at: null,
    title: null,
    "school id": null,
    date: null,
  },
  GraphGradesData: "",
  InjuredPlayerError:
    "A player must have a primary position to be added to the injury list. Please go to roster to edit player and assign them a primary position.",
  InjuredPlayerToDelete: 0,
  IntangibleData: "",
  IntangibleToDelete: 0,
  InvalidSchoolError:
    "Sorry, but that University already exists. If you think there is an issue, please contact us.",
  IsLoggedIn: false,
  LastIndex: 0,
  MaximumTitles: "",
  NavBarDropDownIndex: 9,
  NewData: false,
  NewExercise: false,
  NewField: "",
  NewGame: false,
  NewGames: false,
  NewInjuredPlayer: false,
  NewIntangible: false,
  NewModal: false,
  NewPlayRow: false,
  NewPosition: false,
  NewPositionCoach: false,
  NewQuiz: false,
  NewQuizQuestion: false,
  NewRow: false,
  NewUser: false,
  NewUserExercise: false,
  NoDataDepthChartUser: "",
  NullPositionError: "",
  Nullvalue: "null",
  One: 1,
  PageDepthChartsList: "/depth_chart_lists",
  PageGameGrades: "/play_grades",
  PlayDepthChart: {
    game_id: null,
    category: null,
    shared: null,
    cfid: null,
    data: null,
    school_id: null,
    created_at: null,
    title: null,
    index: null,
    package: null,
    type: null,
    name: null,
    down: null,
    series: null,
    qtr: null,
    custom_field: null,
    base: null,
    title_id: null,
    week: null,
    id: null,
    depth_chart_id: null,
  },
  PlayGradesPage: "/play_grades",
  PlayLimit: "",
  PlayRowToDelete: false,
  Player: "Player",
  PlayerComparisonFilter: 0,
  PlayerComparisonIntangible: 0,
  PlayerExerciseToDelete: 0,
  PlayerGradesUserCount: { count: null },
  PlayerReportDataUser: -1,
  PlayerReportUsersCount: 0,
  Playid: 0,
  PositionCoach: "Position Coach",
  PositionCoachPassword: "",
  PositionCoachUserType: "Position Coach",
  PositionSelected: "",
  PositionsCoachUserType: "Position Coach",
  Practices: {
    id: null,
    created_at: null,
    title: null,
    "school id": null,
    date: null,
  },
  PlayTypeArray: [
    { label: "All", value: -1 },
    { label: "Run", value: 0 },
    { label: "Pass", value: 1 },
    { label: "RPO", value: 2 },
    { label: "Screen", value: 3 },
    { label: "Punt", value: 4 },
    { label: "Kickoff", value: 5 },
    { label: "FG", value: 6 },
    { label: "PAT", value: 7 },
    { label: "2 PT", value: 8 },
  ],
  SeriesTypeArray: [],
  QuarterData: [
    { label: "All", value: "-1" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ],
  DownDropDownData: [
    { label: "All", value: "-1" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ],
  CategoryCollection: [
    { label: "All", value: "All" },
    { label: "Defense", value: "Defense" },
    { label: "Offense", value: "Offense" },
    { label: "Special Teams", value: "Special Teams" },
  ],
  ReportsCurrentPackage: "",
  ReportsDate: "",
  ResetPasswordMessage: "",
  ResetPasswordRequestResponseText: "",
  RoasterUploadSuccessMessage:
    "You have successfully uploaded your roster. If your roster included email addresses for players and you chose to send out a welcome email, a CoachTools invite has been emailed out to those players inviting them to login.",
  RosterFilter: "",
  Run: "Run",
  SignInData:{},
  SendEmail: false,
  ShowPlayerComprison: false,
  ShowUserDropDownError: "",
  ShowUserGraph: 0,
  SortingPlayIndex: false,
  SortingPlayName: false,
  SortingPlayer: false,
  SortingPositions: false,
  SortingIntangibleSettings: true,
  SortingSettings: true,
  BaseDepthChartSorting:true,
  AllDepthChartSorting:true,
  SpecialTeams: "Special Teams",
  SuccessMessage: "",
  SuccessPositionCoachDelete:
    "The position coach has been deleted successfully.",
  SuccessPositionCoachEdit: "The Position Coach has been updated successfully.",
  SuccessPositionCoachMessage:
    "New coach has been added and an email invite has been sent. You can resend the email invite from the coaches page.",
  SuccessResendEmail: "The email has been sent successfully.",
  SelectedPositionId: null,
  SelectedCustomFields: null,
  SelectedPlayerPositionId: null,
  SelectedPlayerGameAndPracticeId: -1,
  SelectedCategoryId: null,
  SelectedPlayTypeId: null,
  SelectedQuarterId: null,
  SelectedSeriesId: null,
  SelectedDownId: null,
  SelectedCustomFieldId: null,
  TD: "None",
  TagToDelete: 0,
  ToggleDeletePlayDepthChartUser: false,
  ToggleDepthChart: false,
  ToggleDown: false,
  ToggleField: false,
  ToggleGameDelete: false,
  ToggleIndex: false,
  ToggleName: false,
  ToggleNotes: false,
  TogglePackage: false,
  ToggleProductionPoint: false,
  ToggleQtr: false,
  ToggleReset: false,
  ToggleSeries: false,
  ToggleTrueReset: "True",
  ToggleType: false,
  ToggleUserDropDown: false,
  TotalPlays: 0,
  TrueCheckbox: true,
  TwoHundred: 200,
  FiveHundred: 500,
  Unauthorized: "",
  UpdatedPackage: 0,
  UserToDelete: 0,
  UserToUpdate: 0,
  ViewPlayer: {
    number: null,
    secondary: null,
    position: null,
    weight: null,
    grade: null,
    height: null,
    position_id: null,
    avg_game_grade: null,
    avg_practice_grade: null,
    notes: null,
    email: null,
    user_type: null,
    name: null,
    graduation_year: null,
    Id: null,
    injured: null,
    active: null,
    category: null,
  },
  WeekDepthChartError: "",
  Zero: 0,
  csv_play_mapping_titles_cache: { titles: null, cache: null },
  currentCustomTitleCsvPlayMappingIndex: 0,
  currentintegervalue: 0,
  currentuserintegervalue: "",
  demoGrade: 0,
  depthchartlistpage: "/depth_chart_lists",
  fileimg: "fa fa-align-justify",
  isUnauthorized: false,
  loginPage: "/login",
  notes: "None",
  offense: "Offense",
  pagepath: "/head_coach",
  playmapping: { mapping: null, id: null },
  position: "",
  rostermapping: { mapping: null, id: null },
  savedgrade: "",
  tablecss: "table table-striped",
  weekError: "No Packages Found",
  weeks: 0,
  PlaySortingTable: true,
  CompareSortingTable: true,
  TrainingSortingTable:true,
  PlayerSortingTable: true,
  PlayIdPackageVariable: 0,
  GradingScaleTypeOptionsData:[
    {
      label: "Binary (Plus - Minus)",
      value: "1",
    },
    {
      label: "Binary (Zero - One)",
      value: "2",
    },
    {
      label: "Zero - Half - One",
      value: "12",
    },
    {
      label:
        "Tertiary (One - Two - Three)",
      value: "3",
    },
    {
      label:
        "Tertiary (Zero - One - Two)",
      value: "4",
    },
    {
      label:
        "Four (Zero - One - Two - Three)",
      value: "11",
    },
    {
      label:
        "Advanced plus - minus 1",
      value: "5",
    },
    {
      label:
        "Advanced plus - minus 2",
      value: "6",
    },
    { label: "Three", value: "7" },
    { label: "Five", value: "8" },
    { label: "Ten", value: "9" },
    { label: "Hundred", value: "10" },
  ],
  IntangibleValueNameTemp: [
    {
      "position id": 1,
      name: "Effort",
    },
    {
      "position id": 2,
      name: "Alignment",
    },
    {
      "position id": 3,
      name: "Assignment",
    },
    {
      "position id": 4,
      name: "Technique",
    },
    {
      "position id": 5,
      name: "Execution",
    },
    {
      "position id": 6,
      name: "Finish",
    },
    {
      "position id": 7,
      name: "Accuracy",
    },
    {
      "position id": 8,
      name: "Hand Placement",
    },
    {
      "position id": 9,
      name: "Impact",
    },
    {
      "position id": 10,
      name: "Fit",
    },
    {
      "position id": 11,
      name: "Read",
    },
    {
      "position id": 12,
      name: "Release",
    },
    {
      "position id": 13,
      name: "Decision",
    },
    {
      "position id": 14,
      name: "Footwork",
    },
    {
      "position id": 15,
      name: "Job",
    },
    {
      "position id": 16,
      name: "Production",
    }
  ],
  IntangibleValueName: [
    { name: "Alignment", value: false, positionName: "" },
    { name: "Assignment", value: false, positionName: "" },
    { name: "Technique", value: false, positionName: "" },
    { name: "Execution", value: false, positionName: "" },
    { name: "Production", value: false, positionName: "" },
    { name: "Effort", value: false, positionName: "" },
    { name: "Job", value: false, positionName: "" },
    { name: "Finish", value: false, positionName: "" },
    { name: "Accuracy", value: false, positionName: "" },
    { name: "Hand Placement", value: false, positionName: "" },
    { name: "Impact", value: false, positionName: "" },
    { name: "Fit", value: false, positionName: "" },
    { name: "Read", value: false, positionName: "" },
    { name: "Release", value: false, positionName: "" },
    { name: "Decision", value: false, positionName: "" },
    { name: "Footwork", value: false, positionName: "" },
    { name: "Vision", value: false, positionName: "" },
    { name: "Hustle", value: false, positionName: "" },
    { name: "Block", value: false, positionName: "" },
    { name: "Catch", value: false, positionName: "" },
    { name: "Throw", value: false, positionName: "" },
    { name: "Toughness", value: false, positionName: "" },
    { name: "Preparation", value: false, positionName: "" },
    { name: "Resilience", value: false, positionName: "" },
    { name: "Tackle", value: false, positionName: "" },
    { name: "Sack", value: false, positionName: "" },
    { name: "TFL", value: false, positionName: "" },
    { name: "INT", value: false, positionName: "" },
    { name: "PBU", value: false, positionName: "" },
    { name: "Decision Making", value: false, positionName: "" },
    { name: "Eye Progression", value: false, positionName: "" },
    { name: "Base", value: false, positionName: "" },
    { name: "Stance and Start", value: false, positionName: "" },
    { name: "Pad Level", value: false, positionName: "" },
    { name: "Responsibilities", value: false, positionName: "" },
    { name: "Keys", value: false, positionName: "" },
    { name: "Hand Violence", value: false, positionName: "" },
    { name: "Aggression", value: false, positionName: "" },
    { name: "Mesh", value: false, positionName: "" },
    { name: "Ball Placement", value: false, positionName: "" },
    { name: "BIG", value: false, positionName: "" },
  ],
  // ProductionPointsData:[
  //   {
  //     label: "TD",
  //     value: "TD",
  //   },
  //   {
  //     label: "1st Down",
  //     value: "1st Down",
  //   },
  //   {
  //     label: "Completion",
  //     value: "Completion",
  //   },
  //   {
  //     label: "Big Play",
  //     value: "Big Play",
  //   },
  //   {
  //     label: "Tackle",
  //     value: "Tackle",
  //   },
  //   {
  //     label: "Sack",
  //     value: "Sack",
  //   },
  //   {
  //     label: "Interception",
  //     value: "Interception",
  //   },
  //   {
  //     label: "PBU",
  //     value: "PBU",
  //   },
  //   {
  //     label: "Recovered Fumble",
  //     value: "Recovered Fumble",
  //   },
  //   {
  //     label: "Block",
  //     value: "Block",
  //   },
  //   {
  //     label: "Missed Block",
  //     value: "Missed Block",
  //   },
  //   {
  //     label: "Penalty",
  //     value: "Penalty",
  //   },
  //   {
  //     label: "Turnover",
  //     value: "Turnover",
  //   },
  //   {
  //     label:
  //       "Missed Assignment",
  //     value:
  //       "Missed Assignment",
  //   },
  //   {
  //     label: "Tackle for loss",
  //     value: "Tackle for loss",
  //   },
  //   {
  //     label: "Assist",
  //     value: "Assist",
  //   },
  //   {
  //     label: "Big Hit",
  //     value: "Big Hit",
  //   },
  //   {
  //     label: "Extra Effort",
  //     value: "Extra Effort",
  //   },
  //   {
  //     label: "Forced Fumble",
  //     value: "Forced Fumble",
  //   },
  //   {
  //     label: "Knock Back",
  //     value: "Knock Back",
  //   },
  //   {
  //     label: "Pressure",
  //     value: "Pressure",
  //   },
  //   {
  //     label: "Safety",
  //     value: "Safety",
  //   },
  //   {
  //     label: "Blocked",
  //     value: "Blocked",
  //   },
  //   {
  //     label: "Miss Aligned",
  //     value: "Miss Aligned",
  //   },
  //   {
  //     label: "Mental Error",
  //     value: "Mental Error",
  //   },
  //   {
  //     label: "Missed Tackle",
  //     value: "Missed Tackle",
  //   },
  //   {
  //     label: "Loaf",
  //     value: "Loaf",
  //   },
  //   {
  //     label: "Missed Sack",
  //     value: "Missed Sack",
  //   },
  //   {
  //     label:
  //       "Big Play Given Up",
  //     value:
  //       "Big Play Given Up",
  //   },
  //   {
  //     label: "Bad Technique",
  //     value: "Bad Technique",
  //   },
  //   {
  //     label: "Foolish Penalty",
  //     value: "Foolish Penalty",
  //   },
  //   {
  //     label: "Drop",
  //     value: "Drop",
  //   },
  // ],
  SelectedIntangible: {},
  Offense: [
    {
      id: 1,
      name: "QB",
    },
    {
      id: 2,
      name: "RB",
    },
    {
      id: 3,
      name: "WR",
    },
    {
      id: 4,
      name: "TE",
    },
    {
      id: 5,
      name: "OL",
    },
  ],
  Defense: [
    {
      id: 1,
      name: "DL",
    },
    {
      id: 2,
      name: "LB",
    },
    {
      id: 3,
      name: "DB",
    },
  ],
  Special: [
    {
      id: 1,
      name: "K/P",
    },
    {
      id: 2,
      name: "LS",
    },
  ],

  DepthChartType: [
    { id: 0, name: "All" },
    { id: 1, name: "Defense" },
    { id: 2, name: "Offense" },
    { id: 3, name: "Special Teams" },
  ],
  storeEditedPosition: "",
  PlayerComparisonIntangibleCollection:[],
  NoteInfo:false,
  NoteInfoData:'',
  AverageByPlay:[],
  AverageByIntangible:[],
  Team:[],
  filterPositions:0,
  NextData:{},
  DepthChartIndex:0,
  viewUser:false,
  CurrentDepthChartData:{},
  SelectedWeekId:0,
  SelectedTypeId:'All',
  SelectedTagId:'All',
  ResendEmailAll : false,
  DepthChartToDuplicate:false,
  DuplicateDepthChartData:{},
  RosterMappingError:'',
  filterPlayerPositions:-1, 
  PlayerCompare:[],
  filterPlayerIntangible:-1,
  BulkDropDownData:[
    { label: "Bulk edit", value: 'bulk edit' },
    { label: "Set Package", value: 'setpackage'},
    { label: "Duplicate", value: 'duplicate' },
    { label: "Delete", value: 'delete'},
  ],
  SelectedBulkType:'',
  BulkDeleteModal:false,
  BulkSelectedDropDownData:[],
  gradingBulkDataClear:false,
  BulkGradingDropDownData:[
    { label: "Bulk edit", value: 'bulk edit' },
    { label: "Grade", value: 'grade' },
  ],
  GradingPositionScaleTypeData:{
    1:[
      {label: "+",value:100},
      {label: "-",value:0},
      {label:"None",value:"NULL",},
    ],
    2:[
      {label: "1",value: "100",},
      {label: "0",value: "0",},
      {label:"None",value:"NULL",},
    ],
    3:[
      {
        label:
        "3",
        value:
        "100",
      },
      {
        label:
        "2",
        value:
        "50",
      },
      {
        label:
        "1",
        value:
        "0",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    4:[
      {
        label:
        "2",
        value:
          "100",
      },
      {
        label:
        "1",
        value:
        "50",
      },
      {
        label:
        "0",
        value:
        "0",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    5:[
      {
        label:
        "++",
        value:
          "100",
      },
      {
        label:
          "+",
          value:
          "66",
        },
        {
          label:
          "-",
          value:
          "33",
        },
        {
          label:
          "--",
          value:
          "0",
        },
        {
          label:
          "None",
          value:
            "NULL",
        },
      ],
    6:[
        {
          label:
          "++",
          value:
          "100",
        },
        {
          label:
          "+",
          value:
          "75",
        },
        {
          label:
          "0",
        value:
          "50",
      },
      {
        label:
        "-",
        value:
        "25",
      },
      {
        label:
        "--",
        value:
        "0",
      },
      {
        label:
        "None",
        value:
        "NULL",
      },
    ],
    7:[
      {
        label:
        "3",
        value:
          "100",
      },
      {
        label:
        "2",
        value:
        "50",
      },
      {
        label:
        "1",
        value:
        "0",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    8:[
      {
        label:
          "5",
        value:
          "100",
      },
      {
        label:
        "4",
        value:
        "75",
      },
      {
        label:
        "3",
        value:
        "50",
      },
      {
        label:
        "2",
        value:
        "25",
      },
      {
        label:
        "1",
        value:
        "0",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    9:[
      {
        label:
        "10",
        value:
        "100",
      },
      {
        label:
        "9",
        value:
        "90",
      },
      {
        label:
        "8",
        value:
        "80",
      },
      {
        label:
        "7",
        value:
        "70",
      },
      {
        label:
          "6",
          value:
          "60",
        },
        {
          label:
          "5",
          value:
          "50",
        },
        {
          label:
          "4",
          value:
          "40",
        },
        {
          label:
          "3",
        value:
        "30",
      },
      {
        label:
        "2",
        value:
        "20",
      },
      {
        label:
        "1",
        value:
        "10",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    10:[
      {
        label:
          "100",
        value:
          "100",
      },
      {
        label:
          "99",
        value:
          "99",
      },
      {
        label:
          "98",
        value:
          "98",
      },
      {
        label:
          "97",
        value:
          "97",
      },
      {
        label:
          "96",
        value:
          "96",
      },
      {
        label:
          "95",
        value:
          "95",
      },
      {
        label:
          "94",
        value:
          "94",
      },
      {
        label:
          "93",
        value:
          "93",
      },
      {
        label:
          "92",
        value:
          "92",
      },
      {
        label:
          "91",
        value:
          "91",
      },
      {
        label:
          "90",
        value:
          "90",
      },
      {
        label:
          "89",
        value:
          "89",
      },
      {
        label:
          "88",
        value:
          "88",
      },
      {
        label:
          "87",
        value:
          "87",
      },
      {
        label:
          "86",
        value:
          "86",
      },
      {
        label:
          "85",
        value:
          "85",
      },
      {
        label:
          "84",
        value:
          "84",
      },
      {
        label:
          "83",
        value:
          "83",
      },
      {
        label:
          "82",
        value:
          "82",
      },
      {
        label:
          "81",
        value:
          "81",
      },
      {
        label:
          "80",
        value:
          "80",
      },
      {
        label:
          "79",
        value:
          "79",
      },
      {
        label:
          "78",
        value:
          "78",
      },
      {
        label:
          "77",
        value:
          "77",
      },
      {
        label:
          "76",
        value:
          "76",
      },
      {
        label:
          "75",
        value:
          "75",
      },
      {
        label:
          "74",
        value:
          "74",
      },
      {
        label:
          "73",
        value:
          "73",
      },
      {
        label:
          "72",
        value:
          "72",
      },
      {
        label:
          "71",
        value:
          "71",
      },
      {
        label:
          "70",
        value:
          "70",
      },
      {
        label:
          "69",
        value:
          "69",
      },
      {
        label:
          "68",
        value:
          "68",
      },
      {
        label:
          "67",
        value:
          "67",
      },
      {
        label:
          "66",
        value:
          "66",
      },
      {
        label:
          "65",
        value:
          "65",
      },
      {
        label:
          "64",
        value:
          "64",
      },
      {
        label:
          "63",
        value:
          "63",
      },
      {
        label:
          "62",
        value:
          "62",
      },
      {
        label:
          "61",
        value:
          "61",
      },
      {
        label:
          "60",
        value:
          "60",
      },
      {
        label:
          "59",
        value:
          "59",
      },
      {
        label:
          "58",
        value:
          "58",
      },
      {
        label:
          "57",
        value:
          "57",
      },
      {
        label:
          "56",
        value:
          "56",
      },
      {
        label:
          "55",
        value:
          "55",
      },
      {
        label:
          "54",
        value:
          "54",
      },
      {
        label:
          "53",
        value:
          "53",
      },
      {
        label:
          "52",
        value:
          "52",
      },
      {
        label:
          "51",
        value:
          "51",
      },
      {
        label:
          "50",
        value:
          "50",
      },
      {
        label:
          "49",
        value:
          "49",
      },
      {
        label:
          "48",
        value:
          "48",
      },
      {
        label:
          "47",
        value:
          "47",
      },
      {
        label:
          "46",
        value:
          "46",
      },
      {
        label:
          "45",
        value:
          "45",
      },
      {
        label:
          "44",
        value:
          "44",
      },
      {
        label:
          "43",
        value:
          "43",
      },
      {
        label:
          "42",
        value:
          "42",
      },
      {
        label:
          "41",
        value:
          "41",
      },
      {
        label:
          "40",
        value:
          "40",
      },
      {
        label:
          "39",
        value:
          "39",
      },
      {
        label:
          "38",
        value:
          "38",
      },
      {
        label:
          "37",
        value:
          "37",
      },
      {
        label:
          "36",
        value:
          "36",
      },
      {
        label:
          "35",
        value:
          "35",
      },
      {
        label:
          "34",
        value:
          "34",
      },
      {
        label:
          "33",
        value:
          "33",
      },
      {
        label:
          "32",
        value:
          "32",
      },
      {
        label:
          "31",
        value:
          "31",
      },
      {
        label:
          "30",
        value:
          "30",
      },
      {
        label:
          "29",
        value:
          "29",
      },
      {
        label:
          "28",
        value:
          "28",
      },
      {
        label:
          "27",
        value:
          "27",
      },
      {
        label:
          "26",
        value:
          "26",
      },
      {
        label:
          "25",
        value:
          "25",
      },
      {
        label:
          "24",
        value:
          "24",
      },
      {
        label:
          "23",
        value:
          "23",
      },
      {
        label:
          "22",
        value:
          "22",
      },
      {
        label:
          "21",
        value:
          "21",
      },
      {
        label:
          "20",
        value:
          "20",
      },
      {
        label:
          "19",
        value:
          "19",
      },
      {
        label:
          "18",
        value:
          "18",
      },
      {
        label:
          "17",
        value:
          "17",
      },
      {
        label:
          "16",
        value:
          "16",
      },
      {
        label:
          "15",
        value:
          "15",
      },
      {
        label:
          "14",
        value:
          "14",
      },
      {
        label:
          "13",
        value:
          "13",
      },
      {
        label:
          "12",
        value:
          "12",
      },
      {
        label:
          "11",
        value:
          "11",
      },
      {
        label:
          "10",
        value:
          "10",
      },
      {
        label:
          "9",
        value:
          "9",
      },
      {
        label:
          "8",
        value:
          "8",
      },
      {
        label:
          "7",
        value:
          "7",
      },
      {
        label:
          "6",
        value:
          "6",
      },
      {
        label:
          "5",
        value:
          "5",
      },
      {
        label:
          "4",
        value:
          "4",
      },
      {
        label:
          "3",
        value:
          "3",
      },
      {
        label:
          "2",
        value:
          "2",
      },
      {
        label:
          "1",
        value:
          "1",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ],
    11:[
      {
        label:
        "3",
        value:
        "100",
      },
      {
        label:
          "2",
          value:
          "50",
        },
      {
        label:
          "1",
          value:
          "25",
        },
        {
          label:
          "0",
          value:
          "0",
        },
        {
          label:
            "None",
          value:
            "NULL",
        },
      ],
    12:[
      {
        label:
          "1",
          value:
          "100",
        },
      {
        label:
        "0.5",
        value:
        "50",
      },
      {
        label:
        "0",
        value:
        "0",
      },
      {
        label:
          "None",
        value:
          "NULL",
      },
    ]
  },
  selectedPackage:'',
  defaultViewData:[],
  selectedDefaultView:'',
  FormNewDefaultViewTitle:'',
  saveViewModal:'',
  currentSelectedView:'',
  DepthChartSortingTable:true,
  depthChartDropDownData:[],
  selectedDepthChartDropDownData:'',
  customizeView:false,
  gradingIndexData:[],
  PlayerProductionPointData:[],
  tableView:'table_view',
  DepthChartFieldView:[],
  ToggleDepthFieldView:false,
  UpdateFiewViewPlayer:{},
  UpdatedProductionPointData:{},
  SeasonYearData:[],
  selectedSeasonYear:d.getMonth() >= 1 ? d.getFullYear() : d.getFullYear()-1,
  playByPlayGradingMessage:'',  
  BaseDepthChartsPlayer:[],
  PlayAverages:[],
  ToggleUploadVideo:false,
  FormUploadVideo:{
    uploadVideo:'',
  },
  GetUploadedVideo:'',
  CheckboxError:'',
  PlayUploadMappingError:'',
  gradingPlayerNameError:'',
  userGradingError:'',
  updateBulkEdit:false,
  updateGradesBulkData:{},
  checkAll: false,
  selectedPlayDataArray:[],
  // toggleCustomField:false,
  // customFieldData:[],
  // SelectedCustomFieldDataId:null
  toggleUserGradeLoader:false,
  PlayCheckboxError:'',
  playByPlayLoader:true,
  run:false,
  stepIndex:0,
  dummy_depth_chart_id:null,
  dummy_position_id:null,
  dummy_user_id:null,
  dummy_rank:1,
  dummy_row_id:null,
  productionPoints:[],
  productionPointsData:[],
  productionPointToDelete:false,
  productionPointToDeleteData:[],
  productionPointError:false,
  productionPointErrorMessage:'',
  productionPointErrorId:'',
  productionPointMessage:'',
  productionPointSuccessMessage:'',
  ToggleHudlVideoUpload:false,
  ToggleHudlVideoUploadMessage:false,
  uploadGradeMessage:'',
  uploadGradeError:'',
  schoolReport:[],
  sortingSchoolReport:true,
  uploadPlayError:'',
  togglePackageSelect:false,
  importMapping:{ mapping: null, id: null },
  importPlayLoader: false,
  adminEditUser:{},
  editCoachLoader: false,
  isPlayGradesLoader: false,
}
const reducerMain = (state = initialState, action = {}) => {
  const { type, payload, inputVariables } = action;
  
  const sortAsc = (arr, field) => {
    return arr?.sort((a,b) => (a[field] > b[field]) ? 1 : -1)
  };
  const sortDesc = (arr, field) => {
    return arr?.sort((a,b) => (a[field] > b[field]) ? -1 : 1)
  };

  const sortStringAsc = (arr, field) => {
    return arr?.sort((a,b) => ((String(a[field])=== "null" ? "" : a[field]) > (String(b[field]) === "null" ? "" : b[field])) ? 1 : -1)
  };
  const sortStringDesc = (arr, field) => {
    return arr?.sort((a,b) => ((String(a[field]) === "null" ? "" : a[field]) > (String(b[field]) === "null" ? "" : b[field])) ? -1 : 1)
  };

  const sortDateAsc = (arr, field) => {
    return arr?.sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      return dateA - dateB;
    });
  };
  
  const sortDateDesc = (arr, field) => {
    return arr?.sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      return dateB - dateA;
    });
  };

  const sortAscNumber = (arr, field) => {
    return arr?.sort((a,b) => (Number(a[field]) > Number(b[field])) ? 1 : -1)
  };

  const sortDescNumber = (arr, field) => {
    return arr?.sort((a,b) => (Number(a[field]) > Number(b[field])) ? -1 : 1)
  };

  const innerArrayAsc = (outerArray, intangible_name,position_value) => {
    if(position_value === "" || position_value === "-1" || position_value === null){
      return outerArray.sort(function (a, b) {
        var valueA, valueB , testA ,testB;
        valueA =
          a?.position_averages?.length > 0 ? a?.position_averages?.[
            a?.position_averages?.findIndex((item) => item?.name === intangible_name)
          ]?.integer_value : -1; // Where 1 is your index, from your example
        valueB =
          b?.position_averages?.length > 0 ? b?.position_averages?.[
            b?.position_averages?.findIndex((item) => item?.name === intangible_name)
          ]?.integer_value : -1;
        testA = valueA === undefined ? -1 : valueA;
        testB = valueB === undefined ? -1 : valueB;
        if (Number(testA) < Number(testB)) {
          return 1;
        } else if (Number(testA) > Number(testB)) {
          return -1;
        }
        return 0;
      });
    }else{
      return outerArray.sort(function (a, b) {
        var valueA, valueB , testA ,testB;
        valueA =
          a?.averages?.length > 0 ? a?.averages?.[
            a?.averages?.findIndex((item) => item?.intangible_name === intangible_name)
          ]?.integer_value : -1; // Where 1 is your index, from your example
        valueB =
          b?.averages?.length > 0 ? b?.averages?.[
            b?.averages?.findIndex((item) => item?.intangible_name === intangible_name)
          ]?.integer_value : -1;
          testA = valueA === undefined ? -1 : valueA;
        testB = valueB === undefined ? -1 : valueB;
        if (Number(testA) < Number(testB)) {
          return 1;
        } else if (Number(testA) > Number(testB)) {
          return -1;
        }
        return 0;
      });
    }
  };

  const innerArrayDesc = (outerArray, intangible_name,position_value) => {
    if(position_value === "" || position_value === "-1" || position_value === null){
      return outerArray.sort(function (a, b) {
        var valueA, valueB, testA ,testB;
        valueA =
        a?.position_averages?.length > 0 ? a?.position_averages?.[
          a?.position_averages?.findIndex((item) => item?.name === intangible_name)
        ]?.integer_value : -1; // Where 1 is your index, from your example
        valueB =
        b?.position_averages?.length > 0 ? b?.position_averages?.[
          b?.position_averages?.findIndex((item) => item?.name === intangible_name)
        ]?.integer_value : -1;
        testA = valueA === undefined ? -1 : valueA;
        testB = valueB === undefined ? -1 : valueB;
        if (Number(testA) < Number(testB)) {
          return -1;
        } else if (Number(testA) > Number(testB)) {
          return 1;
        }
        return 0;
      });
    }else{
      return outerArray.sort(function (a, b) {
        var valueA, valueB, testA ,testB;
        valueA =
        a?.averages?.length > 0 ? a?.averages?.[
          a?.averages?.findIndex((item) => item?.intangible_name === intangible_name)
        ]?.integer_value : -1; // Where 1 is your index, from your example
        valueB =
        b?.averages?.length > 0 ? b?.averages?.[
          b?.averages?.findIndex((item) => item?.intangible_name === intangible_name)
        ]?.integer_value : -1;
        testA = valueA === undefined ? -1 : valueA;
        testB = valueB === undefined ? -1 : valueB;
        if (Number(testA) < Number(testB)) {
          return -1;
        } else if (Number(testA) > Number(testB)) {
          return 1;
        }
        return 0;
      });
    }
  };

  const innerArrayCustomAsc = (outerArray, custom_field_name) => {
    return outerArray.sort(function (a, b) {
      var valueA, valueB;
      valueA =
        a?.custom_fields?.length > 0 ? a?.custom_fields?.[
          a?.custom_fields?.findIndex((item) => item?.title === custom_field_name)
        ]?.data : ""; // Where 1 is your index, from your example
      valueB =
        b?.custom_fields?.length > 0 ? b?.custom_fields?.[
          b?.custom_fields?.findIndex((item) => item?.title === custom_field_name)
        ]?.data : "";
      if (valueA < valueB) {
        return 1;
      } else if (valueA > valueB) {
        return -1;
      }
      return 0;
    });
  };

  const innerArrayCustomDesc = (outerArray, custom_field_name) => {
    return outerArray.sort(function (a, b) {
      var valueA, valueB;
      valueA =
      a?.custom_fields?.length > 0 ? a?.custom_fields?.[
        a?.custom_fields?.findIndex((item) => item?.title === custom_field_name)
      ]?.data : ""; // Where 1 is your index, from your example
      valueB =
      b?.custom_fields?.length > 0 ? b?.custom_fields?.[
        b?.custom_fields?.findIndex((item) => item?.title === custom_field_name)
      ]?.data : "";
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  };

const playerInnerArrayAsc = (outerArray, sortingPlayerField) => {
  return outerArray.sort(function (a, b) {
    var valueA, valueB,testA,testB;
      valueA =
        a?.user_grades?.length > 0 ? a?.user_grades[
          a?.user_grades?.findIndex((item) => item?.intangible_name === sortingPlayerField)
        ]?.integer_value : -1; // Where 1 is your index, from your example
      valueB =
        b?.user_grades?.length > 0 ? b?.user_grades[
          b?.user_grades?.findIndex((item) => item?.intangible_name === sortingPlayerField)
        ]?.integer_value : -1;
      testA = valueA === undefined ? -1 : valueA;
      testB = valueB === undefined ? -1 : valueB;
      if (Number(testA) < Number(testB)) {
        return 1;
      } else if (Number(testA) > Number(testB)) {
        return -1;
      }
      return 0;
  });
};

const playerInnerArrayDesc = (outerArray, sortingPlayerField) => {
  return outerArray.sort(function (a, b) {
    var valueA, valueB,testA,testB;
      valueA =
        a?.user_grades?.length > 0 ? a?.user_grades[
          a?.user_grades?.findIndex((item) => item?.intangible_name === sortingPlayerField)
        ]?.integer_value : -1; // Where 1 is your index, from your example
      valueB =
        b?.user_grades?.length > 0 ? b?.user_grades[
          b?.user_grades?.findIndex((item) => item?.intangible_name === sortingPlayerField)
        ]?.integer_value : -1;
        testA = valueA === undefined ? -1 : valueA;
        testB = valueB === undefined ? -1 : valueB;
      if (Number(testA) < Number(testB)) {
        return -1;
      } else if (Number(testA) > Number(testB)) {
        return 1;
      }
      return 0;
  });
};

const playerCustomInnerArrayDesc = (outerArray, sortingPlayerField) => {
  return outerArray.sort(function (a, b) {
    var valueA, valueB;
      valueA =
        a?.custom_fields?.length > 0 ? a?.custom_fields[
          a?.custom_fields?.findIndex((item) => item?.title === sortingPlayerField)
        ]?.data : ""; // Where 1 is your index, from your example
      valueB =
        b?.custom_fields?.length > 0 ? b?.custom_fields[
          b?.custom_fields?.findIndex((item) => item?.title === sortingPlayerField)
        ]?.data : "";
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
  });
};
const playerCustomInnerArrayAsc = (outerArray, sortingPlayerField) => {
  return outerArray.sort(function (a, b) {
    var valueA, valueB;
      valueA =
        a?.custom_fields?.length > 0 ? a?.custom_fields[
          a?.custom_fields?.findIndex((item) => item?.title === sortingPlayerField)
        ]?.data : ""; // Where 1 is your index, from your example
      valueB =
        b?.custom_fields?.length > 0 ? b?.custom_fields[
          b?.custom_fields?.findIndex((item) => item?.title === sortingPlayerField)
        ]?.data : "";
      if (valueA < valueB) {
        return 1;
      } else if (valueA > valueB) {
        return -1;
      }
    return 0;
  });
};

const sortLeaderBoardAscNumber = (arr, field) => {
  return arr?.sort((a, b) => {
    const aValue = Number(a[field]?.ranking?.rank);
    const bValue = Number(b[field]?.ranking?.rank);

    if (aValue === null || isNaN(aValue)) return -1;
    if (bValue === null || isNaN(bValue)) return 1;

    return aValue > bValue ? 1 : -1;
  });
};

const sortLeaderBoardDescNumber = (arr, field) => {
  return arr?.sort((a, b) => {
    const aValue = Number(a[field]?.ranking?.rank);
    const bValue = Number(b[field]?.ranking?.rank);

    if (aValue === null || isNaN(aValue)) return 1;
    if (bValue === null || isNaN(bValue)) return -1;

    return aValue > bValue ? -1 : 1;
  });
};



  const trainingInnerArrayAsc = (outerArray, sortingField) => {
    return outerArray.sort(function (a, b) {
      var valueA, valueB;
      if (a?.averages !== null) {
        valueA =
          a?.averages[
            a?.averages?.findIndex((item) => item?.exercise_name == sortingField)
          ]?.integer_value; // Where 1 is your index, from your example
        valueB =
          b?.averages[
            b?.averages?.findIndex((item) => item?.exercise_name == sortingField)
          ]?.integer_value;
        if (valueA < valueB) {
          return 1;
        } else if (valueA > valueB) {
          return -1;
        }
      }
      return 0;
    });
  };

  const trainingInnerArrayDesc = (outerArray, sortingField) => {

    try {
      return outerArray.sort(function (a, b) {
        var valueA, valueB;
        if (a?.averages !== null) {
          valueA =
            a?.averages[
              a?.averages?.findIndex((item) => item?.exercise_name == sortingField)
            ]?.integer_value; // Where 1 is your index, from your example
          valueB =
            b?.averages[
              b?.averages?.findIndex((item) => item?.exercise_name == sortingField)
            ]?.integer_value;
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          }
        }
        return 0;
      });
    } catch (error) {
      console.log("error", error);
    }

  };


  const depthchartInnerArrayAsc = (outerArray, sortingField) => {
    return outerArray.sort(function (a, b) {
      var valueA, valueB;
      if (a?.positions !== null) {
        valueA =
          a?.positions[
            a?.positions?.findIndex((item) => Number(item?.rank === null ? 0 : item?.rank) === Number(sortingField))
          ]?.name; // Where 1 is your index, from your example
        valueB =
          b?.positions[
            b?.positions?.findIndex((item) => Number(item?.rank === null ? 0 : item?.rank) === Number(sortingField))
          ]?.name;
        if (valueA < valueB) {
          return 1;
        } else if (valueA > valueB) {
          return -1;
        }
      }
      return 0;
    });
  };

  const depthchartInnerArrayDesc = (outerArray, sortingField) => {
    try {
      return outerArray.sort(function (a, b) {
        var valueA, valueB;
        if (a?.positions !== null) {
          valueA =
            a?.positions[
              a?.positions?.findIndex((item) => Number(item?.rank === null ? 0 : item?.rank) === Number(sortingField))
            ]?.name; // Where 1 is your index, from your example
          valueB =
            b?.positions[
              b?.positions?.findIndex((item) => Number(item?.rank === null ? 0 : item?.rank) === Number(sortingField))
            ]?.name;
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          }
        }
        return 0;
      });
    } catch (error) {
      console.log("error", error);
    }

  };

  if (type === actions.set_field) {
    return Object.assign({}, state, {
      [payload.key]: payload.value,
    });
  } else if (type === actions.remove_field) {
    const newState = _.cloneDeep(state);
    if (payload.index !== null && payload.index !== undefined) {
      if (
        _.isArray(newState[payload.key]) &&
        newState[payload.key].length > payload.index
      ) {
        newState[payload.key].splice(payload.index, 1);
      } else {
        console.warn(
          `Could not remove field at ${payload.key} with index ${payload.index}`
        );
      }
    } else {
      const deleted = _.unset(newState, payload.key);
      if (!deleted) {
        console.warn(`Could not remove field at ${payload.key}`);
      }
    }

    return newState;
  } else if (type === actions.change_input) {
    /*let fieldName = payload.name;
        let value = payload.value;

        let storeObj = {};
        _.set(storeObj, fieldName, value);

        try {
            schema.validateSyncAt(fieldName, storeObj)
        } catch(e) {
            console.warn(e)
        }

        return _.merge({}, state, storeObj);*/
    // debugger
    // setTimeout(() => {
    //   var _payload = {
    //     name: payload.name,
    //     value: "",
    //   };

    //   return _.setWith(_.clone(state), _payload.name, _payload.value, _.clone);
    // }, 3000);
    return _.setWith(_.clone(state), payload.name, payload.value, _.clone);
  } else if (type === actions.log_task_started) {
    let status = state.tasks.get(payload);
    if (status) {
      status.stage = "started";
      status.counter += 1;
    } else {
      status = {
        stage: "started",
        counter: 1,
      };
    }
    state = Object.assign({}, state);
    state.tasks.set(payload, status);
    return state;
  } else if (type === actions.log_task_done) {
    let status = state.tasks.get(payload);
    if (status) {
      state = Object.assign({}, state);
      if (status.counter > 1) {
        status.counter -= 1;
        state.tasks.set(payload, status);
      } else {
        state.tasks.delete(payload);
      }
    } else {
      console.warn(
        "Some process tried to call DONE action for " +
        payload +
        " which was not started before."
      );
    }
    return state;
  } else if (
    type === actions.log_task_errored ||
    type === actions.log_task_success
  ) {
    state = Object.assign({}, state);
    state.messages.set(payload.timestamp, {
      text: payload.text,
      type: type,
    });
    return state;
  } else if (type === actions.log_task_close) {
    state = Object.assign({}, state);
    state.messages.delete(payload);
    return state;
  }else if(type === actions.SET_CURRENT_PLAYER_POSITION){
    state = Object.assign({}, state);
    console.log('payload :>> ', payload);
    state.currentSelectedPositionData = payload
    return state
  }else if(type === actions.REMOVE_PRODUCTION_POINT){
    state = Object.assign({}, state);
    state.UpdatedProductionPointData = payload
    return state
  }else if(type === actions.DISABLE_LOADING){
    state = Object.assign({}, state);
    state.isDefaultLoaded = inputVariables?.isDefault
    return state
  }
  else if (type === actions.SORT_PLAYER_TABLE) {
    state = Object.assign({}, state);
    state.Users = Object.assign(
      [],
      inputVariables.inputSorting
        ? sortAsc(state.Users, inputVariables.sortingField)
        : sortDesc(state.Users, inputVariables.sortingField)
    );
    state.SortingSettings = inputVariables.inputSorting;

    return state;
  } else if (type === actions.SORT_ADMIN_REPORT_TABLE) {
    state = Object.assign({}, state);
    if(inputVariables?.sortingType === 'date'){
      state.schoolReport.result = Object.assign(
        [],
        inputVariables.inputSorting
          ? sortDateAsc(state.schoolReport?.result, inputVariables.sortingField)
          : sortDateDesc(state.schoolReport?.result, inputVariables.sortingField)
      );
      state.sortingSchoolReport = inputVariables.inputSorting;
    } else if(inputVariables?.sortingType === 'number'){
      state.schoolReport.result = Object.assign(
        [],
        inputVariables.inputSorting
          ? sortAscNumber(state.schoolReport?.result, inputVariables.sortingField)
          : sortDescNumber(state.schoolReport?.result, inputVariables.sortingField)
      );
      state.sortingSchoolReport = inputVariables.inputSorting;
    } else {
      state.schoolReport.result = Object.assign(
        [],
        inputVariables.inputSorting
          ? sortAsc(state.schoolReport?.result, inputVariables.sortingField)
          : sortDesc(state.schoolReport?.result, inputVariables.sortingField)
      );
      state.sortingSchoolReport = inputVariables.inputSorting;
    }

    return state;
  } else if (type === actions.SORT_BASE_DEPTH_CHART_TABLE) {
    state = Object.assign({}, state);
    state.BaseDepthCharts = Object.assign(
      [],
      inputVariables.inputSorting
        ? sortAsc(state.BaseDepthCharts, inputVariables.sortingField)
        : sortDesc(state.BaseDepthCharts, inputVariables.sortingField)
    );
    state.BaseDepthChartSorting = inputVariables.inputSorting;

    return state;
  } else if (type === actions.SORT_ALL_DEPTH_CHART_TABLE) {
    state = Object.assign({}, state);
    state.GroupedDepthCharts = Object.assign(
      [],
      inputVariables.inputSorting
        ? sortAsc(state.GroupedDepthCharts, inputVariables.sortingField)
        : sortDesc(state.GroupedDepthCharts, inputVariables.sortingField)
    );
    state.AllDepthChartSorting = inputVariables.inputSorting;

    return state;
  } else if (type === actions.SORTINTANGIBLESNAME) {
    state = Object.assign({}, state);
    state.Intangibles = Object.assign(
      [],
      inputVariables.inputIntangibleSorting
        ? sortAsc(state.Intangibles, inputVariables.sortingField)
        : sortDesc(state.Intangibles, inputVariables.sortingField)
    );
    state.SortingIntangibleSettings = inputVariables.inputIntangibleSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_PLAY) {
    state = Object.assign({}, state);
    state.PlayReportsData = Object.assign(
      [],
      inputVariables.inputTogglePlay.ToggleSorting
        ? sortAsc(
          state.PlayReportsData,
          inputVariables.inputTogglePlay.sortingField
        )
        : sortDesc(
          state.PlayReportsData,
          inputVariables.inputTogglePlay.sortingField
        )
    );
    state.PlaySortingTable = inputVariables.inputTogglePlay.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_PLAY_AVG) {
    state = Object.assign({}, state);
    state.PlayReportsData = Object.assign(
      [],
      inputVariables.inputTogglePlay.ToggleSorting
        ? sortAscNumber(
          state.PlayReportsData,
          inputVariables.inputTogglePlay.sortingField
        )
        : sortDescNumber(
          state.PlayReportsData,
          inputVariables.inputTogglePlay.sortingField
        )
    );
    state.PlaySortingTable = inputVariables.inputTogglePlay.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLECOMPARESORTING) {
    state = Object.assign({}, state);
    state.PlayerCompare = Object.assign(
      [],
      inputVariables.inputCompare.ToggleSorting
        ? sortAsc(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
        : sortDesc(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
    );
    state.CompareSortingTable = inputVariables.inputCompare.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLECOMPARESORTINGNUMBER) {
    state = Object.assign({}, state);
    state.PlayerCompare = Object.assign(
      [],
      inputVariables.inputCompare.ToggleSorting
        ? sortAscNumber(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
        : sortDescNumber(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
    );
    state.CompareSortingTable = inputVariables.inputCompare.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLECOMPARESORTINGRANK) {
    state = Object.assign({}, state);
    state.PlayerCompare = Object.assign(
      [],
      inputVariables.inputCompare.ToggleSorting
        ? sortLeaderBoardAscNumber(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
        : sortLeaderBoardDescNumber(
          state.PlayerCompare,
          inputVariables.inputCompare.sortingField
        )
    );
    state.CompareSortingTable = inputVariables.inputCompare.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_TRAINING) {
    state = Object.assign({}, state);
    state.AverageUserExercises = Object.assign(
      [],
      inputVariables.inputTraining.ToggleSorting
        ? sortAsc(
          state.AverageUserExercises,
          inputVariables.inputTraining.sortingField
        )
        : sortDesc(
          state.AverageUserExercises,
          inputVariables.inputTraining.sortingField
        )
    );
    state.TrainingSortingTable = inputVariables.inputTraining.ToggleSorting;
    return state;
  } else if (type === actions.TOGGLESORTINGPLAYER) {
    state = Object.assign({}, state);
    state.PlayerReportsData = Object.assign(
      [],
      inputVariables.inputTogglePlayerName.TogglePlayerSorting
        ? sortAsc(
          state.PlayerReportsData, 
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
        : sortDesc(
          state.PlayerReportsData,
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
    );
    state.PlayerSortingTable =
      inputVariables.inputTogglePlayerName.TogglePlayerSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_PLAYER_AVG) {
    state = Object.assign({}, state);
    state.PlayerReportsData = Object.assign(
      [],
      inputVariables.inputTogglePlayerName.TogglePlayerSorting
        ? sortAscNumber(
          state.PlayerReportsData,
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
        : sortDescNumber(
          state.PlayerReportsData,
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
    );
    state.PlayerSortingTable =
      inputVariables.inputTogglePlayerName.TogglePlayerSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_DEPTHCHART) {
    state = Object.assign({}, state);
    state.DepthChartUsers = Object.assign(
      [],
      inputVariables.inputTogglePlay.ToggleSorting
        ? sortStringAsc(
          state.DepthChartUsers,
          inputVariables.inputTogglePlay.sortingField
        )
        : sortStringDesc(
          state.DepthChartUsers,
          inputVariables.inputTogglePlay.sortingField
        )
    );
    state.DepthChartSortingTable =
      inputVariables.inputTogglePlay.ToggleSorting;
    return state;
  }else if (type === actions.TOGGLE_SORTING_CUSTOM_FIELD_PLAYER) {
    state = Object.assign({}, state);
    state.PlayerReportsData = Object.assign(
      [],
      inputVariables.inputTogglePlayerName.TogglePlayerSorting
        ? playerCustomInnerArrayAsc(
          state.PlayerReportsData,
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
        : playerCustomInnerArrayDesc(
          state.PlayerReportsData,
          inputVariables.inputTogglePlayerName.sortingPlayerField
        )
    );
    state.PlayerSortingTable =
      inputVariables.inputTogglePlayerName.TogglePlayerSorting;
    return state;
  }else if (type === actions.TOGGLE_SORTING_GAME_AND_PRACTICE) {
    state = Object.assign({}, state);
    let { sortingField, ToggleSorting } = inputVariables.inputTogglePlay;
    state.PlayReportsData = Object.assign(
      [],
      ToggleSorting
        ? innerArrayAsc(state?.PlayReportsData, sortingField,state?.SelectedPositionId)
        : innerArrayDesc(state?.PlayReportsData, sortingField,state?.SelectedPositionId)
    );
    state.PlaySortingTable = ToggleSorting;
    return state;
  }else if (type === actions.TOGGLE_SORTING_GAME_AND_PRACTICE_CUSTOM_FIELD) {
    state = Object.assign({}, state);
    let { sortingField, ToggleSorting } = inputVariables.inputTogglePlay;
    state.PlayReportsData = Object.assign(
      [],
      ToggleSorting
        ? innerArrayCustomAsc(state?.PlayReportsData, sortingField)
        : innerArrayCustomDesc(state?.PlayReportsData, sortingField)
    );
    state.PlaySortingTable = ToggleSorting;
    return state;
  }else if (type === actions.TOGGLE_SORTING_PLAYER_INTANGIBLES) {
    state = Object.assign({}, state);
    let { sortingPlayerField, TogglePlayerSorting } =
      inputVariables.inputTogglePlayerName;
    state.PlayerReportsData = Object.assign(
      [],
      TogglePlayerSorting
        ? playerInnerArrayAsc(state.PlayerReportsData, sortingPlayerField)
        : playerInnerArrayDesc(state.PlayerReportsData, sortingPlayerField)
    );
    state.PlayerSortingTable = TogglePlayerSorting;
    return state;
  } else if (type === actions.TOGGLE_SORTING_TRAINING_EXERCISES) {
    state = Object.assign({}, state);
    let { sortingField, ToggleSorting } =
      inputVariables.inputTraining;
    // let tempData = state.Intangibles;
    // let myData = tempData.filter((i) => i.name == sortingPlayerField);
    state.AverageUserExercises = Object.assign(
      [],
      ToggleSorting
        ? trainingInnerArrayAsc(state.AverageUserExercises, sortingField)
        : trainingInnerArrayDesc(state.AverageUserExercises, sortingField)
    );
    state.TrainingSortingTable = ToggleSorting;
    return state;
  }else if (type === actions.TOGGLE_SORTING_DEPTHCHART_PLAYER) {
    state = Object.assign({}, state);
    let { sortingField, ToggleSorting } =
    inputVariables.inputTogglePlay;
    state.DepthChartUsers = Object.assign(
      [],
      inputVariables.inputTogglePlay.ToggleSorting
        ? depthchartInnerArrayAsc(
          state.DepthChartUsers,
          sortingField
        )
        : depthchartInnerArrayDesc(
          state.DepthChartUsers,
          sortingField
        )
    );
    state.DepthChartSortingTable = ToggleSorting;
    return state;
  }else if (type === actions.GET_TRAINING_VALUES) {
    state = Object.assign({}, state);

    state.AverageUserExercises = inputVariables.rosterValue;

    return state;
  }else if (type === actions.GET_USERS_VALUES) {
    state = Object.assign({}, state);

    state.Users = inputVariables.rosterValue;

    return state;
  }else if (type === actions.GET_PLAYER_COMPARE_VALUES) {
    state = Object.assign({}, state);

    state.PlayerCompare = inputVariables.rosterValue;

    return state;
  }else if (type === actions.SET_UPLOAD_VIDEO) {
    state = Object.assign({}, state);

    state.ToggleUploadVideo = !state.ToggleUploadVideo;

    return state;
  }else if (type === actions.SET_UPLOAD_HUDL_VIDEO) {
    state = Object.assign({}, state);

    state.ToggleHudlVideoUpload = !state.ToggleHudlVideoUpload;

    return state;
  } else if (type === actions.REMOVE_UPLOAD_HUDL_VIDEO) {
    state = Object.assign({}, state);

    state.ToggleHudlVideoUploadMessage = !state.ToggleHudlVideoUploadMessage;

    return state;
  } else if (type === actions.SELECTED_CUSTOM_FIELD_DATA) {
    state = Object.assign({}, state);
    state.SelectedCustomFields = inputVariables?.customFieldData ?? {};

    return state;
  }else if (type === actions.REMOVE_CHECKBOX_GRADING_VALUE) {
    state = Object.assign({}, state);

    state.gradingBulkDataClear = false;
    state.CheckboxError = '';
    // state.productionPointErrorMessage='';
    state.productionPointSuccessMessage = '';

    return state;
  }else if (type === actions.PLAY_BY_PLAY_CHECK_ALL) {
    state = Object.assign({}, state);

    let playByPlayDataClone = [...state.PlayByPlayInfo];
    playByPlayDataClone && isArray(playByPlayDataClone) && playByPlayDataClone?.map((val) =>
       val['isChecked'] = !state.checkAll
    )
    let selectedData = playByPlayDataClone && isArray(playByPlayDataClone) && playByPlayDataClone.filter(item => item.isChecked).map(val=>val.id)
    state.PlayByPlayInfo = playByPlayDataClone;
    state.checkAll = !state.checkAll;
    state.selectedPlayDataArray = selectedData;

    // this.setState({playByPlayData:playByPlayDataClone,checkAll:!this.state.checkAll,selectedPlayDataArray:selectedData})

    return state;
  }else if (type === actions.PLAY_BY_PLAY_ON_CHECK_BOX_CHANGE) {
    state = Object.assign({}, state);

    let index= state.PlayByPlayInfo.findIndex(item => item?.id=== inputVariables?.data?.id) 
    state.PlayByPlayInfo[index]['isChecked']  = !inputVariables?.data?.isChecked 
     let isSelectedData = state?.PlayByPlayInfo.filter(item=>item.isChecked).map((item)=>{return item.id})
    
     state.checkAll = isSelectedData?.length === state?.PlayByPlayInfo?.length ? true : false;
     state.selectedPlayDataArray = isSelectedData;
 
    //  this.setState({checkAll:isSelectedData.length===this.state.playByPlayData.length ? true : false,selectedPlayDataArray:isSelectedData})

    return state;
  }else if (type === actions.GET_PLAY_BY_PLAY_GAME_LOADER) {
    state = Object.assign({}, state);

    state.playByPlayLoader = inputVariables.loader;
    return state;
  }else if (type === actions.UPDATE_INTANGIBLE_ARRAY) {
    state = Object.assign({}, state);

    state.IntangibleValueName = inputVariables.newIntArray;
    state.otherTextInput = "";

    return state;
  } else if (type === actions.TOUR_START) {
    state = Object.assign({}, state);

    state.run = inputVariables?.run;
    state.stepIndex = inputVariables?.index ? inputVariables?.index: 0;

    return state;
  } else if (type === actions.TOUR_STOP) {
    state = Object.assign({}, state);

    state.run = false;
    state.stepIndex = 0;

    return state;
  }else if (type === actions.ADD_DEPTH_CHART_TITLE) {
    state = Object.assign({}, state);

    // state["FormCreateDepthChart"]?.title = "Offense"
    state.FormCreateDepthChart.title = "Offense"

    return state;
  }else if (type === actions.REMOVE_ADD_DEPTH_CHART_TITLE) {
    state = Object.assign({}, state);

    // state["FormCreateDepthChart"]?.title = "Offense"
    state.FormCreateDepthChart = {};

    return state;
  } else if (type === actions.SET_DELETE_PRODUCTION_POINT) {
    state = Object.assign({}, state);

    state.productionPointToDelete = !state.productionPointToDelete;
    state.productionPointToDeleteData = inputVariables?.productionData
    state.AddedProductionData = "";

    return state;
  } else if (type === actions.OPTION_CHANGE_PRODUCTION_POINT_REQUEST) {
    state = Object.assign({}, state);

    let value = inputVariables?.value?.map((item)=>({production_point:{name:item?.name}}))
    state.productionPointsData = value;

    return state;
  } else if (type === actions.PRODUCTION_POINT_ERROR_MESSAGE) {
    state = Object.assign({}, state);

    state.productionPointError = !state.productionPointError;

    return state;
  } else if (type === actions.PRODUCTION_POINT_SUCCESS_MESSAGE) {
    state = Object.assign({}, state);

    state.productionPointSuccessMessage = 'Your suggested production point has been sent for approval. Please check back shortly.';

    return state;
  } else if (type === actions.SET_HUDL_VIDEO) {
    state = Object.assign({}, state);

    const value = inputVariables?.["depth chart row"]?.hudlVideoLink
    if(value && typeof(value) === 'string'){
      const jsonArray = JSON.parse(inputVariables?.["depth chart row"]?.hudlVideoLink);
      if(jsonArray && isArray(jsonArray)){
        state.GetUploadedVideo = jsonArray;
      }
    }


    return state;
  } else if (type === actions.TOGGLE_CHOOSE_PACKAGE) {
    state = Object.assign({}, state);

    state.togglePackageSelect = !state.togglePackageSelect;

    return state;
  } 
  else if (type === actions.EDIT_COACH_POSITION) {
    state = Object.assign({}, state);

    if (
      state.storeEditedPosition?.split(",").includes(inputVariables?.position)
    ) {
    } else {
    }
    state.storeEditedPosition = inputVariables?.position;

    return state;
  } else if (type === actions.SET_COACH_POSITION) {
    state = Object.assign({}, state);

    try {
      let shallowPosition = state.storeEditedPosition;

      if (shallowPosition?.split(",").includes(inputVariables?.position)) {
        if (shallowPosition.split(",").indexOf(inputVariables?.position) > 0) {
          shallowPosition = shallowPosition.replace(`,${inputVariables?.position}`, "");
        } else {
          shallowPosition = shallowPosition.replace(`${inputVariables?.position}`, "");
          if (shallowPosition?.charAt(0) === ",") {
            shallowPosition =
              shallowPosition?.substring(1);
          }
        }

      } else {

        if (shallowPosition === undefined || shallowPosition === null) {
          let temp = inputVariables.position;
          shallowPosition = temp
        } else {
          shallowPosition = shallowPosition?.concat(",", inputVariables.position);
        }
      }

      state.storeEditedPosition = shallowPosition;
      return state;
    } catch (error) {

    }

  }else if (type === actions.LOGOUT) {
    state = { ...initialState, run: false, stepIndex: null };
    return state;
  }
  return state;
};

const reducer = combineReducers({
  reducer: reducerMain,
  routing: routerReducer,
});

export default reducer;
