import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import {
  FileUpload,
  FormBlock,
  Label,
  SelectDropDown,
  TextInput,
} from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import TextField from "@material-ui/core/TextField";
import { Image } from "../modules/Media";
import profileImage from "../assets/images/qb_medium.png";
import ImageCrop from "../modules/ImageCrop";
import {
  debounced,
  startPageTimer,
  trackPageDuration,
} from "../utils/function";
import { IMAGES } from "../assets";
import { CircularProgress } from "@material-ui/core";

class RosterPage extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      value: true,
      changeImage: "",
      errorMessage: "Click image above to edit.",
      setCropper: "",
      image: "",
      imageName: "",
      width: 0,
      imageCrop: 300,
      imageURl: "",
      startTime: null,
      searchValue: "",
      loading: true,
      profileZoomLevel: 0.1,
      // message:'',
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    startPageTimer("Roster Page");
    this.setState({ startTime: Date.now() });
    if (
      this.props?.Users?.length === 0 ||
      this.props?.Users?.length === undefined
    ) {
      this.props.getRosterData();
    } else {
      dispatch({
        type: "DISABLE_LOADING",
        inputVariables: { isDefault:false },
      });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.EditUser !== this.props.EditUser) {
      this.setState({
        changeImage:
          process.env.REACT_APP_PROFILE +
          this.props?.EditUser?.profile_image?.medium,
      });
    }
    if (previousProps.SuccessMessage !== this.props.SuccessMessage) {
      this.setState({ value: true });
    }
  }

  componentWillUnmount() {
    trackPageDuration("Roster Page", this.state.startTime);
  }

  handleCropChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ imageName: e.target.files?.[0]?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(files?.[0]);
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
      img.onload = function () {
        that.setState({ width: this.width });
        if (this.width >= 300) {
          if (this.width >= 600) {
            that.setState({ imageCrop: 600 });
          }
          that.setState({
            changeImage: reader.result,
            modal: true,
            errorMessage: "",
          });
        } else {
          that.setState({
            errorMessage: "Image resolution should be more than 300px.",
          });
        }
      };
    };
  };

  handleChangeImage = () => {
    this.setState({ changeImage: profileImage, image: "" });
    const { dispatch } = this.props;
    dispatch({ type: "REMOVE_IMAGE" });
  };

  setProfileLogoCanvasAndCropBoxData = () => {
    const { setCropper } = this.state;
    if (setCropper) {
      const imageData = setCropper.getImageData();
      const containerData = setCropper.getContainerData();

      const cropBoxSize = 300; // Fixed crop box size (300x300)
      let canvasWidth, canvasHeight, canvasLeft, canvasTop;

      // Calculate the aspect ratio of the image
      const imageAspectRatio = imageData.width / imageData.height;

      // Fit the image to the cropping area (300x300) while maintaining aspect ratio
      if (imageAspectRatio > 1) {
        // Landscape image or very wide
        canvasHeight = cropBoxSize; // Set height to match the crop box height
        canvasWidth = canvasHeight * imageAspectRatio; // Calculate width based on aspect ratio

        // If the width exceeds the cropping area, scale down to fit
        if (canvasWidth > cropBoxSize) {
          canvasWidth = cropBoxSize;
          canvasHeight = canvasWidth / imageAspectRatio;
        }
      } else {
        // Portrait image or very tall
        canvasWidth = cropBoxSize; // Set width to match the crop box width
        canvasHeight = canvasWidth / imageAspectRatio; // Calculate height based on aspect ratio

        // If the height exceeds the cropping area, scale down to fit
        if (canvasHeight > cropBoxSize) {
          canvasHeight = cropBoxSize;
          canvasWidth = canvasHeight * imageAspectRatio;
        }
      }

      // Center the image within the cropping area
      canvasLeft = (containerData.width - canvasWidth) / 2;
      canvasTop = (containerData.height - canvasHeight) / 2;

      // Set the fixed crop box dimensions (300x300)
      setCropper.setCropBoxData({
        left: (containerData.width - cropBoxSize) / 2,
        top: (containerData.height - cropBoxSize) / 2,
        width: cropBoxSize,
        height: cropBoxSize,
      });

      // Set the canvas data to center and fit the image within the cropping area
      setCropper.setCanvasData({
        left: canvasLeft,
        top: canvasTop,
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  };

  handleCropperInitialized = (instance) => {
    this.setState({ setCropper: instance }, () => {
      setTimeout(() => {
        if (this.state.setCropper) {
          const imageData = this.state.setCropper.getImageData();
          const cropBoxSize = 300; // Fixed crop box size (300x300)

          // Calculate minimum zoom based on the image's natural dimensions
          const minZoomWidth = cropBoxSize / imageData.naturalWidth;
          const minZoomHeight = cropBoxSize / imageData.naturalHeight;
          const minProfileZoomLevel = Math.min(minZoomWidth, minZoomHeight);

          // Set the initial zoom level and min zoom level in state
          this.setState({
            profileZoomLevel: minProfileZoomLevel,
            minProfileZoomLevel,
          });

          // Apply the initial zoom to the cropper
          this.state.setCropper.zoomTo(minProfileZoomLevel);

          // Set the canvas and crop box data
          this.setProfileLogoCanvasAndCropBoxData();
        }
      }, 100); // Adjust the delay as needed
    });
  };

  handleProfileZoomChange = (event) => {
    let newZoomLevel = parseFloat(event.target.value);

    if (this.state.setCropper) {
      // Clamp the zoom level to the calculated minimum zoom level
      newZoomLevel = Math.max(
        newZoomLevel,
        this.state.minProfileZoomLevel || 0.1
      );

      // Zoom the image
      this.state.setCropper.zoomTo(newZoomLevel);

      // Update the state with the new zoom level
      this.setState({ profileZoomLevel: newZoomLevel });

      // Center the crop box
      const cropBoxData = this.state.setCropper.getCropBoxData();
      const cropBoxCenterX = cropBoxData.left + cropBoxData.width / 2;
      const cropBoxCenterY = cropBoxData.top + cropBoxData.height / 2;

      const newImageData = this.state.setCropper.getImageData();
      const newCropBoxLeft = cropBoxCenterX - cropBoxData.width / 2;
      const newCropBoxTop = cropBoxCenterY - cropBoxData.height / 2;

      this.state.setCropper.setCropBoxData({
        left: newCropBoxLeft,
        top: newCropBoxTop,
        width: cropBoxData.width,
        height: cropBoxData.height,
      });
    }
  };

  getCropData = () => {
    if (this.state.setCropper !== "undefined") {
      const cropper = this.state.setCropper;
      const croppedCanvas = cropper.getCroppedCanvas({
        width: this.state.imageCrop,
        height: this.state.imageCrop,
      });

      let dataURL;

      if (
        this.state.imageName.toLowerCase().endsWith(".jpeg") ||
        this.state.imageName.toLowerCase().endsWith(".jpg")
      ) {
        // Create a new canvas for JPEG images
        const newCanvas = document.createElement("canvas");
        newCanvas.width = croppedCanvas.width;
        newCanvas.height = croppedCanvas.height;

        // Get the context of the new canvas
        const ctx = newCanvas.getContext("2d");

        // Set the background color to white
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the cropped image onto the new canvas
        ctx.drawImage(croppedCanvas, 0, 0);

        // Get the data URL of the new canvas
        dataURL = newCanvas.toDataURL("image/jpeg");
      } else {
        // Get the data URL of the original cropped canvas for non-JPEG images
        dataURL = croppedCanvas.toDataURL();
      }

      // Set the state with the new image data
      this.setState({
        image: dataURL,
        modal: false,
      });

      const imageData = this.dataURLtoFile(dataURL, this.state.imageName);
      this.setState({
        image: imageData,
        changeImage: URL.createObjectURL(imageData),
      });
    }
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(","),
      mime = arr?.[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleChange = (e) => {
    const { dispatch } = this.props;
    const { value } = e.target;
    this.setState({ searchValue: value, loading: true });
    debounced(() => {
      fetch(`${process.env.REACT_APP_BASE_URL}/roster?text_search=${value}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) =>
          response.json().then((text) => {
            dispatch({
              type: "GET_USERS_VALUES",
              inputVariables: { rosterValue: text.data },
            });
            this.setState({ loading: false });
          })
        )
        .catch((err) => {
          this.setState({ loading: false });
          console.log("eer", err);
        });
    }, 500);
  };

  render() {
    let {
      CurrentDepthChart,
      CurrentUser,
      DropdownRosterFilter,
      EditUser,
      FormEditUser,
      FormEditUserErrors,
      FormNewUser,
      FormNewUserErrors,
      NavBarDropDownIndex,
      NewUser,
      Positions,
      RosterFilter,
      SortingSettings,
      SuccessMessage,
      UserToDelete,
      Users,
      rostermapping,
      viewUser,
      ViewPlayer,
      ResendEmailAll,
      RosterMappingError,
      isDefaultLoaded,
    } = this.props;
    const { CreateSecondaryPositions, FilterList } = window;

    const handleFilters = () => {
      if (Positions && isArray(Positions)) {
        let Allposition = [...Positions];
        Allposition.unshift({ abbreviation: "All", id: -1 });
        return Allposition.map((data) => ({
          ...data,
          abbreviation: `${data["abbreviation"]}`,
        }));
      }
    };

    let blankArr = Positions?.filter((item) =>
      EditUser?.secondary?.includes(item?.name)
    );
    blankArr = blankArr?.map((item) => item?.id);

    const CustomCheckBox = () => {
      return (
        <DivBlock>
          <input
            type="checkbox"
            checked={this.state.value}
            defaultChecked={this.state.value}
            onChange={(event) => {
              this.setState({ value: event.target.checked });
            }}
          />

          <Label>Send Welcome email to students</Label>
        </DivBlock>
      );
    };

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Get Current User",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Get Positions",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {},
            },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 5 : 99,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                className="main_section"
                style={{
                  backgroundColor: "#f8f8fb",
                  borderBottomColor: "#000000",
                  borderBottomStyle: "none",
                  borderBottomWidth: "",
                  borderLeftColor: "#000000",
                  borderLeftStyle: "none",
                  borderLeftWidth: "",
                  borderRightColor: "#000000",
                  borderRightStyle: "none",
                  borderRightWidth: "",
                  borderStyle: "none",
                  borderTopColor: "#000000",
                  borderTopStyle: "none",
                  borderTopWidth: "",
                  flex: "10 auto",
                  height: "100%",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock className={"SuccessMessage"}>
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <DivBlock style={{}}>
                        <DivBlock
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            fontFamily: "'Poppins', sans-serif",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} type={"h2"}>
                            {CurrentUser?.["school_name"]}
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}></Span>
                        </DivBlock>
                      </DivBlock>

                      <Columns>
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          xl={9}
                          className="roster-filter-wrap"
                        >
                          <DivBlock style={{ display: "flex" }}>
                            <DivBlock
                              className="filterbox"
                              style={{ marginTop: "10px" }}
                            >
                              <SelectDropDown
                                className={"form-control"}
                                dataSet={handleFilters()}
                                dataSetLabel={"abbreviation"}
                                dataSetValue={"id"}
                                enableReinitialize={true}
                                initialValue={
                                  handleFilters() && handleFilters().length > 0
                                    ? handleFilters()[0].Id
                                    : -1
                                }
                                name={"filterPositions"}
                                options={[]}
                                type={"number"}
                                style={{
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  fontSize: "14px",
                                  width: "80px",
                                }}
                                actions={[
                                  {
                                    action: "Set Roster Filter",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    // inputVariables: {
                                    //   "input rosterfilter":
                                    //     "abbreviation",
                                    // },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className="playerbox"
                              style={{ marginLeft: "10px" }}
                            >
                              <Button
                                className={"btn btn-primary"}
                                style={{
                                  backgroundColor: "#48c46e",
                                  borderColor: "#48c46e",
                                }}
                                actions={[
                                  {
                                    action: "Set New User",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input new user": true,
                                      "input open roster filter":
                                        DropdownRosterFilter !== true,
                                    },
                                  },
                                ]}
                              >
                                Add Player
                              </Button>
                            </DivBlock>
                          </DivBlock>
                          {CurrentUser["user_type"] === "Coach" ? (
                            <DivBlock className="resendinvitebox">
                              <Button
                                className={"btn btn-primary"}
                                style={{}}
                                actions={[
                                  {
                                    action: "Set Email All Player",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "resend email": true,
                                    },
                                  },
                                ]}
                              >
                                Resend Invite Email To All
                              </Button>
                            </DivBlock>
                          ) : null}
                          <DivBlock className="roster-input">
                            <TextField
                              id="outlined-search"
                              type="search"
                              size="small"
                              variant="outlined"
                              placeholder="Search Roster"
                              autoComplete="off"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.searchValue}
                              sx={{ marginLeft: "0px" }}
                              fullWidth
                              InputProps={{
                                style: {
                                  height: "35px",
                                  width: "250px",
                                },
                                startAdornment: this.state.searchValue ? (
                                  this.state.loading ? (
                                    <CircularProgress
                                      size={20}
                                      thickness={3}
                                      style={{
                                        display: "flex",
                                        marginRight: "5px",
                                        color: "#48c46e",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={IMAGES.SearchIcon}
                                      alt="icon"
                                      height={"20px"}
                                      width={"20px"}
                                      style={{ marginRight: "5px" }}
                                    />
                                  )
                                ) : (
                                  <img
                                    src={IMAGES.SearchIcon}
                                    alt="icon"
                                    height={"20px"}
                                    width={"20px"}
                                    style={{ marginRight: "5px" }}
                                  />
                                ),
                              }}
                            />
                          </DivBlock>
                        </Column>

                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          xl={3}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "1rem",
                          }}
                          xs={12}
                          className="RosterUploadFile"
                        >
                          <FileUpload
                            FileName={"Filename"}
                            name={"RosterUpload"}
                            style={{ fontFamily: "'Poppins', sans-serif" }}
                          ></FileUpload>

                          <Button
                            className={"btn btn-primary joyride-upload-roster"}
                            actions={[
                              {
                                action: "Upload Roster",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input open roster filter":
                                    DropdownRosterFilter !== true,
                                },
                              },
                            ]}
                          >
                            Upload
                          </Button>
                        </Column>
                      </Columns>

                      <DivBlock className={"SuccessDiv"} style={{}}>
                        <Span className={"success-span"}>{SuccessMessage}</Span>
                      </DivBlock>
                      {isDefaultLoaded ? null : (
                        <>
                          <DivBlock className="table-responsive table-responsive-sm">
                            {Users && isArray(Users) && Users.length > 0 ? (
                              <table className={"table"} style={{}}>
                                <thead>
                                  <tr className={"thead-bg"}>
                                    <th className={"th-border"}></th>
                                    <th className={"th-border"}>
                                      <Span
                                        className={"Sorting-span th-span"}
                                        style={{}}
                                        actions={[
                                          {
                                            action: "Sort Player Table",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              inputSorting:
                                                SortingSettings !== true,
                                              sortingField: "name",
                                            },
                                          },
                                        ]}
                                      >
                                        Name
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span
                                        className={"Sorting-span th-span"}
                                        style={{}}
                                        actions={[
                                          {
                                            action: "Sort Player Table",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              sortingField: "position",
                                              inputSorting:
                                                SortingSettings !== true,
                                            },
                                          },
                                        ]}
                                      >
                                        Position
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span
                                        className={"Sorting-span th-span"}
                                        actions={[
                                          {
                                            action: "Sort Player Table",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              inputSorting:
                                                SortingSettings !== true,
                                              sortingField: "secondary",
                                            },
                                          },
                                        ]}
                                      >
                                        Secondary
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span
                                        className={"Sorting-span th-span"}
                                        style={{}}
                                        actions={[
                                          {
                                            action: "Sort Player Table",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              inputSorting:
                                                SortingSettings !== true,
                                              sortingField: "graduation_year",
                                            },
                                          },
                                        ]}
                                      >
                                        Graduation Year
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span
                                        className={"Sorting-span th-span"}
                                        style={{}}
                                        actions={[
                                          {
                                            action: "Sort Player Table",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              inputSorting:
                                                SortingSettings !== true,
                                              sortingField: "number",
                                            },
                                          },
                                        ]}
                                      >
                                        Number
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span className={"th-span"} style={{}}>
                                        Action
                                      </Span>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody
                                  style={{
                                    overflow: "visible",
                                    position: "static",
                                    width: "100%",
                                  }}
                                >
                                  {(RosterFilter
                                    ? FilterList({
                                        searchData: Users,
                                        query: RosterFilter,
                                        property: "position",
                                      })
                                    : Users) &&
                                    isArray(
                                      RosterFilter
                                        ? FilterList({
                                            searchData: Users,
                                            query: RosterFilter,
                                            property: "position",
                                          })
                                        : Users
                                    ) &&
                                    (RosterFilter
                                      ? FilterList({
                                          searchData: Users,
                                          query: RosterFilter,
                                          property: "position",
                                        })
                                      : Users
                                    ).map((row, index) => {
                                      const RedirectId = `${row["name"]
                                        .toLowerCase()
                                        .replace(/\s+/g, "-")}-${row["Id"]}`;
                                      return (
                                        <Fragment key={index}>
                                          <tr
                                            className={
                                              row % 2 === 0 ? "grey-bg" : ""
                                            }
                                            style={{
                                              paddingBottom: "0px",
                                              paddingTop: "0px",
                                            }}
                                          >
                                            <td
                                              className="common-table-data"
                                              style={{
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Image
                                                src={
                                                  process.env
                                                    .REACT_APP_PROFILE +
                                                  row?.profile_image?.thumbnail
                                                }
                                                style={{
                                                  height: "35px",
                                                  width: "35px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            </td>
                                            <td className="common-table-data">
                                              <LinkBlock
                                                href={RedirectId ? `/player/${RedirectId}` : '/play_grades'}
                                                target={"_blank"}
                                                type={"spa"}
                                                className={"a a-link"}
                                                style={{ color: "#48c46e" }}
                                              >
                                                <Span
                                                  style={{
                                                    fontFamily:
                                                      "'Poppins', sans-serif",
                                                  }}
                                                >
                                                  {row["name"]}
                                                </Span>
                                              </LinkBlock>
                                            </td>

                                            <td className="common-table-data">
                                              <Span
                                                className={"table-span"}
                                                style={{}}
                                              >
                                                {row["position"]
                                                  ? row["position"]
                                                  : "None"}
                                              </Span>
                                            </td>

                                            <td className="common-table-data">
                                              <Span className={"table-span"}>
                                                {row["secondary"]
                                                  ? row["secondary"]
                                                  : "None"}
                                              </Span>
                                            </td>

                                            <td className="common-table-data">
                                              <Span className={"table-span"}>
                                                {row["graduation_year"]
                                                  ? row["graduation_year"]
                                                  : "None"}
                                              </Span>
                                            </td>

                                            <td className="common-table-data">
                                              <Span className={"table-span"}>
                                                {row["number"]
                                                  ? row["number"]
                                                  : "None"}
                                              </Span>
                                            </td>

                                            <td className="common-table-data">
                                              <DivBlock
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Span
                                                  className={"btn table-span"}
                                                  actions={[
                                                    {
                                                      action: "Set Edit User",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        "Edit User": row,
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  Edit
                                                </Span>

                                                <Span
                                                  className={"btn table-span"}
                                                  actions={[
                                                    {
                                                      action: "Set Delete User",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        // "input delete user": row["Id"],
                                                        "user idd": row["Id"],
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  Delete
                                                </Span>
                                                {CurrentUser["user_type"] ===
                                                "Coach" ? (
                                                  <Span
                                                    className={"btn table-span"}
                                                    actions={[
                                                      {
                                                        action: "Resend Email",
                                                        trigger: "onclick",
                                                        timeout: "",
                                                        pollingPeriod: "",
                                                        inputVariables: {
                                                          // "input delete user": row["Id"],
                                                          "player id":
                                                            row["Id"],
                                                        },
                                                      },
                                                    ]}
                                                  >
                                                    Resend Email
                                                  </Span>
                                                ) : null}
                                              </DivBlock>
                                            </td>
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                                </tbody>
                              </table>
                            ) : (
                              <Span
                                className={"table-span"}
                                style={{ fontSize: "20px" }}
                              >
                                No data found
                              </Span>
                            )}
                          </DivBlock>
                        </>
                      )}
                    </DivBlock>
                  </DivBlock>

                  <If condition={EditUser ? true : false} show={false}>
                    <Then>
                      <DivBlock className={"modal"} style={{}}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Edit Player
                                </Heading>
                                <Span
                                  className={"close"}
                                  actions={[
                                    {
                                      action: "Set Edit User",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "Edit User": null,
                                        "input position": null,
                                      },
                                    },
                                    {
                                      action: "Remove Errors",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Full Name</Label>

                                    <TextInput
                                      className={"form-control"}
                                      enableReinitialize={true}
                                      initialValue={
                                        EditUser ? EditUser["name"] : ""
                                      }
                                      name={"FormEditUser.name"}
                                      placeholder={""}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["name"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["name"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Email</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser ? EditUser["email"] : ""
                                      }
                                      name={"FormEditUser.email"}
                                      placeholder={""}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["email"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["email"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Number</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["number"]
                                          ? EditUser["number"]
                                          : 0
                                      }
                                      name={"FormEditUser.number"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["number"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["number"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Grade</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["grade"]
                                          ? EditUser["grade"]
                                          : 0
                                      }
                                      name={"FormEditUser.grade"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["grade"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["grade"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Primary Position
                                    </Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      dataSet={Positions}
                                      dataSetLabel={"name"}
                                      dataSetValue={"id"}
                                      initialValue={
                                        EditUser && EditUser["position_id"]
                                          ? EditUser["position_id"]
                                          : Positions && Positions.length > 0
                                          ? Positions[0].id
                                          : 0
                                      }
                                      name={"FormEditUser.position id"}
                                      options={[]}
                                      type={"number"}
                                    ></SelectDropDown>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["position id"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["position id"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Secondary Position
                                    </Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      dataSet={"Positions"}
                                      dataSetLabel={"name"}
                                      dataSetValue={"id"}
                                      initialValue={blankArr}
                                      multiple={true}
                                      name={"FormEditUser.secondary_positions"}
                                      options={[]}
                                      type={"number"}
                                    ></SelectDropDown>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Graduation Year
                                    </Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["graduation_year"]
                                          ? EditUser["graduation_year"]
                                          : 0
                                      }
                                      name={"FormEditUser.graduation year"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["graduation year"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors[
                                                "graduation year"
                                              ]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Height (Inches)
                                    </Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["height"]
                                          ? EditUser["height"]
                                          : 0
                                      }
                                      name={"FormEditUser.height"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["height"]
                                          ? true
                                          : false
                                      }
                                      show={false}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["height"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Weight</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["weight"]
                                          ? EditUser["weight"]
                                          : 0
                                      }
                                      name={"FormEditUser.weight"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["weight"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["weight"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Status</Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      initialValue={
                                        EditUser && EditUser["active"]
                                          ? "true"
                                          : "false"
                                      }
                                      name={"FormEditUser.active"}
                                      options={[
                                        { label: "Active", value: "true" },
                                        { label: "Inactive", value: "false" },
                                      ]}
                                      type={"boolean"}
                                    ></SelectDropDown>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["active"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["active"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Notes</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={
                                        EditUser ? EditUser["notes"] : ""
                                      }
                                      name={"FormEditUser.notes"}
                                      placeholder={""}
                                      type={"plain"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormEditUserErrors &&
                                        FormEditUserErrors["notes"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormEditUserErrors
                                            ? FormEditUserErrors["notes"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Profile Image
                                    </Label>
                                    <DivBlock className="profile-image-roster-edit">
                                      <DivBlock
                                        onClick={() =>
                                          this.imageRef.current.click()
                                        }
                                      >
                                        <img
                                          src={this.state.changeImage}
                                          alt={"img"}
                                          style={{
                                            height: "150px",
                                            width: "150px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </DivBlock>
                                      <button
                                        className={"btn btn-primary"}
                                        style={{ marginLeft: "30px" }}
                                        onClick={this.handleChangeImage}
                                      >
                                        Remove
                                      </button>
                                    </DivBlock>
                                    <Span className={"invalid-feedback"}>
                                      {this.state.errorMessage}
                                    </Span>
                                  </DivBlock>
                                  <DivBlock className="remove-input">
                                    <input
                                      type="file"
                                      ref={this.imageRef}
                                      onChange={(e) => this.handleCropChange(e)}
                                      key={this.state.changeImage || ""}
                                      accept=".jpg, .jpeg, .png"
                                    />
                                  </DivBlock>
                                  <Button
                                    className={"btn btn-primary"}
                                    actions={[
                                      {
                                        action: "Update User",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          "secondary positions": FormEditUser
                                            ? CreateSecondaryPositions(
                                                FormEditUser.secondary_positions
                                              )
                                            : [],
                                          image: this.state.image,
                                        },
                                      },
                                    ]}
                                  >
                                    Submit
                                  </Button>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If condition={this.state.modal ? true : false} show={false}>
                    <Then>
                      <DivBlock className={"modal"}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Crop Image Before Upload
                                </Heading>

                                <span
                                  className={"close"}
                                  onClick={() =>
                                    this.setState({
                                      modal: false,
                                      changeImage: this.props?.EditUser
                                        ?.profile_image?.medium
                                        ? process.env.REACT_APP_PROFILE +
                                          this.props?.EditUser?.profile_image
                                            ?.medium
                                        : null,
                                      image: "",
                                    })
                                  }
                                >
                                  x
                                </span>
                              </DivBlock>
                              <DivBlock
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  overflow: "auto",
                                }}
                              >
                                <DivBlock style={{ width: "70%" }}>
                                  <ImageCrop
                                    initialAspectRatio={1 / 1}
                                    // aspectRatio={1}
                                    preview=".img-preview"
                                    guides={true}
                                    src={this.state.changeImage}
                                    onInitialized={
                                      this.handleCropperInitialized
                                    }
                                    viewMode={0}
                                    dragMode="move"
                                    cropBoxMovable={false}
                                    checkOrientation={false}
                                    toggleDragModeOnDblclick={false}
                                    modal
                                    background={false}
                                    minCropBoxHeight={100}
                                    minCropBoxWidth={100}
                                    zoomOnWheel={false}
                                  />
                                </DivBlock>
                                <DivBlock
                                  style={{ width: "30%", marginLeft: "10px" }}
                                >
                                  <DivBlock
                                    className="img-preview"
                                    style={{ width: "100%", height: "300px" }}
                                  />
                                </DivBlock>
                              </DivBlock>
                              {/* <DivBlock style={{ marginLeft: "10px" }}>
                                <Span className={"invalid-feedback"}>
                                  Note : Zoom in or zoom out for cropping the
                                  image.
                                </Span>
                              </DivBlock> */}
                              <DivBlock
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <input
                                  type="range"
                                  value={this.state.profileZoomLevel}
                                  onChange={this.handleProfileZoomChange}
                                  min={this.state.minProfileZoomLevel || 0.1} // Set the minimum dynamically
                                  max="3"
                                  step="0.1"
                                />
                                <Span
                                  className={"invalid-feedback"}
                                  style={{ color: "black" }}
                                >
                                  Adjust the zoom level using the slider.
                                </Span>
                              </DivBlock>
                              <DivBlock
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: "20px",
                                }}
                              >
                                <button
                                  className={"btn btn-reset DeleteBtn"}
                                  onClick={() =>
                                    this.setState({
                                      modal: false,
                                      changeImage: this.props?.EditUser
                                        ?.profile_image?.medium
                                        ? process.env.REACT_APP_PROFILE +
                                          this.props?.EditUser?.profile_image
                                            ?.medium
                                        : null,
                                      image: "",
                                    })
                                  }
                                >
                                  Cancel
                                </button>

                                <button
                                  className={"btn btn-primary"}
                                  onClick={this.getCropData}
                                >
                                  Crop
                                </button>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If condition={NewUser ? true : false} show={true}>
                    <Then>
                      <DivBlock
                        className={"modal"}
                        style={{}}
                        actions={[
                          {
                            action: "Set New Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input new position": false },
                          },
                          {
                            action: "Edit Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input position": null },
                          },
                        ]}
                      >
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Add New Player
                                </Heading>

                                <Span
                                  className={"close"}
                                  actions={[
                                    {
                                      action: "Set New User",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "input new user": false,
                                      },
                                    },
                                    {
                                      action: "Remove Errors",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Full Name</Label>

                                    <TextInput
                                      className={"form-control"}
                                      enableReinitialize={true}
                                      name={"FormNewUser.name"}
                                      placeholder={""}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["name"]
                                          ? true
                                          : false
                                      }
                                      show={false}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["name"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Email</Label>

                                    <TextInput
                                      className={"form-control"}
                                      name={"FormNewUser.email"}
                                      placeholder={""}
                                      actions={[
                                        {
                                          action: "Remove Errors",
                                          trigger: "onfocus",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["email"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["email"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Number</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={0}
                                      name={"FormNewUser.number"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["number"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["number"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Grade</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={0}
                                      name={"FormNewUser.grade"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["grade"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["grade"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Primary Position
                                    </Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      dataSet={Positions}
                                      dataSetLabel={"name"}
                                      dataSetValue={"id"}
                                      enableReinitialize={true}
                                      initialValue={
                                        Positions && Positions.length > 0
                                          ? Positions[0].id
                                          : 0
                                      }
                                      name={"FormNewUser.position id"}
                                      options={[]}
                                      type={"number"}
                                    ></SelectDropDown>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["position id"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["position id"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Secondary Position
                                    </Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      dataSet={"Positions"}
                                      dataSetLabel={"name"}
                                      dataSetValue={"id"}
                                      initialValue={[]}
                                      multiple={true}
                                      name={"FormNewUser.secondary_positions"}
                                      options={[]}
                                      type={"number"}
                                    ></SelectDropDown>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Graduation Year
                                    </Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={0}
                                      name={"FormNewUser.graduation year"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["graduation year"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors[
                                                "graduation year"
                                              ]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      Height (Inches)
                                    </Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={0}
                                      name={"FormNewUser.height"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["height"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["height"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Weight</Label>

                                    <TextInput
                                      className={"form-control"}
                                      initialValue={0}
                                      name={"FormNewUser.weight"}
                                      placeholder={""}
                                      type={"number"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["weight"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["weight"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Status</Label>

                                    <SelectDropDown
                                      className={"form-control"}
                                      initialValue={"true"}
                                      name={"FormNewUser.active"}
                                      options={[
                                        { label: "Active", value: "true" },
                                        { label: "Inactive", value: "false" },
                                      ]}
                                      type={"boolean"}
                                    ></SelectDropDown>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["active"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["active"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>Notes</Label>

                                    <TextInput
                                      className={"form-control"}
                                      name={"FormNewUser.notes"}
                                      placeholder={""}
                                      type={"plain"}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormNewUserErrors &&
                                        FormNewUserErrors["notes"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormNewUserErrors
                                            ? FormNewUserErrors["notes"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>

                                  <Button
                                    className={"btn btn-primary"}
                                    actions={[
                                      {
                                        action: "New User",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          "secondary positions one":
                                            CreateSecondaryPositions(
                                              FormNewUser
                                                ? FormNewUser.secondary_positions
                                                : []
                                            ),
                                        },
                                      },
                                    ]}
                                  >
                                    Submit
                                  </Button>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If
                    condition={rostermapping && rostermapping.id ? true : false}
                    show={false}
                  >
                    <Then>
                      <DivBlock
                        className={"modal"}
                        style={{}}
                        actions={[
                          {
                            action: "Set New Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input new position": false },
                          },
                          {
                            action: "Edit Position",
                            trigger: "onclick",
                            timeout: "",
                            pollingPeriod: "",
                            inputVariables: { "input position": null },
                          },
                        ]}
                      >
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Roster Mapping
                                </Heading>

                                <Span
                                  className={"close"}
                                  actions={[
                                    {
                                      action: "Hide Roster Mapping",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "Edit User": null,
                                        "input new user": false,
                                        "input position": null,
                                        null: null,
                                      },
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                  actions={[
                                    {
                                      action: "Create User",
                                      trigger: "onsubmit",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  <DivBlock className={"form-group"}>
                                    <table
                                      style={{
                                        borderCollapse: "collapse",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <thead>
                                        <tr>
                                          <th className={"th-border"}>
                                            <Span className={"th-span"}>
                                              CoachTools Fields
                                            </Span>
                                          </th>

                                          <th className={"th-border"}>
                                            <Span className={"th-span"}>
                                              Uploaded File Fields
                                            </Span>
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Number
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.number"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Full Name
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.fullname"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Primary Position
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.position_id"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Secondary Position
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.secondary"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Graduation Year
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              name={
                                                "CsvMapping.graduation_year"
                                              }
                                              initialValue={"99"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Height
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.height"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Weight
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              initialValue={"99"}
                                              name={"CsvMapping.weight"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <Span className={"table-span"}>
                                              Email
                                            </Span>
                                          </td>

                                          <td
                                            style={{
                                              borderBottomColor: "#ddd",
                                              borderBottomStyle: "solid",
                                              borderBottomWidth: "1px",
                                              borderLeftColor: "#ddd",
                                              borderLeftStyle: "solid",
                                              borderLeftWidth: "1px",
                                              borderRightColor: "#ddd",
                                              borderRightStyle: "solid",
                                              borderRightWidth: "1px",
                                              borderTopColor: "#ddd",
                                              borderTopStyle: "solid",
                                              borderTopWidth: "1px",
                                              paddingBottom: "0.375rem",
                                              paddingLeft: "0.75rem",
                                              paddingRight: "0.75rem",
                                              paddingTop: "0.375rem",
                                            }}
                                          >
                                            <SelectDropDown
                                              dataSet={
                                                rostermapping
                                                  ? rostermapping["mapping"]
                                                  : []
                                              }
                                              dataSetLabel={"name"}
                                              dataSetValue={"index"}
                                              name={"CsvMapping.email"}
                                              initialValue={"99"}
                                              // options={[
                                              //   { label: "None", value: "0" },
                                              // ]}
                                              style={{
                                                background: "#fff",
                                                border: "1px solid #ced4da",
                                                borderRadius: "0.25rem",
                                                color: "#495057",
                                                display: "block",
                                                fontSize: "1rem",
                                                fontWeight: 400,
                                                lineHeight: 1.5,
                                                paddingBottom: "0.375rem",
                                                paddingLeft: "0.75rem",
                                                paddingRight: "0.75rem",
                                                paddingTop: "0.375rem",
                                                width: "100%",
                                              }}
                                              type={"number"}
                                            ></SelectDropDown>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </DivBlock>

                                  <CustomCheckBox />
                                  <If
                                    condition={
                                      RosterMappingError ? true : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {RosterMappingError
                                          ? RosterMappingError
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>

                                  <Button
                                    className={"btn btn-primary"}
                                    actions={[
                                      {
                                        action: "Map Csv",
                                        trigger: "onclick",
                                        timeout: "",
                                        pollingPeriod: "",
                                        inputVariables: {
                                          check: this.state.value,
                                          "secondary positions one":
                                            CreateSecondaryPositions(
                                              FormNewUser
                                                ? FormNewUser.secondary_positions
                                                : []
                                            ),
                                        },
                                      },
                                    ]}
                                  >
                                    Submit
                                  </Button>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If condition={UserToDelete ? true : false} show={false}>
                    <Then>
                      <DivBlock className={"modal"}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Delete Player
                                </Heading>

                                <Span
                                  className={"close"}
                                  actions={[
                                    {
                                      action: "Set Delete User",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "user idd": null,
                                      },
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <DivBlock>
                                    <Label className={"label"}>
                                      Are you sure you want to delete this
                                      player?
                                    </Label>
                                  </DivBlock>

                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      className={"btn btn-reset DeleteBtn"}
                                      actions={[
                                        {
                                          action: "Set Delete User",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "user idd": null,
                                          },
                                        },
                                      ]}
                                    >
                                      Cancel
                                    </Button>

                                    <Button
                                      className={"btn btn-primary"}
                                      actions={[
                                        {
                                          action: "Delete User",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "depth chart id to delete":
                                              CurrentDepthChart
                                                ? CurrentDepthChart["Id"]
                                                : "",
                                            "input delete user": UserToDelete,
                                          },
                                        },
                                      ]}
                                    >
                                      Yes
                                    </Button>
                                  </DivBlock>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If condition={ResendEmailAll ? true : false} show={false}>
                    <Then>
                      <DivBlock className={"modal"}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Resend Invite Email
                                </Heading>

                                <Span
                                  className={"close"}
                                  actions={[
                                    {
                                      action: "Set Email All Player",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        "resend email": false,
                                      },
                                    },
                                  ]}
                                >
                                  x
                                </Span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <DivBlock>
                                    <Label className={"label"}>
                                      This will send welcome emails to all the
                                      players. Are you sure you want to proceed?
                                    </Label>
                                  </DivBlock>

                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button
                                      className={"btn btn-reset DeleteBtn"}
                                      actions={[
                                        {
                                          action: "Set Email All Player",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {
                                            "resend email": false,
                                          },
                                        },
                                      ]}
                                    >
                                      Cancel
                                    </Button>

                                    <Button
                                      className={"btn btn-primary"}
                                      actions={[
                                        {
                                          action: "RESEND_EMAIL_ALL_PLAYER",
                                          trigger: "onclick",
                                          timeout: "",
                                          pollingPeriod: "",
                                          inputVariables: {},
                                        },
                                      ]}
                                    >
                                      Yes
                                    </Button>
                                  </DivBlock>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRosterData: () => {
      dispatch({
        type: "GET_USERS",
        inputVariables: {
          isDefault: true,
        },
      });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RosterPage);
