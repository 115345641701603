import React, { Fragment } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { joyrideSteps } from "../../utils/constant";
import {
  GetAllGamesEndpoint,
  GetFirstDepthChartEndpoint,
  GetPlayByPlayInfoEndpoint,
} from "../../api/api";

const JoyrideComponent = ({ dispatch, ...props }) => {
  const { state } = props;

  const handleJoyrideCallback = async (data) => {
    const { action, index, step, type } = data;
    const targetElement = document.querySelector(step.target);

    if (action === "skip" && type === "tour:end") {
      dispatch({ type: "TOUR_STOP" });
    }
    if ((action === "next" || action === "close") && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index + 1 },
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 0 ? 0 : 0,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-roster") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-add-depth-chart") &&
        type === "step:after"
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/add_depth_chart" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-add-button") &&
        type === "step:after"
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear
              ? state?.selectedSeasonYear
              : state?.SeasonYearData?.[0],
          },
        };
        const res = await GetFirstDepthChartEndpoint(payload);
        const result = res?.data;
        if (res?.data) {
          dispatch({
            type: "JOYRIDE_NAVIGATION",
            inputVariables: {
              routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
            },
          });
        } else {
          dispatch({ type: "TOUR_STOP" });
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-click-cell")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": {
              "position id": state?.DepthChartUsers?.[0].positions?.[0]?.id,
              rank: state?.DepthChartUsers?.[0].positions?.[0]?.rank,
              "row id": state?.DepthChartUsers?.[0].positions?.[0]?.row_id,
            },
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth_chart-submit-player")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": null,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading")
      ) {
        await dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/play_grades" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-add-new-game-or-practice"
        )
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {
            "input new Game": true,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-import-play")
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {},
        });
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-download-playdata")
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear
              ? state?.selectedSeasonYear
              : state?.SeasonYearData?.[0],
          },
        };

        const res = await GetAllGamesEndpoint(payload);
        const result = res?.data;

        if (res?.data) {
          // Check if result[11] is defined and has the required Id property
          if (result) {
            dispatch({
              type: "JOYRIDE_NAVIGATION",
              inputVariables: {
                routeName: `/play_by_play?playgradeid=${result[4].Id}&year=${state?.selectedSeasonYear}`,
              },
            });
          }
        } else {
          // Stop the tour if res.data is not valid
          dispatch({ type: "TOUR_STOP" });
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-new-game-or-practice-to-open"
        )
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-play")
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-upload-hudl-video")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO" });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-paste-hudl-link")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO", inputVariables: {} });
        const element = document.querySelector(
          ".joyride-grading-assign-depth-chart-manually"
        );
        if (element) {
          // Scroll into view, but also check scrollable parent
          const scrollableParent = document.querySelector(".mobile_overflow");
          if (scrollableParent) {
            scrollableParent.scrollLeft = element.offsetLeft; // Manual horizontal scroll
          }

          // Scroll the element into view smoothly
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Vertical alignment
            inline: "end", // Horizontal alignment
          });
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-bulk-edit")
      ) {
        const dropdownElements = document.getElementsByClassName(
          "form-control bulk-dropdown joyride-grading-bulk-edit"
        );
        const dropdown = dropdownElements[0];

        if (dropdown) {
          // Programmatically set the second option (Set Package)
          dropdown.value = "setpackage"; // Assuming 'setpackage' is the value of the second option

          // Trigger change event to notify any listeners of the change (if needed)
          const event = new Event("change", { bubbles: true });
          dropdown.dispatchEvent(event);
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-select-set-package")
      ) {
        const dropdownElements = document.getElementsByClassName(
          "form-control bulk-dropdown joyride-grading-select-set-package"
        );
        const dropdown = dropdownElements[0];

        if (dropdown) {
          // Programmatically click to open the dropdown (if it behaves like a button)
          dropdown.click();

          // Wait a moment to allow the dropdown to fully open
          setTimeout(() => {
            // Find the options within the dropdown
            const options = dropdown.getElementsByTagName("option");

            // Programmatically select the second option or desired value
            if (options.length > 1) {
              options[1].selected = true; // Select the second option (index 1)

              // Trigger change event to notify any listeners
              const event = new Event("change", { bubbles: true });
              dropdown.dispatchEvent(event);

              console.log("Set Package selected.");
            }

            // Add event listener for focus event (when the dropdown is opened)
            dropdown.addEventListener("focus", () => {
              console.log("Dropdown opened");
            });

            // Add event listener to detect clicks outside the dropdown to simulate "close"
            document.addEventListener(
              "click",
              function handleClickOutside(event) {
                if (!dropdown.contains(event.target)) {
                  console.log("Closing dropdown by clicking outside");
                  dropdown.blur(); // Trigger blur to close the dropdown

                  // Remove the event listener after closing
                  document.removeEventListener("click", handleClickOutside);
                }
              }
            );
          }, 200); // Adding delay to ensure dropdown is fully rendered and open
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-click-grading-options"
        )
      ) {
        const element = document.querySelector(
          ".joyride-grading-individual-player-notes"
        );
        if (element) {
          // Scroll into view, but also check scrollable parent
          const scrollableParent = document.querySelector(".mobile_overflow");
          if (scrollableParent) {
            scrollableParent.scrollLeft = element.offsetLeft; // Manual horizontal scroll
          }

          // Scroll the element into view smoothly
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Vertical alignment
            inline: "end", // Horizontal alignment
          });
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-grades")
      ) {
        if (
          state.PlayByPlayInfo &&
          state.PlayByPlayInfo.length > 0 &&
          state.PlayByPlayInfo[0] &&
          state.PlayByPlayInfo[0]["depth_chart_id"] !== undefined &&
          state.PlayByPlayInfo[0]["id"] !== undefined
        ) {
          // Dispatch the first action for setting the depth chart
          dispatch({
            type: "SET_PLAY_DEPTH_CHART",
            inputVariables: {
              "toggle depth chart": true,
              "depth chart input ": state.PlayByPlayInfo[0],
              "depth chart index": state.PlayByPlayInfo[0].index,
            },
          });

          // Dispatch the second action for getting user grades
          dispatch({
            type: "GET_USER_GRADES",
            inputVariables: {
              depthChartId: state.PlayByPlayInfo[0]["depth_chart_id"],
              playRowId: state.PlayByPlayInfo[0]["id"],
            },
          });

          // Dispatch the third action for getting all play depth chart users
          dispatch({
            type: "GET_ALL_PLAY_DEPTH_CHART_USERS",
            inputVariables: {
              "input depth chart rowid":
                state.PlayByPlayInfo[0]["depth_chart_id"],
              "input play rowid": state.PlayByPlayInfo[0]["id"],
            },
          });
        } else {
          dispatch({ type: "TOUR_STOP" });
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-save-and-close")
      ) {
        dispatch({ type: "REMOVE_TOGGLEDEPTHCHART", inputVariables: {} });
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 2 ? -1 : 2,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-player")
      ) {
        // Get the target element to scroll to
        const element = document.querySelector(".joyride-grading-trash-icon");
        if (element) {
          // Scroll into view, but also check scrollable parent
          const scrollableParent = document.querySelector(".mobile_overflow");
          if (scrollableParent) {
            scrollableParent.scrollTop = element.offsetTop; // Manual vertical scroll
          }

          // Scroll the element into view smoothly
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Vertical alignment
            inline: "start", // Horizontal alignment
          });
        }
      }
    }

    if (action === "prev" && type === "step:after") {
      dispatch({
        type: "TOUR_START",
        inputVariables: { run: true, index: index - 1 }, // Move to the previous step
      });
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/roster" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-click-cell")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/add_depth_chart" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-depth-chart-name-search")
      ) {
        dispatch({
          type: "SET_CURRENT_DEPTH_CHART_POSITION",
          inputVariables: {
            "current depth chart position input": null,
          },
        });
      }
      // if (
      //   targetElement &&
      //   targetElement.classList.contains("joyride-grading")
      // ) {
      //   const payload = {
      //     query_variables: {
      //       year: state?.selectedSeasonYear
      //         ? state?.selectedSeasonYear
      //         : state?.SeasonYearData?.[0],
      //     },
      //   };
      //   const res = await GetFirstDepthChartEndpoint(payload);
      //   const result = res?.data;
      //   console.log("result", result);
      //   if (res?.data) {
      //     dispatch({
      //       type: "JOYRIDE_NAVIGATION",
      //       inputVariables: {
      //         routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
      //       },
      //     });
      //     await dispatch({
      //       type: "SET_CURRENT_DEPTH_CHART_POSITION",
      //       inputVariables: {
      //         "current depth chart position input": {
      //           "position id": state?.DepthChartUsers?.[0].positions?.[0]?.id,
      //           rank: state?.DepthChartUsers?.[0].positions?.[0]?.rank,
      //           "row id": state?.DepthChartUsers?.[0].positions?.[0]?.row_id,
      //         },
      //       },
      //     });
      //   }

      // }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading")
      ) {
        const payload = {
          query_variables: {
            year: state?.selectedSeasonYear || state?.SeasonYearData?.[0],
          },
        };

        GetFirstDepthChartEndpoint(payload)
          .then((res) => {
            const result = res?.data;
            if (result) {
              // First dispatch for navigation
              dispatch({
                type: "JOYRIDE_NAVIGATION",
                inputVariables: {
                  routeName: `/depth_chart?depthchartid=${result?.id}&base=${result?.base}&year=${state?.selectedSeasonYear}`,
                },
              });

              dispatch({
                type: "SET_CURRENT_DEPTH_CHART_POSITION",
                inputVariables: {
                  "current depth chart position input": {
                    "position id":
                      state?.DepthChartUsers?.[0]?.positions?.[0]?.id,
                    rank: state?.DepthChartUsers?.[0]?.positions?.[0]?.rank,
                    "row id":
                      state?.DepthChartUsers?.[0]?.positions?.[0]?.row_id,
                  },
                },
              });
            } else {
              dispatch({ type: "TOUR_STOP" });
            }
          })
          .catch((error) => {
            dispatch({ type: "TOUR_STOP" });
          });
      }

      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-enter-new-game-or-practice"
        )
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {
            "input new Game": false,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-download-playdata")
      ) {
        dispatch({
          type: "NEW_GAME",
          inputVariables: {
            "input new Game": true,
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-new-game-or-practice-to-open"
        )
      ) {
        dispatch({
          type: "TOGGLE_NEW_ROW",
          inputVariables: {
            "input new row": false,
          },
        });
      }

      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-play")
      ) {
        dispatch({
          type: "JOYRIDE_NAVIGATION",
          inputVariables: { routeName: "/play_grades" },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-paste-hudl-link")
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO", inputVariables: null });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-assign-depth-chart-manually"
        )
      ) {
        dispatch({ type: "SET_UPLOAD_VIDEO" });
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-select-set-package")
      ) {
        const dropdownElements = document.getElementsByClassName(
          "form-control bulk-dropdown joyride-grading-select-set-package"
        );
        const dropdown = dropdownElements[0];

        if (dropdown) {
          dropdown.value = "setpackage"; // Assuming 'setpackage' is the value of the second option

          const event = new Event("change", { bubbles: true });
          dropdown.dispatchEvent(event);

          dropdown.addEventListener("focus", () => {
            console.log("Dropdown opened");
          });

          document.addEventListener(
            "click",
            function handleClickOutside(event) {
              if (!dropdown.contains(event.target)) {
                console.log("Closing dropdown by clicking outside");
                dropdown.blur(); // Trigger blur to close the dropdown

                document.removeEventListener("click", handleClickOutside);
              }
            }
          );
        }
      }

      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-choose-position-group"
        )
      ) {
        dispatch({
          type: "REMOVE_TOGGLEDEPTHCHART",
          inputVariables: {},
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-settings-grading-scale-type"
        )
      ) {
        dispatch({
          type: "SET_NAVBAR_DROPDOWN_INDEX",
          inputVariables: {
            "input navbar dropdown index":
              state?.NavBarDropDownIndex === 2 ? -1 : 2,
          },
        });
        dispatch({
          type: "SET_PLAY_DEPTH_CHART",
          inputVariables: {
            "toggle depth chart": true,
            "depth chart input ": state.PlayByPlayInfo[0],
            "depth chart index": state.PlayByPlayInfo[0].index,
          },
        });
        dispatch({
          type: "GET_USER_GRADES",
          inputVariables: {
            depthChartId: state.PlayByPlayInfo[0]?.["depth_chart_id"],
            playRowId: state.PlayByPlayInfo[0]?.["id"],
          },
        });
        dispatch({
          type: "GET_ALL_PLAY_DEPTH_CHART_USERS",
          inputVariables: {
            "input depth chart rowid":
              state.PlayByPlayInfo[0]?.["depth_chart_id"],
            "input play rowid": state.PlayByPlayInfo[0]?.["id"],
          },
        });
      }
      if (
        targetElement &&
        targetElement.classList.contains(
          "joyride-grading-click-grading-options"
        )
      ) {
        const element = document.querySelector(
          ".joyride-grading-bulk-edit-grades-checkbox"
        );
        if (element) {
          const scrollableParent = document.querySelector(".mobile_overflow");
          if (scrollableParent) {
            scrollableParent.scrollLeft = element.offsetLeft; // Manual horizontal scroll
          }

          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Vertical alignment
            inline: "start", // Horizontal alignment (for backward scroll)
          });
        }
      }
      if (
        targetElement &&
        targetElement.classList.contains("joyride-grading-add-player")
      ) {
        // Get the target element to scroll to
        const element = document.querySelector(
          ".joyride-grading-individual-player-notes"
        );
        if (element) {
          // Scroll into view, but also check scrollable parent
          const scrollableParent = document.querySelector(".mobile_overflow");
          if (scrollableParent) {
            scrollableParent.scrollTop = element.offsetTop; // Manual vertical scroll
          }

          // Scroll the element into view smoothly
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Vertical alignment
            inline: "start", // Horizontal alignment
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <Joyride
        steps={joyrideSteps}
        continuous={true}
        run={state?.run}
        stepIndex={state?.stepIndex}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        // showBackButton={true}
        // spotlightClicks={true}
        // hideBackButton
        // disableOverlayClose
        hideCloseButton={true}
        disableScrollParentFix={true} // Prevent joyride from fixing scroll on the parent container
        scrollToSteps={false} // Disable automatic scrolling to each step
        scrollToFirstStep={false}
        // scrollOffset={20} // Adjust scroll offset if necessary
        showProgress={true}
        // disableScrollParentFix={true} // Prevent parent elements from being scrolled
        disableScrolling={true} // Prevent automatic scrolling
        styles={{
          buttonSkip: {
            display: "block",
            position: "absolute",
            bottom: "15px",
            right: "140px",
          },
          buttonBack: {
            display: "block",
            position: "absolute",
            bottom: "15px",
            left: "15px",
          },
          buttonNext: {
            minWidth: "110px",
            maxWidth: "115px",
            padding: "10px 20px",
            whiteSpace: "nowrap",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoyrideComponent);
