let logMessageCounter = 0;

export const set_field = 'set_field';
export const remove_field = 'remove_field';

export const change_input = 'change_input';

export const log_task_started = 'log_task_started';
export const log_task_done = 'log_task_done';

export const log_task_success = 'log_task_success';
export const log_task_errored = 'log_task_errored';
export const log_task_close = 'log_task_close';

export const on_app_started = 'on_app_started';
export const log_event = 'log_event';

export const init_websocket = 'init_websocket';
export const ws_msg_recieved = 'ws_msg_recieved';
export const ADD_NEW_FIELD = 'ADD_NEW_FIELD';
export const CREATE_CUSTOM_FIELD_DATA = 'CREATE_CUSTOM_FIELD_DATA';
export const UPDATE_CUSTOM_FIELD_DATA = 'UPDATE_CUSTOM_FIELD_DATA';
export const CREATE_CUSTOM_PLAY_DATA = 'CREATE_CUSTOM_PLAY_DATA';
export const CREATE_DEPTH_CHART = 'CREATE_DEPTH_CHART';
export const CREATE_DEPTH_CHART_ALIAS = 'CREATE_DEPTH_CHART_ALIAS';
export const CREATE_DEPTH_CHART_REGISTER = 'CREATE_DEPTH_CHART_REGISTER';
export const CREATE_EXERCISE = 'CREATE_EXERCISE';
export const CREATE_GAMES = 'CREATE_GAMES';
export const CREATE_GRADE = 'CREATE_GRADE';
export const CREATE_INJURED_PLAYER = 'CREATE_INJURED_PLAYER';
export const CREATE_INTANGIBLE = 'CREATE_INTANGIBLE';
export const CREATE_NEW_COLUMN = 'CREATE_NEW_COLUMN';
export const CREATE_NEW_PLAY_ROW = 'CREATE_NEW_PLAY_ROW';
export const CREATE_NEW_POSITION_COACH = 'CREATE_NEW_POSITION_COACH';
export const CREATE_NEW_ROW = 'CREATE_NEW_ROW';
export const CREATE_PLAY_DEPTH_CHART_ROW = 'CREATE_PLAY_DEPTH_CHART_ROW';
export const CREATE_POSITION = 'CREATE_POSITION';
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const CREATE_QUIZ_QUESTION = 'CREATE_QUIZ_QUESTION';
export const CREATE_QUIZ_RESPONSE = 'CREATE_QUIZ_RESPONSE';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_EXERCISE = 'CREATE_USER_EXERCISE';
export const CREATE_USER_GRADE = 'CREATE_USER_GRADE';
export const DELETE_DEPTH_CHART = 'DELETE_DEPTH_CHART';
export const DELETE_DEPTH_CHART_ROW = 'DELETE_DEPTH_CHART_ROW';
export const DELETE_DEPTH_CHART_ROW_USER = 'DELETE_DEPTH_CHART_ROW_USER';
export const DELETE_DEPTH_CHART_USER = 'DELETE_DEPTH_CHART_USER';
export const DELETE_DEPTH_CHARTS_USERS = 'DELETE_DEPTH_CHARTS_USERS';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const DELETE_GAME = 'DELETE_GAME';
export const DELETE_GAME_FILE = 'DELETE_GAME_FILE';
export const DELETE_INJURED_PLAYER = 'DELETE_INJURED_PLAYER';
export const DELETE_INTANGIBLE = 'DELETE_INTANGIBLE';
export const DELETE_PLAY_ROW = 'DELETE_PLAY_ROW';
export const DELETE_POSITION = 'DELETE_POSITION';
export const DELETE_POSITION_COACH = 'DELETE_POSITION_COACH';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const DELETE_QUIZ_QUESTION = 'DELETE_QUIZ_QUESTION';
export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_DEPTH_CHART = 'DELETE_USER_DEPTH_CHART';
export const DELETE_USER_EXERCISE = 'DELETE_USER_EXERCISE';
export const DEPTH_CHART_USERS_WORKFLOWS = 'DEPTH_CHART_USERS_WORKFLOWS';
export const DEPTH_CHART_WORKFLOW = 'DEPTH_CHART_WORKFLOW';
export const EDIT_COACH_USER = 'EDIT_COACH_USER';
export const EDIT_DEPTH_CHART = 'EDIT_DEPTH_CHART';
export const EDIT_GAME_FILE = 'EDIT_GAME_FILE';
export const EDIT_PLAYER = 'EDIT_PLAYER';
export const EDIT_PLAYER_PASSWORD = 'EDIT_PLAYER_PASSWORD';
export const EDIT_POSITION = 'EDIT_POSITION';
export const EDIT_USER_EXERCISE = 'EDIT_USER_EXERCISE';
export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';
export const EDITPLAYERPOSITION = 'EDITPLAYERPOSITION';
export const GET_ALL_PLAY_DEPTH_CHART_USERS = 'GET_ALL_PLAY_DEPTH_CHART_USERS';
export const GET_AVERAGE_GAME_GRADE_SORTING = 'GET_AVERAGE_GAME_GRADE_SORTING';
export const GET_AVERAGE_GAME_GRADES = 'GET_AVERAGE_GAME_GRADES';
export const GET_AVERAGE_GAME_GRADES_DES = 'GET_AVERAGE_GAME_GRADES_DES';
export const GET_AVERAGE_GAME_GRADES_REPORTS = 'GET_AVERAGE_GAME_GRADES_REPORTS';
export const GET_AVERAGE_GAME_GRADES_SORTING_DES = 'GET_AVERAGE_GAME_GRADES_SORTING_DES';
export const GET_AVERAGE_PRACTICE_GRADE_SORTING = 'GET_AVERAGE_PRACTICE_GRADE_SORTING';
export const GET_AVERAGE_PRACTICE_GRADES_SORTING_DES = 'GET_AVERAGE_PRACTICE_GRADES_SORTING_DES';
export const GET_AVERAGE_USER_EXERCISES = 'GET_AVERAGE_USER_EXERCISES';
export const GET_AVERAGE_USER_EXERCISES_DES = 'GET_AVERAGE_USER_EXERCISES_DES';
export const GET_BASE_DEPTH_CHARTS = 'GET_BASE_DEPTH_CHARTS';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CUSTOM_FIELD_TITLES = 'GET_CUSTOM_FIELD_TITLES';
export const GET_DEPTH_CHART = 'GET_DEPTH_CHART';
export const GET_DEPTH_CHART_ID = 'GET_DEPTH_CHART_ID';
export const GET_DEPTH_CHART_POSITION_USERS = 'GET_DEPTH_CHART_POSITION_USERS';
export const GET_DEPTH_CHART_USERS = 'GET_DEPTH_CHART_USERS';
export const GET_DEPTH_CHART_BY_SCHOOL = 'GET_DEPTH_CHART_BY_SCHOOL';
export const GET_EXERCISES = 'GET_EXERCISES';
export const GET_EXERCISES_GRAPH = 'GET_EXERCISES_GRAPH';
export const GET_EXERCISES_GRAPH_USER = 'GET_EXERCISES_GRAPH_USER';
export const GET_GAME = 'GET_GAME';
export const GET_GAME_FILES = 'GET_GAME_FILES';
export const GET_GAME_FOR_PLAYER = 'GET_GAME_FOR_PLAYER';
export const GET_GAME_GRADES = 'GET_GAME_GRADES';
export const GET_GAME_GRADES_DES = 'GET_GAME_GRADES_DES';
export const GET_GAMES = 'GET_GAMES';
export const GET_GAMES_AND_PRACTICES = 'GET_GAMES_AND_PRACTICES';
export const GET_GRADES_GRAPH = 'GET_GRADES_GRAPH';
export const PLAYER_INTANGIBLE_COLLECTION = 'PLAYER_INTANGIBLE_COLLECTION';
export const PLAYER_POSITION_COLLECTION = 'PLAYER_POSITION_COLLECTION';
export const GET_GROUPED_CHARTS = 'GET_GROUPED_CHARTS';
export const GET_INJURED_PLAYERS = 'GET_INJURED_PLAYERS';
export const GET_INTANGIBLE_SORTING = 'GET_INTANGIBLE_SORTING';
export const GET_INTANGIBLES = 'GET_INTANGIBLES';
export const GET_INTANGIBLES_GRAPH = 'GET_INTANGIBLES_GRAPH';
export const GET_NUMBER_ROSTER = 'GET_NUMBER_ROSTER';
export const GET_NUMBER_ROSTER_DES = 'GET_NUMBER_ROSTER_DES';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PLAY_BY_PLAY_INFO = 'GET_PLAY_BY_PLAY_INFO';
export const GET_PLAY_INFO_AFTER_REFRESH = 'GET_PLAY_INFO_AFTER_REFRESH';
export const GET_PLAY_INFO_BY_DOWN = 'GET_PLAY_INFO_BY_DOWN';
export const GET_PLAY_INFO_BY_DOWN_DES = 'GET_PLAY_INFO_BY_DOWN_DES';
export const GET_PLAY_INFO_BY_INTANGIBLE = 'GET_PLAY_INFO_BY_INTANGIBLE';
export const GET_PLAY_INFO_BY_NAME = 'GET_PLAY_INFO_BY_NAME';
export const GET_PLAY_INFO_BY_NAME_DES = 'GET_PLAY_INFO_BY_NAME_DES';
export const GET_PLAY_INFO_BY_PACKAGE = 'GET_PLAY_INFO_BY_PACKAGE';
export const GET_PLAY_INFO_BY_PACKAGE_DES = 'GET_PLAY_INFO_BY_PACKAGE_DES';
export const GET_PLAY_INFO_BY_QTR = 'GET_PLAY_INFO_BY_QTR';
export const GET_PLAY_INFO_BY_QTR_DES = 'GET_PLAY_INFO_BY_QTR_DES';
export const GET_PLAY_INFO_BY_SERIES = 'GET_PLAY_INFO_BY_SERIES';
export const GET_PLAY_INFO_BY_SERIES_DES = 'GET_PLAY_INFO_BY_SERIES_DES';
export const GET_PLAY_INFO_BY_TYPE = 'GET_PLAY_INFO_BY_TYPE';
export const GET_PLAY_INFO_BY_TYPE_DES = 'GET_PLAY_INFO_BY_TYPE_DES';
export const GET_PLAY_REPORTS_DATA = 'GET_PLAY_REPORTS_DATA';
export const GET_PLAYER_GRADES = 'GET_PLAYER_GRADES';
export const GET_PLAYER_GRADES_DATA = 'GET_PLAYER_GRADES_DATA';
export const GET_PLAYER_REPORTS_COUNT = 'GET_PLAYER_REPORTS_COUNT';
export const GET_POSITION = 'GET_POSITION';
export const GET_POSITION_COACHES = 'GET_POSITION_COACHES';
export const GET_POSITIONS = 'GET_POSITIONS';
export const GET_POSITIONS_DES = 'GET_POSITIONS_DES';
export const GET_POSITIONS_ROSTER = 'GET_POSITIONS_ROSTER';
export const GET_PRACTICES = 'GET_PRACTICES';
export const GET_QUIZ_QUESTIONS = 'GET_QUIZ_QUESTIONS';
export const GET_QUIZZES = 'GET_QUIZZES';
export const GET_REPORTS_POSITION_FIELD = 'GET_REPORTS_POSITION_FIELD';
// export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_SECONDARY_ROSTER = 'GET_SECONDARY_ROSTER';
export const GET_SECONDARY_ROSTER_DES = 'GET_SECONDARY_ROSTER_DES';
export const GET_SUPPORT_PAGE = 'GET_SUPPORT_PAGE';
export const GET_TAGS = 'GET_TAGS';
export const GET_TOTAL_PLAYS = 'GET_TOTAL_PLAYS';
export const GET_USER = 'GET_USER';
export const GET_USER_EXERCISES = 'GET_USER_EXERCISES';
export const GET_USER_GRADES = 'GET_USER_GRADES';
export const GET_USER_GRADES_FOR_FILTER = 'GET_USER_GRADES_FOR_FILTER';
export const GET_USER_GRADES_GROUPED = 'GET_USER_GRADES_GROUPED';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_DES = 'GET_USERS_DES';
export const GET_YEAR_ROSTER = 'GET_YEAR_ROSTER';
export const GET_YEAR_ROSTER_DES = 'GET_YEAR_ROSTER_DES';
export const GET_CSV_PLAY_MAPPING_TITLES_CACHE = 'GET_CSV_PLAY_MAPPING_TITLES_CACHE';
export const GET_INTANGIBLES_FOR_REPORT = 'GET_INTANGIBLES_FOR_REPORT';
export const GET_INTANGIBLES_FOR_VIEW_ROW = 'GET_INTANGIBLES_FOR_VIEW_ROW';
export const HIDE_PLAY_MAPPING = 'HIDE_PLAY_MAPPING';
export const HIDE_ROSTER_MAPPING = 'HIDE_ROSTER_MAPPING';
export const HIDE_USER_GRAPH = 'HIDE_USER_GRAPH';
export const INIT_GAME_GRADES_PAGE = 'INIT_GAME_GRADES_PAGE';
export const INIT_GAME_GRADES_PAGE_DES = 'INIT_GAME_GRADES_PAGE_DES';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MAP_CSV = 'MAP_CSV';
export const MAP_PLAY_CSV = 'MAP_PLAY_CSV';
export const NEW_EXERCISE = 'NEW_EXERCISE';
export const NEW_GAME = 'NEW_GAME';
export const NEW_INJURED_PLAYER = 'NEW_INJURED_PLAYER';
export const NEW_INTANGIBLE = 'NEW_INTANGIBLE';
export const NEW_POSITION_COACH = 'NEW_POSITION_COACH';
export const NEW_USER = 'NEW_USER';
export const NEW_USER_EXERCISE = 'NEW_USER_EXERCISE';
export const PLAY_BY_PLAY_POSITION_SORTING = 'PLAY_BY_PLAY_POSITION_SORTING';
export const PRINT_REPORTS = 'PRINT_REPORTS';
export const PRINT_REPORTS_PARTICULAR = 'PRINT_REPORTS_PARTICULAR';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const RELOADPAGE = 'RELOADPAGE';
export const RELOADPLAYGRADES = 'RELOADPLAYGRADES';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const REMOVE_SUCCESSEMAILMESSAGE = 'REMOVE_SUCCESSEMAILMESSAGE';
export const REMOVE_TOGGLEDEPTHCHART = 'REMOVE_TOGGLEDEPTHCHART';
export const REMOVEVALUE = 'REMOVEVALUE';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const RESET_DEPTH_CHART = 'RESET_DEPTH_CHART';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_ACCORDION_INDEX = 'SET_ACCORDION_INDEX';
export const SET_AVERAGE_GRADES_FIELD = 'SET_AVERAGE_GRADES_FIELD';
export const SET_CURRENT_ALIAS = 'SET_CURRENT_ALIAS';
export const SET_CURRENT_DEPTH_CHART_POSITION = 'SET_CURRENT_DEPTH_CHART_POSITION';
export const SET_CURRENT_DOWN = 'SET_CURRENT_DOWN';
export const SET_CURRENT_EMAIL = 'SET_CURRENT_EMAIL';
export const SET_CURRENT_FIELD = 'SET_CURRENT_FIELD';
export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX';
export const SET_CURRENT_INTANGIBLE = 'SET_CURRENT_INTANGIBLE';
export const SET_CURRENT_NAME = 'SET_CURRENT_NAME';
export const SET_CURRENT_NOTES = 'SET_CURRENT_NOTES';
export const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
export const SET_CURRENT_PRODUCTION_POINT = 'SET_CURRENT_PRODUCTION_POINT';
export const SET_CURRENT_QTR = 'SET_CURRENT_QTR';
export const SET_CURRENT_SERIES = 'SET_CURRENT_SERIES';
export const SET_CURRENT_TYPE = 'SET_CURRENT_TYPE';
export const SET_DATE_FORMAT = 'SET_DATE_FORMAT';
export const SET_DELETE_COACH = 'SET_DELETE_COACH';
export const SET_DELETE_DEPTH_CHART = 'SET_DELETE_DEPTH_CHART';
export const SET_DELETE_DEPTH_CHART_ROW = 'SET_DELETE_DEPTH_CHART_ROW';
export const SET_DELETE_EXERCISE = 'SET_DELETE_EXERCISE';
export const SET_DELETE_GAME = 'SET_DELETE_GAME';
export const SET_DELETE_GAME_FILE = 'SET_DELETE_GAME_FILE';
export const SET_DELETE_INJURED_PLAYER = 'SET_DELETE_INJURED_PLAYER';
export const SET_DELETE_INTANGIBLE = 'SET_DELETE_INTANGIBLE';
export const SET_DELETE_PLAY_DEPTH_CHART_USER = 'SET_DELETE_PLAY_DEPTH_CHART_USER';
export const SET_DELETE_PLAY_ROW = 'SET_DELETE_PLAY_ROW';
export const SET_DELETE_PLAYER_EXERCISE = 'SET_DELETE_PLAYER_EXERCISE';
export const SET_DELETE_TAG = 'SET_DELETE_TAG';
export const SET_DELETE_USER = 'SET_DELETE_USER';
export const SET_DEPTH_CHART_CATEGORY = 'SET_DEPTH_CHART_CATEGORY';
export const SET_DEPTH_CHART_LIST_CATEGORY_FILTER = 'SET_DEPTH_CHART_LIST_CATEGORY_FILTER';
export const SET_DEPTH_CHART_LIST_WEEK_FILTER = 'SET_DEPTH_CHART_LIST_WEEK_FILTER';
export const SET_DEPTH_CHART_USER = 'SET_DEPTH_CHART_USER';
export const SET_EDIT_DEPTH_CHART = 'SET_EDIT_DEPTH_CHART';
export const SET_EDIT_GAME = 'SET_EDIT_GAME';
export const SET_EDIT_INTANGIBLE = 'SET_EDIT_INTANGIBLE';
export const SET_EDIT_QUIZ_QUIZ = 'SET_EDIT_QUIZ_QUIZ';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const SET_GAME_DATE = 'SET_GAME_DATE';
export const SET_GAME_TYPE_FIELD = 'SET_GAME_TYPE_FIELD';
export const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE';
export const SET_NAVBAR_DROPDOWN_INDEX = 'SET_NAVBAR_DROPDOWN_INDEX';
export const SET_NEW_POSITION = 'SET_NEW_POSITION';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_PACKAGES_MESSAGE = 'SET_PACKAGES_MESSAGE';
export const SET_PLAY_DEPTH_CHART = 'SET_PLAY_DEPTH_CHART';
export const SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER = 'SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER';
export const SET_ROSTER_FILTER = 'SET_ROSTER_FILTER';
export const SET_USER_DROPDOWN = 'SET_USER_DROPDOWN';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_CSV_PLAY_MAPPING_CACHE = 'SET_CSV_PLAY_MAPPING_CACHE';
export const SET_CSV_PLAY_MAPPING_TITLES = 'SET_CSV_PLAY_MAPPING_TITLES';
export const SHARE_DEPTH_CHART = 'SHARE_DEPTH_CHART';
export const SHOW_PLAYER_COMPARISON = 'SHOW_PLAYER_COMPARISON';
export const SET_PLAY_GRADES_DROPDOWN = 'SET_PLAY_GRADES_DROPDOWN';
export const TOGGLE_AVERAGE_GAME_GRADE_SORTING = 'TOGGLE_AVERAGE_GAME_GRADE_SORTING';
export const TOGGLE_AVERAGE_PRACTICE_GRADE_SORTING = 'TOGGLE_AVERAGE_PRACTICE_GRADE_SORTING';
export const TOGGLE_BASE_PACKAGES_DROPDOWN = 'TOGGLE_BASE_PACKAGES_DROPDOWN';
export const TOGGLE_DEPTHCHARTUSER_DROPDOWN = 'TOGGLE_DEPTHCHARTUSER_DROPDOWN';
export const TOGGLE_DROPDOWN_MANAGE_GAME = 'TOGGLE_DROPDOWN_MANAGE_GAME';
export const TOGGLE_DROPDOWN_MANAGE_PACKAGE = 'TOGGLE_DROPDOWN_MANAGE_PACKAGE';
export const TOGGLE_INTANGIBLE_FILTER = 'TOGGLE_INTANGIBLE_FILTER';
export const TOGGLE_NEW_DATA = 'TOGGLE_NEW_DATA';
export const TOGGLE_NEW_PLAY_ROW = 'TOGGLE_NEW_PLAY_ROW';
export const TOGGLE_NEW_QUIZ = 'TOGGLE_NEW_QUIZ';
export const TOGGLE_NEW_ROW = 'TOGGLE_NEW_ROW';
export const TOGGLE_PACKAGE_SELECT = 'TOGGLE_PACKAGE_SELECT';
export const TOGGLE_POSITION_SORTING = 'TOGGLE_POSITION_SORTING';
export const TOGGLE_QUIZ_QUESTION = 'TOGGLE_QUIZ_QUESTION';
export const TOGGLE_ROSTER_FILTER = 'TOGGLE_ROSTER_FILTER';
export const TOGGLE_SORTING = 'TOGGLE_SORTING';
export const TOGGLE_SORTING_DOWN_FOR_PLAYER = 'TOGGLE_SORTING_DOWN_FOR_PLAYER';
export const TOGGLE_SORTING_EXERCISE = 'TOGGLE_SORTING_EXERCISE';
export const TOGGLE_SORTING_GAME_GRADES = 'TOGGLE_SORTING_GAME_GRADES';
export const TOGGLE_SORTING_INDEX_FOR_PLAYER = 'TOGGLE_SORTING_INDEX_FOR_PLAYER';
export const TOGGLE_SORTING_NAME_FOR_PLAYER = 'TOGGLE_SORTING_NAME_FOR_PLAYER';
export const TOGGLE_SORTING_NUMBER = 'TOGGLE_SORTING_NUMBER';
export const TOGGLE_SORTING_PACKAGE_FOR_PLAYER = 'TOGGLE_SORTING_PACKAGE_FOR_PLAYER';
export const TOGGLE_SORTING_PLAY_DOWN = 'TOGGLE_SORTING_PLAY_DOWN';
export const TOGGLE_SORTING_PLAY_NAME = 'TOGGLE_SORTING_PLAY_NAME';
export const TOGGLE_SORTING_PLAY_PACKAGE = 'TOGGLE_SORTING_PLAY_PACKAGE';
export const TOGGLE_SORTING_PLAY_QTR = 'TOGGLE_SORTING_PLAY_QTR';
export const TOGGLE_SORTING_PLAY_TYPE = 'TOGGLE_SORTING_PLAY_TYPE';
export const TOGGLE_SORTING_PLAYER_INDEX = 'TOGGLE_SORTING_PLAYER_INDEX';
export const TOGGLE_SORTING_QTR_FOR_PLAYER = 'TOGGLE_SORTING_QTR_FOR_PLAYER';
export const TOGGLE_SORTING_SECONDARY = 'TOGGLE_SORTING_SECONDARY';
export const TOGGLE_SORTING_SERIES = 'TOGGLE_SORTING_SERIES';
export const TOGGLE_SORTING_SERIES_FOR_PLAYER = 'TOGGLE_SORTING_SERIES_FOR_PLAYER';
export const TOGGLE_SORTING_TYPE_FOR_PLAYER = 'TOGGLE_SORTING_TYPE_FOR_PLAYER';
export const TOGGLE_SORTING_YEAR = 'TOGGLE_SORTING_YEAR';
export const TOGGLE_SORTING_EXERCISE_POSITION = 'TOGGLE_SORTING_EXERCISE_POSITION';
export const TOGGLE_USER_SETTINGS_DROPDOWN = 'TOGGLE_USER_SETTINGS_DROPDOWN';
export const TOGGLE_WEEK_PACKAGES_DROPDOWN = 'TOGGLE_WEEK_PACKAGES_DROPDOWN';
export const UPDATE_DEPTH_CHART_ALIAS = 'UPDATE_DEPTH_CHART_ALIAS';
export const UPDATE_GAME = 'UPDATE_GAME';
export const UPDATE_GAME_FILE = 'UPDATE_GAME_FILE';
export const UPDATE_PLAY_DOWN = 'UPDATE_PLAY_DOWN';
export const UPDATE_PLAY_DISTANCE = 'UPDATE_PLAY_DISTANCE';
export const UPDATE_PLAY_FIELD = 'UPDATE_PLAY_FIELD';
export const UPDATE_PLAY_INDEX = 'UPDATE_PLAY_INDEX';
export const UPDATE_PLAY_NAME = 'UPDATE_PLAY_NAME';
export const UPDATE_PLAY_PACKAGE = 'UPDATE_PLAY_PACKAGE';
export const UPDATE_PLAY_QTR = 'UPDATE_PLAY_QTR';
export const UPDATE_PLAY_SERIES = 'UPDATE_PLAY_SERIES';
export const UPDATE_PLAY_TYPE = 'UPDATE_PLAY_TYPE';
export const UPDATE_PLAY_USER = 'UPDATE_PLAY_USER';
export const UPDATE_PLAY_USER_NOTES = 'UPDATE_PLAY_USER_NOTES';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const UPDATE_POSITION_COACH = 'UPDATE_POSITION_COACH';
export const UPDATE_PRODUCTION_POINT = 'UPDATE_PRODUCTION_POINT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_EXERCISE = 'UPDATE_USER_EXERCISE';
export const UPLOAD_GAME_FILE = 'UPLOAD_GAME_FILE';
export const UPLOAD_PLAY = 'UPLOAD_PLAY';
export const UPLOAD_ROSTER = 'UPLOAD_ROSTER';
export const SORT_PLAYER_TABLE = 'SORT_PLAYER_TABLE'; 
export const TOGGLE_POSITION_SORTING_FRONTED = 'TOGGLE_POSITION_SORTING_FRONTED';
export const TOGGLE_SORTING_SECONDARY_FRONTED = 'TOGGLE_SORTING_SECONDARY_FRONTED';
export const TOGGLE_SORTING_YEAR_FRONTED = 'TOGGLE_SORTING_YEAR_FRONTED';
export const TOGGLE_SORTING_NUMBER_FRONTED = 'TOGGLE_SORTING_NUMBER_FRONTED';
export const SORTINTANGIBLESNAME = 'SORTINTANGIBLESNAME';
export const TOGGLE_SORTING_PLAY = 'TOGGLE_SORTING_PLAY';
export const TOGGLE_SORTING_PLAY_AVG = 'TOGGLE_SORTING_PLAY_AVG';
export const TOGGLE_SORTING_GAME_AND_PRACTICE = 'TOGGLE_SORTING_GAME_AND_PRACTICE';
export const TOGGLE_SORTING_GAME_AND_PRACTICE_CUSTOM_FIELD = 'TOGGLE_SORTING_GAME_AND_PRACTICE_CUSTOM_FIELD';
export const TOGGLESORTINGPLAYER = 'TOGGLESORTINGPLAYER';
export const TOGGLE_SORTING_CUSTOM_FIELD_PLAYER = 'TOGGLE_SORTING_CUSTOM_FIELD_PLAYER';
export const TOGGLE_SORTING_PLAYER_INTANGIBLES = 'TOGGLE_SORTING_PLAYER_INTANGIBLES';
export const TOGGLE_SORTING_PLAYER_AVG = 'TOGGLE_SORTING_PLAYER_AVG';
export const TOGGLESORTINGPLAY_PLAYER = 'TOGGLESORTINGPLAY_PLAYER';
export const TOGGLESORTINGPLAYER_PLAYER = 'TOGGLESORTINGPLAYER_PLAYER';
export const UPDATE_INTANGIBLE_ARRAY = "UPDATE_INTANGIBLE_ARRAY";
export const GET_DEPTH_CHART_BY_PLAYER = "GET_DEPTH_CHART_BY_PLAYER";
export const EDIT_COACH_POSITION = "EDIT_COACH_POSITION";
export const SET_COACH_POSITION = "SET_COACH_POSITION";
export const EDIT_SCALE_TYPE = "EDIT_SCALE_TYPE";
export const GET_SCALE_TYPE = "GET_SCALE_TYPE";
export const ON_POSITION_FILTER_DATA = "ON_POSITION_FILTER_DATA";
export const ON_PLAYER_POSITION_FILTER_DATA = "ON_PLAYER_POSITION_FILTER_DATA";
export const GET_GAME_PRACTICE_GAME = "GET_GAME_PRACTICE_GAME";
export const GET_PLAYER_GAME = "GET_PLAYER_GAME";
export const GET_ALL_SERIES = "GET_ALL_SERIES";
export const DELETE_VIEW_USER = "DELETE_VIEW_USER";
export const UPDATE_VIEW_USER = "UPDATE_VIEW_USER";
export const TOGGLECOMPARESORTING = "TOGGLECOMPARESORTING";
export const SET_NOTES = "SET_NOTES";
export const GET_PLAYER_DEPTH_CHART_USERS = "GET_PLAYER_DEPTH_CHART_USERS";
export const SET_CURRENT_PLAYER_POSITION = "SET_CURRENT_PLAYER_POSITION";
export const GET_TEAMS = "GET_TEAMS";
export const SET_DUPLICATE_DEPTH_CHART = "SET_DUPLICATE_DEPTH_CHART";
export const LOADING = "LOADING";
export const SET_NEXT_DEPTH_CHART = "SET_NEXT_DEPTH_CHART";
export const SET_VIEW_USER = "SET_VIEW_USER";
export const SET_EXPORT_DEPTH_CHART = "SET_EXPORT_DEPTH_CHART";
export const SET_EMAIL_ALL_PLAYER = "SET_EMAIL_ALL_PLAYER";
export const RESEND_EMAIL_ALL_PLAYER = "RESEND_EMAIL_ALL_PLAYER";
export const SORT_BASE_DEPTH_CHART_TABLE = 'SORT_BASE_DEPTH_CHART_TABLE';
export const SORT_ALL_DEPTH_CHART_TABLE = 'SORT_ALL_DEPTH_CHART_TABLE';
export const DUPLICATE_DEPTH_CHART = 'DUPLICATE_DEPTH_CHART';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const CHECKBOX_BULK_GRADING = 'CHECKBOX_BULK_GRADING';
export const SET_BULK_PLAY_ROW = 'SET_BULK_PLAY_ROW';
export const DELETE_CHECKBOX_BULK_GRADING = 'DELETE_CHECKBOX_BULK_GRADING';
export const SET_GRADING_BY_POSITION = 'SET_GRADING_BY_POSITION';
export const CHECKBOX_BULK_GRADING_SCALE_DATA = 'CHECKBOX_BULK_GRADING_SCALE_DATA';
export const CONFIGURABLE_COLUMNS = 'CONFIGURABLE_COLUMNS';
export const UPDATE_CONFIGURABLE_COLUMNS = 'UPDATE_CONFIGURABLE_COLUMNS';
export const GET_PLAYER_USERS = 'GET_PLAYER_USERS';
export const CLEAR_COMPARE_VALUE = 'CLEAR_COMPARE_VALUE';
export const TOGGLE_SORTING_TRAINING = 'TOGGLE_SORTING_TRAINING';
export const TOGGLE_SORTING_TRAINING_EXERCISES = 'TOGGLE_SORTING_TRAINING_EXERCISES';
export const TOGGLECOMPARESORTINGNUMBER = 'TOGGLECOMPARESORTINGNUMBER';
export const SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW';
export const SET_SAVED_VIEW = 'SET_SAVED_VIEW';
export const DELETE_SAVED_VIEW = 'DELETE_SAVED_VIEW';
export const SET_DELETE_SAVED_VIEW = 'SET_DELETE_SAVED_VIEW';
export const TOGGLE_SORTING_DEPTHCHART = 'TOGGLE_SORTING_DEPTHCHART';
export const TOGGLE_SORTING_DEPTHCHART_PLAYER = 'TOGGLE_SORTING_DEPTHCHART_PLAYER';
export const SET_DEFAULT_VALUE = 'SET_DEFAULT_VALUE';
export const SET_DEPTH_CHART_WEEK_FILTER = 'SET_DEPTH_CHART_WEEK_FILTER';
export const TOGGLE_CUSTOMIZE_VIEW = 'TOGGLE_CUSTOMIZE_VIEW';
export const UPDATE_GRADING_TABLE_COLUMNS_INDEX = 'UPDATE_GRADING_TABLE_COLUMNS_INDEX';
export const GET_GRADING_TABLE_COLUMNS_INDEX = 'GET_GRADING_TABLE_COLUMNS_INDEX';
export const UPDATE_DRAG_USER = 'UPDATE_DRAG_USER';
export const UPDATE_DROP_USER = 'UPDATE_DROP_USER';
export const DRAG_AND_DROP_GRADING = 'DRAG_AND_DROP_GRADING';
export const CLEAR_DEPTH_CHART_VALUES = 'CLEAR_DEPTH_CHART_VALUES';
export const GET_DEPTH_CHART_FIELD_VIEW = 'GET_DEPTH_CHART_FIELD_VIEW';
export const UPDATE_DEPTH_CHART_FIELD_VIEW = 'UPDATE_DEPTH_CHART_FIELD_VIEW';
export const SET_FIELD_VIEW_PLAYER = 'SET_FIELD_VIEW_PLAYER';
export const UPDATE_FIELD_VIEW_PLAYER = 'UPDATE_FIELD_VIEW_PLAYER';
export const SELECT_DEPTH_CHART_VIEW = 'SELECT_DEPTH_CHART_VIEW';
export const REMOVE_PRODUCTION_POINT = 'REMOVE_PRODUCTION_POINT';
export const CLEAR_GRADING_INDEX = 'CLEAR_GRADING_INDEX';
export const GET_SEASON_YEAR = 'GET_SEASON_YEAR';
export const SELECT_SEASON_YEAR = 'SELECT_SEASON_YEAR';
export const LOADING_EVENT = 'LOADING_EVENT';
export const TOGGLE_SEASON_YEAR_DEPTH_CHART = 'TOGGLE_SEASON_YEAR_DEPTH_CHART'; 
export const TOGGLE_SEASON_YEAR_GRADING = 'TOGGLE_SEASON_YEAR_GRADING'; 
export const TOGGLE_SEASON_YEAR_GAME_AND_PRACTICE_REPORT = 'TOGGLE_SEASON_YEAR_GAME_AND_PRACTICE_REPORT'; 
export const CLEAR_PLAYER_REPORT_VALUES = 'CLEAR_PLAYER_REPORT_VALUES';
export const CLEAR_PLAYER_ACCOUNT_VALUES = 'CLEAR_PLAYER_ACCOUNT_VALUES';
export const CLEAR_PLAYER_DROPDOWN_VALUES = 'CLEAR_PLAYER_DROPDOWN_VALUES';
export const CLEAR_GAME_PRACTICE_REPORT_VALUES = 'CLEAR_GAME_PRACTICE_REPORT_VALUES';
export const GET_TRAINING_VALUES = 'GET_TRAINING_VALUES';
export const GET_USERS_VALUES = 'GET_USERS_VALUES';
export const GET_PLAYER_COMPARE_VALUES = 'GET_PLAYER_COMPARE_VALUES';
export const GET_BASE_DEPTH_CHART_BY_PLAYER = 'GET_BASE_DEPTH_CHART_BY_PLAYER';
export const SET_UPLOAD_VIDEO = 'SET_UPLOAD_VIDEO';
export const UPDATE_VIDEO_LINK = 'UPDATE_VIDEO_LINK';
export const REMOVE_CHECKBOX_GRADING_VALUE = 'REMOVE_CHECKBOX_GRADING_VALUE';
export const ADD_GRADING_PLAY = 'ADD_GRADING_PLAY';
export const SET_BULK_EDIT_ROW = 'SET_BULK_EDIT_ROW';
export const UPDATE_BULK_GRADING_SCALE_DATA = 'UPDATE_BULK_GRADING_SCALE_DATA';
export const SELECTED_CUSTOM_FIELD_DATA = 'SELECTED_CUSTOM_FIELD_DATA';
export const PLAY_BY_PLAY_CHECK_ALL = 'PLAY_BY_PLAY_CHECK_ALL';
export const PLAY_BY_PLAY_ON_CHECK_BOX_CHANGE = 'PLAY_BY_PLAY_ON_CHECK_BOX_CHANGE';
export const GET_GAME_BY_ID = 'GET_GAME_BY_ID';
export const GET_PLAY_BY_PLAY_GAME_LOADER = 'GET_PLAY_BY_PLAY_GAME_LOADER';
// export const SELECTED_CUSTOM_FIELD_DATA = 'SELECTED_CUSTOM_FIELD_DATA';
export const JOYRIDE_NAVIGATION = 'JOYRIDE_NAVIGATION';
export const TOUR_START = 'TOUR_START';
export const TOUR_STOP = 'TOUR_STOP';
export const ADD_DEPTH_CHART_TITLE = 'ADD_DEPTH_CHART_TITLE';
export const REMOVE_ADD_DEPTH_CHART_TITLE = 'REMOVE_ADD_DEPTH_CHART_TITLE';
export const VALIDATE_QWIKCUT_GAMES = 'VALIDATE_QWIKCUT_GAMES';
export const GET_PRODUCTION_POINT = 'GET_PRODUCTION_POINT';
export const GET_SCHOOL_PRODUCTION_POINT = 'GET_SCHOOL_PRODUCTION_POINT';
export const ADD_SCHOOL_PRODUCTION_POINT = 'ADD_SCHOOL_PRODUCTION_POINT';
export const ADD_PRODUCTION_POINT = 'ADD_PRODUCTION_POINT';
export const SET_DELETE_PRODUCTION_POINT = 'SET_DELETE_PRODUCTION_POINT';
export const REMOVE_PRODUCTION_POINT_VALUES = 'REMOVE_PRODUCTION_POINT_VALUES';
export const SEND_NEW_PRODUCTION_POINT_REQUEST = 'SEND_NEW_PRODUCTION_POINT_REQUEST';
export const OPTION_CHANGE_PRODUCTION_POINT_REQUEST = 'OPTION_CHANGE_PRODUCTION_POINT_REQUEST';
export const PRODUCTION_POINT_ERROR_MESSAGE = 'PRODUCTION_POINT_ERROR_MESSAGE';
export const PRODUCTION_POINT_SUCCESS_MESSAGE = 'PRODUCTION_POINT_SUCCESS_MESSAGE';
export const SET_HUDL_VIDEO = 'SET_HUDL_VIDEO';
export const SET_UPLOAD_HUDL_VIDEO = 'SET_UPLOAD_HUDL_VIDEO';
export const UPLOAD_HUDL_VIDEO = 'UPLOAD_HUDL_VIDEO';
export const REMOVE_UPLOAD_HUDL_VIDEO = 'REMOVE_UPLOAD_HUDL_VIDEO';
export const REMOVE_UPLOAD_HUDL_VIDEO_ERROR = 'REMOVE_UPLOAD_HUDL_VIDEO_ERROR';
export const EDIT_PLAY_USER_NOTES = 'EDIT_PLAY_USER_NOTES';
export const UPLOAD_GRADE = 'UPLOAD_GRADE';
export const REMOVE_VALUE = 'REMOVE_VALUE';
export const GET_SCHOOL_REPORT = 'GET_SCHOOL_REPORT';
export const SORT_ADMIN_REPORT_TABLE  = 'SORT_ADMIN_REPORT_TABLE';
export const TOGGLE_CHOOSE_PACKAGE= 'TOGGLE_CHOOSE_PACKAGE';
export const IMPORT_PLAY = 'IMPORT_PLAY';
export const IMPORT_PLAY_CSV = 'IMPORT_PLAY_CSV';
export const HIDE_IMPORT_MAPPING = 'HIDE_IMPORT_MAPPING';
export const SET_ADMIN_EDIT_USER = 'SET_ADMIN_EDIT_USER';
export const UPDATE_ADMIN_EDIT_USER = 'UPDATE_ADMIN_EDIT_USER';
export const REMOVE_ADMIN_EDIT_USER_ERROR = 'REMOVE_ADMIN_EDIT_USER_ERROR';
export const TOGGLECOMPARESORTINGRANK = 'TOGGLECOMPARESORTINGRANK';
export const DISABLE_LOADING = 'DISABLE_LOADING';


export const initWebsocket = (path) => {
    return ({
        type: init_websocket,
        payload: {
            path: path
        },
    })
}

export const addCurrentPositionData = (payload) => {
    return ({
        type: SET_CURRENT_PLAYER_POSITION,
        payload
    })
}
export const deleteCurrentProductionData = (payload) => {
    return ({
        type: REMOVE_PRODUCTION_POINT,
         payload ,
    })
}

export const setField = (key, value) => {
    return ({
        type: set_field,
        payload: {
            key: key,
            value: value,
        },
    })
};

export const removeField = (key, index) => {
    return ({
        type: remove_field,
        payload: {
            key: key,
            index: index,
        },
    })
};

export const customCreateIntangible = (payload) => {
    return ({
        type: CREATE_INTANGIBLE,
        payload: {
            name: payload?.name,
            value: payload?.value
        }
    })
}
// export const deleteIntangible = (payload)=>{
//     return ({
//         type:DELETE_INTANGIBLE,
//         payload:{
//             name:payload?.name,
//             value:payload?.value
//         }
//     })
// }
export const setCustomIntangible = (payload) => {
    return ({
        type: SET_DELETE_INTANGIBLE,
        payload: {
            name: payload?.name,
            value: payload?.value
        }
    })
}

export const changeInput = (key, value) => {
    // debugger
    return ({
        type: change_input,
        payload: {
            name: key,
            value: value,
        },
    })
};
export const updateIntangibleArray = (payload) => {
    return ({
        type: UPDATE_INTANGIBLE_ARRAY,
        payload: { payload },
    })
}

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))


export const delay = (ms) => {
    return new Promise(resolve => setTimeout(() => resolve(true), ms))
}

export const runAction = (type, payload) => ({ type: type, ...payload });

export const logEvent = (payload) => ({ type: log_event, payload });

export const logTaskStarted = (payload) => ({ type: log_task_started, payload });
export const logTaskDone = (payload) => ({ type: log_task_done, payload });

export const logTaskSuccess = (text, timeout = 3000) => ({ type: log_task_success, payload: { text, timeout, timestamp: Date.now() + ++logMessageCounter } });
export const logTaskErrored = (text) => ({ type: log_task_errored, payload: { text, timestamp: Date.now() + ++logMessageCounter } });
export const logTaskClose = (key) => ({ type: log_task_close, payload: key });
export const sortPlayerTable = (payload) => ({ type: SORT_PLAYER_TABLE, payload });