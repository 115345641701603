import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import {
  Body,
  Button,
  DivBlock,
  LinkBlock,
  List,
  ListItem,
} from "../modules/Basic";
import { DatePicker } from "../modules/Components";
import {
  FormBlock,
  Label,
  SelectDropDown,
  TextInput,
  CustomAutoComplete,
  QwikcutVideo,
} from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Paragraph, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { CircularProgress } from "@material-ui/core";

class PlayGradesPage extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.state = {
      autoTextValue: "",
      fileValue: [],
    };
  }

  componentDidMount() {
    this.props.getGamesData();
    this.props.getPracticesData();
    this.props.getTeamsData();
    this.props.getPackageData();
  }


  componentDidUpdate(previousProps, previousState) {
    const { dispatch } = this.props;
    if (previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear) {
      dispatch({ type: "LOADING_EVENT", inputVariables: { loader: true } });
      this.props.getGamesData();
      this.props.getPracticesData();
      this.props.getTeamsData();
    }
  }

  handleChange = (e) => {
    const { dispatch } = this.props;
    e.preventDefault();
    let files;
    files = e.target.files;
    if (files?.length > 0) {
      this.setState({ fileValue: files?.[0] });
      dispatch({
        type: "IMPORT_PLAY",
        inputVariables: {
          file: files?.[0],
          teamData: this.state.autoTextValue,
        },
      });
    }
  };

  handleImportPlay = async () => {
    const { getValidateQwikcutGame } = this.props;
    const valueCallback = await new Promise((resolve) => {
      getValidateQwikcutGame((callback) => resolve(callback));
    });
    if (valueCallback) {
      this.fileRef.current.click();
    }
  };

  handlePackage = () => {
    const packageOptions =
      this.props?.Packages?.map((data) => ({
        name: data.title,
        value: data.Id,
      })) || [];

    packageOptions.unshift({ name: "None", value: "NULL" });

    return packageOptions;
  };

  render() {
    let {
      FormGamesError,
      Games,
      NavBarDropDownIndex,
      NewGame,
      Practices,
      Teams,
      SeasonYearData,
      selectedSeasonYear,
      importMapping,
      currentCustomTitleCsvPlayMappingIndex,
      PlayUploadMappingError,
      CsvPlayMappingPackage,
      importPlayLoader,
      isPlayGradesLoader,
    } = this.props;

    const getTeamValue = (value) => {
      this.setState({ autoTextValue: value });
    };

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            // {
            //   action: "Get Games",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            // {
            //   action: "Get Practices",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            // {
            //   action: "Get Teams",
            //   trigger: "onload",
            //   timeout: "",
            //   pollingPeriod: "",
            //   inputVariables: {},
            // },
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 5 : 99,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingTop: "0px",
                  position: "relative",
                  height: "100%",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Game &amp; Practice Grading
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Grading /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Game &amp; Practice Grading
                          </Span>
                        </DivBlock>
                      </DivBlock>

                      <Columns
                        style={{
                          backgroundColor: "#ffffff",
                          fontSize: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        <Column
                          lg={12}
                          md={12}
                          sm={12}
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            marginBottom: "1rem",
                            marginLeft: "10px",
                            marginRight: "0px",
                            marginTop: "15px",
                            paddingBottom: "0px",
                            paddingTop: "0px",
                          }}
                          xs={12}
                        >
                          <DivBlock
                            style={{
                              alignItems: "center",
                              display: "flex",
                              fontSize: "13px",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Paragraph
                              style={{
                                fontSize: "14px",
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: "5px",
                                marginTop: 0,
                              }}
                            >
                              Create a new game or practice or choose an
                              existing game or practice below to start managing
                              play-by-play grades for players.
                            </Paragraph>
                          </DivBlock>
                        </Column>
                      </Columns>

                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "600",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Games
                        </Span>

                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Games &&
                            isArray(Games) &&
                            Games.map((row, index) => {
                              return (
                                <Fragment key={index}>
                                  <ListItem style={{ listStyle: "square" }}>
                                    {SeasonYearData?.[0]?.year ===
                                    selectedSeasonYear ? (
                                      <LinkBlock
                                        className={"a"}
                                        duration={1000}
                                        href={
                                          "/play_by_play?playgradeid=" +
                                          row["Id"] +
                                          "&year=" +
                                          selectedSeasonYear
                                        }
                                        target={"_self"}
                                        type={"spa"}
                                      >
                                        <DivBlock>
                                          <Span className={"a package joyride-grading-download-playdata"}>
                                            {row["title"]}
                                          </Span>
                                        </DivBlock>
                                      </LinkBlock>
                                    ) : (
                                      <DivBlock>
                                        <Span className={"a package year"}>
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    )}
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>

                      <DivBlock className={"mb-4"}>
                        <Span
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: "600",
                            marginLeft: "10px",
                            marginRight: "0px",
                          }}
                        >
                          Practices
                        </Span>

                        <List style={{ marginBottom: "0px", marginTop: "0px" }}>
                          {Practices &&
                            isArray(Practices) &&
                            Practices.map((row, index) => {
                              return (
                                <Fragment key={index}>
                                  <ListItem style={{ listStyle: "square" }}>
                                    {SeasonYearData?.[0]?.year ===
                                    selectedSeasonYear ? (
                                      <LinkBlock
                                        className={"a"}
                                        duration={1000}
                                        href={
                                          "/play_by_play?playgradeid=" +
                                          row["Id"] +
                                          "&year=" +
                                          selectedSeasonYear
                                        }
                                        target={"_self"}
                                        type={"spa"}
                                      >
                                        <DivBlock>
                                          <Span className={"a package"}>
                                            {row["title"]}
                                          </Span>
                                        </DivBlock>
                                      </LinkBlock>
                                    ) : (
                                      <DivBlock>
                                        <Span className={"a package year"}>
                                          {row["title"]}
                                        </Span>
                                      </DivBlock>
                                    )}
                                  </ListItem>
                                </Fragment>
                              );
                            })}
                        </List>
                      </DivBlock>

                      {isPlayGradesLoader ? null : (
                        <Columns>
                          <Column
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ marginBottom: "1rem" }}
                            xs={12}
                          >
                            <DivBlock
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                className={
                                  "btn btn-primary joyride-grading-add-new-game-or-practice"
                                }
                                style={
                                  SeasonYearData?.[0]?.year ===
                                  selectedSeasonYear
                                    ? {
                                        backgroundColor: "#48c46e",
                                        borderColor: "#48c46e",
                                      }
                                    : {
                                        backgroundColor: "#a0d2a0",
                                        borderColor: "#a0d2a0",
                                      }
                                }
                                disabled={
                                  SeasonYearData?.[0]?.year ===
                                  selectedSeasonYear
                                    ? false
                                    : true
                                }
                                actions={[
                                  {
                                    action: "New Game",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "input new Game": true,
                                    },
                                  },
                                ]}
                              >
                                Add New Game or Practice
                              </Button>
                            </DivBlock>
                          </Column>
                        </Columns>
                      )}
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>

            <If condition={NewGame ? true : false} show={false}>
              <Then>
                <DivBlock className={"modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading className="modal-heading" type={"h5"}>
                            Add New Game or Practice
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "New Game",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  "input new Game": false,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <DivBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <DivBlock className={"form-group"}>
                              <Label style={{ display: "flex" }}>Date</Label>

                              <DatePicker
                                className={"form-control"}
                                name={"FormGames.date"}
                              ></DatePicker>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label>Type</Label>

                              <SelectDropDown
                                initialValue={"Game"}
                                name={"FormGames.type"}
                                options={[
                                  { label: "Game", value: "Game" },
                                  { label: "Practice", value: "Practice" },
                                ]}
                                style={{
                                  background: "#fff",
                                  border: "1px solid #ced4da",
                                  borderRadius: "0.25rem",
                                  color: "#495057",
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  lineHeight: 1.5,
                                  paddingBottom: "0.375rem",
                                  paddingLeft: "0.75rem",
                                  paddingRight: "0.75rem",
                                  paddingTop: "0.375rem",
                                  width: "100%",
                                  height: "40px",
                                }}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"form-group"}>
                              <Label>Title</Label>

                              <TextInput
                                className={"form-control"}
                                name={"FormGames.title"}
                                placeholder={""}
                              ></TextInput>

                              <If
                                condition={
                                  FormGamesError && FormGamesError["title"]
                                    ? true
                                    : false
                                }
                              >
                                <Then>
                                  <Span className={"invalid-feedback"}>
                                    {FormGamesError
                                      ? FormGamesError["title"]
                                      : " "}
                                  </Span>
                                </Then>
                              </If>
                            </DivBlock>
                            <DivBlock className={"form-group"}>
                              <Label>Team</Label>
                              <CustomAutoComplete
                                {...{
                                  getTeamValue,
                                  name: "play_grade",
                                  clearOnBlur: false,
                                }}
                                size="small"
                                id="auto_complete"
                                data={
                                  Teams && isArray(Teams)
                                    ? Teams.map((data) => {
                                        return { ...data, title: data?.name };
                                      })
                                    : []
                                }
                              />
                            </DivBlock>
                            <DivBlock
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                className={
                                  "btn btn-primary joyride-grading-enter-new-game-or-practice"
                                }
                                style={{ margin: "0px 10px" }}
                                actions={[
                                  {
                                    action: "Create Games",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      teamData: this.state.autoTextValue,
                                      type: "coachtool",
                                    },
                                  },
                                ]}
                              >
                                Create
                              </Button>
                              <QwikcutVideo
                                teamData={this.state.autoTextValue}
                              />
                              <DivBlock className="remove-input">
                                <input
                                  type="file"
                                  ref={this.fileRef}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              </DivBlock>
                              <button
                                className={
                                  "btn btn-primary joyride-grading-import-play"
                                }
                                style={{ margin: "0px 10px" }}
                                onClick={() => this.handleImportPlay()}
                              >
                                {importPlayLoader ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CircularProgress
                                      size={25}
                                      thickness={3}
                                      style={{
                                        display: ["flex", "-webkit-inline-box"],
                                        color: "white",
                                        margin: "0 5px",
                                      }}
                                    />
                                    Loading ...
                                  </div>
                                ) : (
                                  <div>Import plays</div>
                                )}
                              </button>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
            <If
              condition={importMapping && importMapping?.id ? true : false}
              show={false}
            >
              <Then>
                <DivBlock className={"modal play-modal"}>
                  <DivBlock className={"modal-dialog"}>
                    <DivBlock className={"modal-dialog"}>
                      <DivBlock className={"modal-content"}>
                        <DivBlock className={"modal-header"}>
                          <Heading className="modal-heading" type={"h5"}>
                            Play Mapping
                          </Heading>

                          <Span
                            className={"close"}
                            actions={[
                              {
                                action: "Hide Import Mapping",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  nullval: null,
                                },
                              },
                            ]}
                          >
                            x
                          </Span>
                        </DivBlock>

                        <DivBlock className={"modal-body"}>
                          <FormBlock
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <DivBlock className={"form-group"}>
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th className={"th-border"}>
                                      <Span className={"th-span"}>
                                        Coachtools Files
                                      </Span>
                                    </th>

                                    <th className={"th-border"}>
                                      <Span className={"th-span"}>
                                        Fields in file
                                      </Span>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Play No.
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.index"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Play</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.name"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Qtr</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.qtr"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Series
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.series"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>Down</Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.down"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Distance
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.distance"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Play Type
                                      </Span>
                                    </td>

                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          importMapping
                                            ? importMapping["mapping"]
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"index"}
                                        initialValue={"99"}
                                        name={"CsvPlayMapping.type"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                        actions={[
                                          {
                                            action: "SetCsvPlayMappingTitle",
                                            trigger: "onchange",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              index:
                                                currentCustomTitleCsvPlayMappingIndex,
                                            },
                                          },
                                        ]}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="play-mapping-table-row">
                                      <Span className={"table-span"}>
                                        Package
                                      </Span>
                                    </td>
                                    <td className="play-mapping-table-row">
                                      <SelectDropDown
                                        dataSet={
                                          this.handlePackage() &&
                                          isArray(this.handlePackage())
                                            ? this.handlePackage()
                                            : []
                                        }
                                        dataSetLabel={"name"}
                                        dataSetValue={"value"}
                                        initialValue={
                                          CsvPlayMappingPackage === undefined ||
                                          CsvPlayMappingPackage === 0
                                            ? null
                                            : CsvPlayMappingPackage
                                        }
                                        name={"CsvPlayMappingPackage"}
                                        options={[]}
                                        className="play-mapping-table-row-dropdown"
                                        type={"number"}
                                        // actions={[
                                        //   {
                                        //     action: "SetCsvPlayMappingTitle",
                                        //     trigger: "onchange",
                                        //     timeout: "",
                                        //     pollingPeriod: "",
                                        //     inputVariables: {
                                        //       index:
                                        //         currentCustomTitleCsvPlayMappingIndex,
                                        //     },
                                        //   },
                                        // ]}
                                      ></SelectDropDown>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </DivBlock>
                            <If
                              condition={PlayUploadMappingError ? true : false}
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {PlayUploadMappingError
                                    ? PlayUploadMappingError
                                    : ""}
                                </Span>
                              </Then>
                            </If>

                            <DivBlock
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className={"btn btn-reset DeleteBtn"}
                                actions={[
                                  {
                                    action: "Hide Import Mapping",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      // teamData: this.state.autoTextValue,
                                    },
                                  },
                                ]}
                              >
                                Cancel
                              </Button>

                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Import Play Csv",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      // teamData: this.state.autoTextValue,
                                    },
                                  },
                                ]}
                              >
                                Submit
                              </Button>
                            </DivBlock>
                          </FormBlock>
                        </DivBlock>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </Then>
            </If>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGamesData: () => {
      dispatch({ type: "GET_GAMES" });
      return Promise.resolve();
    },
    getPracticesData: () => {
      dispatch({ type: "GET_PRACTICES" });
      return Promise.resolve();
    },
    getTeamsData: () => {
      dispatch({ type: "GET_TEAMS" });
      return Promise.resolve();
    },
    getValidateQwikcutGame: (callback) => {
      dispatch({
        type: "VALIDATE_QWIKCUT_GAMES",
        inputVariables: { callback },
      });
      return Promise.resolve();
    },
    getPackageData: () => {
      dispatch({ type: "GET_PACKAGES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayGradesPage);
