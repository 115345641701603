import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import * as serverApi from "../api/api.js";
import PublicBarChartLine from "../modules/charts/PublicBarChartLine.js";
import { Image } from "../modules/Media/index.js";
import { IMAGES } from "../assets/index.js";
import { DivBlock } from "../modules/Basic/index.js";
import profileImage from "../assets/images/qb_medium.png";
import { connect } from "react-redux";
import { getColorByValue } from "../utils/function.js";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "1112px",
    minWidth: "100%",
    height: "auto",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "30px 30px",
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
    right: 0,
    boxShadow: "rgb(0 0 0 / 21%) 0px 0px 3px 1px",
    position: "relative",
    left: 0,
    transform: "translateX(0)",
    top: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: "100%",
      marginTop: 0,
      position: "relative",
      left: 0,
      transform: "translateX(0)",
      top: 0,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "830px",
      marginTop: "60px",
      position: "absolute",
      left: "50%",
      top: "50px",
      transform: "translateX(-50%)",
      width: "calc(100% - 80px)",
    },
  },
}));

const ViewPlayerPage = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { dispatch } = props;
  const history = useHistory();
  const match = id.match(/^(.*?)-(\d+)$/);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(
    theme.breakpoints.up("sm") && theme.breakpoints.down("md")
  );

  const finalValue = {
    fullName: match ? match?.[1] : "",
    playerId: match ? match?.[2] : "",
  };

  useEffect(() => {
    const payload = {
      path_variables: {
        id: finalValue?.playerId,
      },
    };

    if (finalValue?.fullName && finalValue?.playerId) {
      try {
        serverApi
          .GetUserEndpoint(payload)
          .then((data) => {
            if (data?.status === 200) {
              setUserData(data);
              setLoading(false);
            } else {
              setUserData(null);
              setLoading(false);
              history.push("/player");
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setUserData(null);
    }
  }, [dispatch, finalValue?.fullName, finalValue?.playerId, history]);

  const ordinal = (n) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return `${n}${suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}`;
  };

  const yearwiseData = userData?.data?.yearwise_data;
  const chartLabels = yearwiseData
    ? Object.keys(yearwiseData)?.map((year) => {
        const { rank } = yearwiseData[year];
        return [year, `\n${ordinal(rank?.rank)}/${rank?.total_player}`];
      })
    : [];

  const chartData = yearwiseData
    ? Object.keys(yearwiseData).map((year) =>
        yearwiseData[year]?.grade != null
          ? parseFloat(yearwiseData[year].grade).toFixed(1)
          : null
      )
    : [];

  const lengthData = chartData?.length;

  const datalabelsOptions = {
    align: "end",
    anchor: "end",
    offset: -50,
    display: true,

    font: {
      size: 14,
      weight: "bold",
      family: "Arial",
    },
  };
  const handleLogoClick = () => {
    history.push("/");
  };

  const convertToFeetInches = (heightInInches) => {
    const feet = Math.floor(heightInInches / 12);
    const inches = Math.round(heightInInches % 12);
    return `${feet}'${inches}"`;
  };

  const schoolGradeOrderStyle = {
    order: isXs ? 3 : isSm ? 2 : 2,
  };
  const schoolLogoOrderStyle = {
    order: isXs ? 2 : isSm ? 3 : 3,
  };
  console.log("schoolLogoOrderStyle: ", schoolLogoOrderStyle);

  return (
    <>
      {loading ? (
        <DivBlock
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress
            style={{
              display: ["flex", "-webkit-inline-box"],
              color: "#48c46e",
            }}
          />
        </DivBlock>
      ) : (
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: { xs: "column", md: "row" },
            overflowY: "auto",
            height: "100%",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              flex: {
                xs: "2 2 0",
                sm: "0.5 0.5 auto",
                md: "0 0 30%",
                lg: "0 0 40%",
              },
              backgroundColor: "black",
              color: "white",
              maxHeight: {
                xs: "97px",
                sm: "92px",
                md: "100%",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: { xs: "45px 0", md: 0, lg: "0", xl: "20px" },
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: { xs: "19px", sm: "15px", md: "20px" },
                right: { xs: 0, sm: 0, md: "30px" },
                margin: { xs: "0 auto", sm: "0 auto", md: 0 },
                left: { xs: 0, sm: "0", md: "unset" },
                display: "flex",
                justifyContent: "center",
                height: "46px",
                width: "140px",
                cursor: "pointer",
              }}
              onClick={handleLogoClick}
            >
              <Image
                alt="CoachTools Logo"
                src={process.env.REACT_APP_LOGO}
                sx={{ height: "46px", width: "140px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              color: "black",
              display: "flex",
              flexDirection: "column",
              position: "static",
              paddingBottom: { xs: "50px", md: "0" },
              paddingTop: { xs: "20px", md: "0" },
              paddingLeft: { xs: "20px", sm: "20px", md: 0 },
              paddingRight: { xs: "20px", sm: "20px", md: 0 },
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                left: { xs: "0px", md: "20px" },
                top: { xs: "0px", md: "53px" },
                textAlign: { xs: "center", md: "left" },
                marginBottom: "20px",
                marginTop: { xs: "15px", md: "0" },
              }}
            >
              <Typography
                variant="h5"
                style={{
                  color: "#2f3244",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "-15px",
                }}
              >
                Player Grade
              </Typography>
            </Box>
            <Box className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={5} style={{ order: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      justifyContent: "end",
                      alignItems: "end",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        alt="Avatar"
                        src={
                          userData?.data?.profile_image?.thumbnail
                            ? process.env.REACT_APP_PROFILE +
                              userData?.data?.profile_image?.thumbnail
                            : profileImage
                        }
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "end",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 600,
                            fontFamily: "Poppins",
                            marginBottom: "1px",
                            fontSize: { xs: "14px", md: "20px" },
                          }}
                        >
                          {userData?.data.name}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: 400,
                            // fontSize: { xs: "12px", md: "14px" },
                            fontSize: "14px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {/* RB #32 */}
                          {userData?.data.position} #{userData?.data.number}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Divider style={{ backgroundColor: "#f6f6f6" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#6e6e6e",
                              fontFamily: "Poppins",
                            }}
                          >
                            {userData?.data?.height === 0
                              ? "0'0\""
                              : userData?.data?.height
                              ? convertToFeetInches(userData.data.height)
                              : ""}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#6e6e6e",
                              fontFamily: "Poppins",
                            }}
                          >
                            {userData?.data.weight
                              ? `${userData?.data.weight}lbs`
                              : ""}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#6e6e6e",
                              fontFamily: "Poppins",
                            }}
                          >
                            {userData?.data.graduation_year} Graduation
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} style={schoolGradeOrderStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        sm: "center",
                        md: "flex-start",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        gap: 10,
                      }}
                    >
                      <Typography
                        color="textSecondary"
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          // marginRight: "8px",
                          color: "#6e6e6e",
                        }}
                      >
                        CoachTools Grade
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: "#fafafa",
                          padding: "18px 12px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "70px",
                          width: "145px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faSquare}
                          style={{
                            marginRight: "8px",
                            color: getColorByValue(
                              userData?.data?.avg_game_grade
                            ),
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          variant="h4"
                          style={{
                            fontWeight: 700,
                            color: "#2c3242",
                            fontFamily: "Poppins",
                          }}
                        >
                          {userData?.data?.avg_game_grade != null
                            ? parseFloat(userData.data.avg_game_grade).toFixed(
                                1
                              )
                            : "0"}
                        </Typography>
                      </Box>
                      <Typography
                        color="textSecondary"
                        style={{
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          color: "#6e6e6e",
                        }}
                      >
                        {userData?.data?.overall_rank
                          ? `${userData.data.overall_rank.rank}/${userData.data.overall_rank.total_players}`
                          : ""}{" "}
                        {userData?.data?.position}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={schoolLogoOrderStyle}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: {
                        xs: "flex-start",
                        sm: "flex-start",
                        md: "center",
                      },
                      height: "100%",
                    }}
                  >
                    <Image
                      alt="School Logo"
                      src={
                        process.env.REACT_APP_PROFILE +
                        userData?.data?.school_logo?.thumbnail
                      }
                      style={{
                        height: "auto",
                        width: "100px",
                        minWidth: "100px",
                        maxWidth: "100px",

                        marginRight: {
                          xs: "10px",
                          sm: "10px",
                          md: 0,
                          lg: "10px",
                        },
                        borderRadius: "100%",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "100",
                        textTransform: "capitalize",
                        marginLeft: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {userData?.data?.school_name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box
                style={{
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                <Divider
                  style={{
                    display: "block",
                    backgroundColor: "#f6f6f6",
                    width: "100",
                  }}
                />
              </Box>

              <Box style={{ width: "100%" }}>
                {yearwiseData ? (
                  <PublicBarChartLine
                    chartLabels={chartLabels}
                    chartData={chartData?.map((item) => Math.round(item))}
                    chartTitle="Player Grades Over the Years"
                    isSkipYaxis={false}
                    maintainAspectRatio={false}
                    lengthData={lengthData}
                    barBorderRadius={10}
                    datalabelsOptions={datalabelsOptions}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#333",
                      margin: "20px 0",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      No Game Records Available
                    </span>
                    <br />
                    This player has not played any games yet.
                  </Typography>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  width: "100%",
                  marginTop: "100px",
                }}
              >
                <Divider
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "100%",
                    margin: "-38px 0",
                  }}
                />

                <img
                  alt="CoachTools Logo"
                  src={IMAGES.Blacklogo}
                  style={{
                    width: "145px",
                    margin: "0 auto",
                    background: "white",
                    border: "5px solid #fff",
                    cursor: "pointer",
                  }}
                  onClick={handleLogoClick}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};

export default connect(mapStateToProps)(ViewPlayerPage);
